import React from 'react';
import './style.css';
import {PerMonthGraph} from '../Payments/components/PerMonthGraph';
import {PerProcedureGraph} from '../Payments/components/PerProcedureGraph';

const data1 = {
  paid: [2532832.68, 1890045.88, 3607242.91, 3760548.74, 3055112.59, 3659258.22],
  base: [2737204.46, 2964618.3, 5213698.38, 9228082.88, 13412282.76, 17207542.67],
  xLabels: [
    '2022-01-01T00:00:00.000Z',
    '2022-02-01T00:00:00.000Z',
    '2022-03-01T00:00:00.000Z',
    '2022-04-01T00:00:00.000Z',
    '2022-05-01T00:00:00.000Z',
    '2022-06-01T00:00:00.000Z',
  ],
};
const data2 = {
  count: [
    5326891.98,
    4974236.32,
    4261880.42,
    3511509.03,
    3315363.85,
    3312381.37,
    2731070.76,
    2676317.2,
    2590092.47,
    2559991.04,
  ],
  xLabels: [
    'Operating Room including HCPCS',
    'Anesthesia Services – Unit',
    'SURGICAL SUPPLIES                  ',
    'DEXTRAN 40 IN DEXTROSE',
    'DEXAMETHASONE SODIUM PHOSPHATE ',
    'Consultation Consultant',
    'DYNASTAT 40MG',
    'NORDASET 4MG/2ML',
    'STERILE NEEDLE                     ',
    'BREATHING CIRCUITS                 ',
  ],
};
const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-160">
        <div className="contentContainer-0-1-161">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Payment Statistics</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-163"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '33.3333%',
                        flex: '0 0 33.3333%',
                        maxWidth: '33.3333%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-171">
                        <div className="numContNumber-0-1-173">
                          <span>57,186.74</span>
                        </div>
                        <div className="numContLabel-0-1-172">SAR Avg. per week</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '33.3333%',
                        flex: '0 0 33.3333%',
                        maxWidth: '33.3333%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-171">
                        <div className="numContNumber-0-1-173">
                          <span>247,809.22</span>
                        </div>
                        <div className="numContLabel-0-1-172">SAR Avg. per month</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '33.3333%',
                        flex: '0 0 33.3333%',
                        maxWidth: '33.3333%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-171">
                        <div className="numContNumber-0-1-173">
                          <span>84</span>
                        </div>
                        <div className="numContLabel-0-1-172">Encounter to Payment (Days)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Cumulative Payments per Month</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <PerMonthGraph data={data1} />;
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Top 10 Payments Per Procedure Code (SAR Amount)</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <PerProcedureGraph data={data2} />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Monthly Payments Per TPA (SAR Amount)</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="container-0-1-137 container">
                        <div className="dropdownButtonContainer-0-1-143 dropCont">
                          <div className="titleContainer-0-1-138 titleContainer">
                            <img
                              className="titleIcon-0-1-141 leftIcon"
                              src="/static/media/tpa_icon.7b588d2f.svg"
                              alt="left"
                            />
                            <div className="valueCont">
                              <span className="titleText-0-1-140 titleText"> RCOVID-19, +56 more</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="tableWrapper-0-1-166">
                    <table className="klaimTable-0-1-176">
                      <thead className="klaimThead-0-1-181">
                        <tr>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Receiver Name</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Receiver Code</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Jan '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Feb '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Mar '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Apr '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">May '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Jun '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Total</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="klaimTbody-0-1-178">
                        <tr className="tableRow-0-1-167">
                          <td>NAS</td>
                          <td>C001</td>
                          <td>
                            <span>10,872.20</span>
                          </td>
                          <td>
                            <span>16,868.70</span>
                          </td>
                          <td>
                            <span>14,038.00</span>
                          </td>
                          <td>
                            <span>11,493.00</span>
                          </td>
                          <td>
                            <span>12,381.5</span>
                          </td>
                          <td>
                            <span>8,274</span>
                          </td>
                          <td>
                            <span>73,927.40</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>NEXTCARE</td>
                          <td>C002</td>
                          <td>
                            <span>11,489.50</span>
                          </td>
                          <td>
                            <span>7,441.60</span>
                          </td>
                          <td>
                            <span>10,128.73</span>
                          </td>
                          <td>
                            <span>16,632.32</span>
                          </td>
                          <td>
                            <span>6,638.70</span>
                          </td>
                          <td>
                            <span>4,544.70</span>
                          </td>
                          <td>
                            <span>56,875.55</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>NEURON</td>
                          <td>C005</td>
                          <td>
                            <span>1,751.94</span>
                          </td>
                          <td>
                            <span>9,843.90</span>
                          </td>
                          <td>
                            <span>3,874</span>
                          </td>
                          <td>
                            <span>13,003.70</span>
                          </td>
                          <td>
                            <span>3,232.90</span>
                          </td>
                          <td>
                            <span>4,190.30</span>
                          </td>
                          <td>
                            <span>35,896.74</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>MSH INLT.</td>
                          <td>C010</td>
                          <td>
                            <span>437.60</span>
                          </td>
                          <td>
                            <span>2,093.40</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>2,531.00</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>MEDNET GLOBAL</td>
                          <td>C019</td>
                          <td>
                            <span>5,990.00</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>5,990.00</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>DAMAN TPA - THIQA</td>
                          <td>D001</td>
                          <td>
                            <span>268,634.30</span>
                          </td>
                          <td>
                            <span>58,858.50</span>
                          </td>
                          <td>
                            <span>139,969.20</span>
                          </td>
                          <td>
                            <span>60,146.30</span>
                          </td>
                          <td>
                            <span>139,770.76</span>
                          </td>
                          <td>
                            <span>132,130.30</span>
                          </td>
                          <td>
                            <span>799,509.36</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>NEURON</td>
                          <td>TPA001</td>
                          <td>
                            <span>4,102.25</span>
                          </td>
                          <td>
                            <span>2,850.5</span>
                          </td>
                          <td>
                            <span>28,382.90</span>
                          </td>
                          <td>
                            <span>8,792.24</span>
                          </td>
                          <td>
                            <span>18,859.10</span>
                          </td>
                          <td>
                            <span>10,298.28</span>
                          </td>
                          <td>
                            <span>73,285.27</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>NEXTCARE</td>
                          <td>TPA002</td>
                          <td>
                            <span>7,439.60</span>
                          </td>
                          <td>
                            <span>10,299.84</span>
                          </td>
                          <td>
                            <span>14,072.70</span>
                          </td>
                          <td>
                            <span>9,862.05</span>
                          </td>
                          <td>
                            <span>5,861.60</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>47,535.79</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ALMADALLAH</td>
                          <td>TPA003</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,957.5</span>
                          </td>
                          <td>
                            <span>685</span>
                          </td>
                          <td>
                            <span>5,075.88</span>
                          </td>
                          <td>
                            <span>1,012.5</span>
                          </td>
                          <td>
                            <span>330</span>
                          </td>
                          <td>
                            <span>9,060.88</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>NAS</td>
                          <td>TPA004</td>
                          <td>
                            <span>15,633.97</span>
                          </td>
                          <td>
                            <span>14,551.97</span>
                          </td>
                          <td>
                            <span>23,112.95</span>
                          </td>
                          <td>
                            <span>17,139.5</span>
                          </td>
                          <td>
                            <span>13,258.5</span>
                          </td>
                          <td>
                            <span>13,433</span>
                          </td>
                          <td>
                            <span>97,129.89</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paginationContainer-0-1-164 right">
                      <div className="paginationContainer-0-1-185">
                        <span />
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_left_icon.5d82b0df.svg" alt="arrow-left" />
                        </span>
                        <div className="numberButtonsContainer-0-1-183">
                          <div
                            role="button"
                            className="paginationNumberButton-0-1-187 paginationNumberButtonActive-0-1-188"
                          >
                            1
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            2
                          </div>
                        </div>
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_right_icon.7c4eefa8.svg" alt="arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Monthly Payments Per Insurer (SAR Amount)</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="container-0-1-137 container">
                        <div className="dropdownButtonContainer-0-1-143 dropCont">
                          <div className="titleContainer-0-1-138 titleContainer">
                            <img
                              className="titleIcon-0-1-141 leftIcon"
                              src="/static/media/insurer_icon.09062271.svg"
                              alt="left"
                            />
                            <div className="valueCont">
                              <span className="titleText-0-1-140 titleText"> COVID-19, +141 more</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="tableWrapper-0-1-166">
                    <table className="klaimTable-0-1-176">
                      <thead className="klaimThead-0-1-181">
                        <tr>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Payer Name</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Payer Code</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Jan '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Feb '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Mar '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Apr '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">May '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Jun '22</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Total</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="klaimTbody-0-1-178">
                        <tr className="tableRow-0-1-167">
                          <td>DAMAN</td>
                          <td>A001</td>
                          <td>
                            <span>15,835.50</span>
                          </td>
                          <td>
                            <span>48,035.50</span>
                          </td>
                          <td>
                            <span>34,954.38</span>
                          </td>
                          <td>
                            <span>9,567.66</span>
                          </td>
                          <td>
                            <span>11,174.82</span>
                          </td>
                          <td>
                            <span>17,357.82</span>
                          </td>
                          <td>
                            <span>136,925.68</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ADNIC</td>
                          <td>A002</td>
                          <td>
                            <span>12,614.60</span>
                          </td>
                          <td>
                            <span>3,362.80</span>
                          </td>
                          <td>
                            <span>4,630.00</span>
                          </td>
                          <td>
                            <span>10,012.10</span>
                          </td>
                          <td>
                            <span>2,182.07</span>
                          </td>
                          <td>
                            <span>3,727.49</span>
                          </td>
                          <td>
                            <span>36,529.06</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>AL SAGR</td>
                          <td>A003</td>
                          <td>
                            <span>2,560</span>
                          </td>
                          <td>
                            <span>4,622.10</span>
                          </td>
                          <td>
                            <span>1,377.80</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>8,559.90</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ADN TAKAFUL</td>
                          <td>A008</td>
                          <td>
                            <span>292</span>
                          </td>
                          <td>
                            <span>4,451.20</span>
                          </td>
                          <td>
                            <span>1,959</span>
                          </td>
                          <td>
                            <span>2,194.80</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>8,897.00</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>AL AIN AHLIA CO.</td>
                          <td>A010</td>
                          <td>
                            <span>1,339.34</span>
                          </td>
                          <td>
                            <span>4,501.70</span>
                          </td>
                          <td>
                            <span>1,245.80</span>
                          </td>
                          <td>
                            <span>8,135.20</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>15,222.04</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ALICO/MELIFE</td>
                          <td>A011</td>
                          <td>
                            <span>180.5</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,402.90</span>
                          </td>
                          <td>
                            <span>339.5</span>
                          </td>
                          <td>
                            <span>1,922.90</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ORIENT INSURANCE</td>
                          <td>A012</td>
                          <td>
                            <span>5,990.30</span>
                          </td>
                          <td>
                            <span>7,311.10</span>
                          </td>
                          <td>
                            <span>9,726.53</span>
                          </td>
                          <td>
                            <span>7,784.62</span>
                          </td>
                          <td>
                            <span>5,361.90</span>
                          </td>
                          <td>
                            <span>3,394.70</span>
                          </td>
                          <td>
                            <span>39,569.15</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>QATAR INSURANCE</td>
                          <td>A014</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>736.20</span>
                          </td>
                          <td>
                            <span>740.40</span>
                          </td>
                          <td>
                            <span>1,043.20</span>
                          </td>
                          <td>
                            <span>2,314</span>
                          </td>
                          <td>
                            <span>1,752</span>
                          </td>
                          <td>
                            <span>6,585.80</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>AL DHAFRA</td>
                          <td>A016</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>480.40</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>480.40</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ZURICH INSURANCE</td>
                          <td>A017</td>
                          <td>
                            <span>412.60</span>
                          </td>
                          <td>
                            <span>5,342.20</span>
                          </td>
                          <td>
                            <span>2,007.20</span>
                          </td>
                          <td>
                            <span>5,614.90</span>
                          </td>
                          <td>
                            <span>3,232.90</span>
                          </td>
                          <td>
                            <span>4,190.30</span>
                          </td>
                          <td>
                            <span>20,800.10</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paginationContainer-0-1-164 right">
                      <div className="paginationContainer-0-1-185">
                        <span />
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_left_icon.5d82b0df.svg" alt="arrow-left" />
                        </span>
                        <div className="numberButtonsContainer-0-1-183">
                          <div
                            role="button"
                            className="paginationNumberButton-0-1-187 paginationNumberButtonActive-0-1-188"
                          >
                            1
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            2
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            3
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            4
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            5
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            6
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-187 ">
                            7
                          </div>
                        </div>
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_right_icon.7c4eefa8.svg" alt="arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
