import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    dashboardContainer: {
      maxHeight: '80vh',
      '&.isProcessing': {
        maxHeight: 'calc(100% - 280px)',
      },
      '& .chart-custom-tooltip': {
        fontSize: `12px !important`,
        minWidth: 300,
        padding: '5px 10px',
      },
      '& .chart-tooltip-heading': {
        padding: '0 0 5px',
        color: Colors.black,
        fontWeight: 'bold',
        fontSize: 14,
      },
      '& .chart-tooltip-item': {
        display: 'block',
        width: '100%',
        '& .left, .right': {
          display: 'inline-block',
          width: 'auto',
          '& > div': {
            display: 'inline-block',
            verticalAlign: 'middle',
          },
          '& .box': {height: 12, width: 12, borderRadius: 3},
        },
        '& .right': {
          textAlign: 'right',
          float: 'right',
          color: Colors.blackLight,
          fontWeight: 'bold',
        },
      },
      '& .chart-tooltip-body': {
        borderTop: `1px solid ${Colors.grayLight}`,
        padding: '5px 0',
      },
      '& .chart-tooltip-total': {
        padding: '5px 0 0',
        borderTop: `1px solid ${Colors.grayLight}`,
        color: `${Colors.black} !important`,
      },
      padding: '10px 20px 20px',
    },
  };
});
