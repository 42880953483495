import React from 'react';
import {createUseStyles} from 'react-jss';

import {Colors} from 'modules/Common/constants/Colors';
import {NumbersContainerData} from 'modules/Dashboard/api/types';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    accent: {
      '&.purple': {
        background: Colors.purple,
      },
      '&.red': {
        background: Colors.red,
      },
      '&.teal': {
        background: Colors.teal,
      },
      '&.yellow': {
        background: Colors.yellow,
      },
      background: Colors.black,
      borderRadius: 5,
      bottom: 13,
      position: 'absolute',
      right: 13,
      top: 13,
      width: 7,
    },
    numCont: {
      background: Colors.blueGrayLight,
      borderRadius: 10,
      height: 'auto',
      margin: '5px 0',
      padding: '15px 30px',
      width: 'calc(100% - 60px)',
      position: 'relative',
      minHeight: '90px',
    },
    numContLabel: {
      fontSize: 12,
      fontWeight: 400,
      height: '24px',
    },
    numContNumber: {
      fontSize: 16,
      fontWeight: 'bold',
      margin: '10px 13px 15px 0',
    },
  };
});

export const NumbersContainer: React.FC<NumbersContainerData> = ({number, label, accentColor, customWidth}) => {
  const classes = useStylesFromThemeFunction();
  let style = {};
  if (customWidth) style = {width: customWidth};
  return (
    <div className={classes.numCont} style={style}>
      <div className={classes.numContNumber}>{number}</div>
      <div className={classes.numContLabel}>{label}</div>
      {accentColor === 0 && <div className={`${classes.accent} purple`} />}
      {accentColor === 1 && <div className={`${classes.accent} teal`} />}
      {accentColor === 2 && <div className={`${classes.accent} red`} />}
      {accentColor === 3 && <div className={`${classes.accent} yellow`} />}
    </div>
  );
};
