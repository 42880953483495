import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import {Colors} from 'modules/Common/constants/Colors';
import {RejectionsPerPeriodData} from 'modules/Dashboard/api/types';
import {
  CollectionsPerMonthSeries,
  TotalClaimsVSAmountDateFormats,
  ClaimsBreakdownFilters,
} from 'modules/Dashboard/api/strings';
import {HistogramMonthFilterGroup} from 'modules/Dashboard/common/enums';

dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const PerPeriodGraph: React.FC<RejectionsPerPeriodData> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const options = {
    grid: {
      bottom: 40,
      left: 90,
      right: 10,
      top: 70,
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: 'Rejections',
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barWidth: data.rejections.length > 20 ? '60%' : 34,
        data: data.rejections,
        itemStyle: {color: Colors.purple},
        // name: 'Rejections',
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          label: {
            formatter: (params: any) => {
              const identifiedParams = dayjs(params.value).isLeapYear();
              const endYear = identifiedParams
                ? dayjs(params.value).dayOfYear(366).format(TotalClaimsVSAmountDateFormats.CompleteDate)
                : dayjs(params.value).dayOfYear(365).format(TotalClaimsVSAmountDateFormats.CompleteDate);
              const endMonth = dayjs(params.value)
                .add(dayjs(params.value).daysInMonth() - 1, 'day')
                .format(TotalClaimsVSAmountDateFormats.CompleteDate);
              return data.year === 'monthly'
                ? `${dayjs(params.value).format(TotalClaimsVSAmountDateFormats.CompleteDate)} - ${endMonth}`
                : `${dayjs(params.value).format(TotalClaimsVSAmountDateFormats.CompleteDate)} - ${endYear}`;
            },
          },
        },
        data: data.xLabels,
        type: 'category',
        axisLabel: {
          formatter: (value: any, index: number) => {
            if (index === 0 || dayjs(value).format(TotalClaimsVSAmountDateFormats.Monthly) === 'Jan') {
              return dayjs(value).format(TotalClaimsVSAmountDateFormats.MonthWithYear);
            }
            return dayjs(value).format(TotalClaimsVSAmountDateFormats.MonthWithYear);
          },
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };

  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
