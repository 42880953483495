import React from 'react';
import './style.css';
import {CollectionsPerMonth} from '../Collection/components/CollectionsPerMonth';

const data = {
  collected: [2587835.99, 2806540.83, 2928272.91, 1706965.36, 426617.61],
  rejected: [305472, 339283, 377189, 289758, 109430],
  xLabels: [
    '2022-01-01T00:00:00.000Z',
    '2022-02-01T00:00:00.000Z',
    '2022-03-01T00:00:00.000Z',
    '2022-04-01T00:00:00.000Z',
    '2022-05-01T00:00:00.000Z',
  ],
  pending: [132095.03, 268488.82, 503193.33, 1431140, 3019059.42],
};
const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-79">
        <div className="contentContainer-0-1-80">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="row2-0-1-81"
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Collections Per Month (SAR Amount)</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="toggleSwitchEL-0-1-88" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Insurer
                        </div>
                        <div role="button">TPA</div>
                      </div>
                      <div className="container-0-1-91 container">
                        <div className="dropdownButtonContainer-0-1-97 dropCont">
                          <div className="titleContainer-0-1-92 titleContainer">
                            <div className="valueCont">
                              <span className="titleText-0-1-94 titleText"> COVID-19, +141 more</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="downloadPopup-0-1-112 dashDLBtn">
                        <button className="klaimBtn-0-1-119 false undefined klaimBtnIconMode-0-1-122 buttonTool-0-1-118">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-121"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <CollectionsPerMonth data={data} scope={null} />;
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="row2-0-1-81"
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Collections Per Source</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="toggleSwitchEL-0-1-88" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Insurer
                        </div>
                        <div role="button">TPA</div>
                      </div>
                      <div className="container-0-1-91 container">
                        <div className="dropdownButtonContainer-0-1-97 dropCont">
                          <div className="titleContainer-0-1-92 titleContainer">
                            <img
                              className="titleIcon-0-1-95 leftIcon"
                              src="/static/media/insurer_icon.09062271.svg"
                              alt="left"
                            />
                            <div className="valueCont">
                              <span className="titleText-0-1-94 titleText"> COVID-19, +141 more</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="downloadPopup-0-1-112 dashDLBtn">
                        <button className="klaimBtn-0-1-119 false undefined klaimBtnIconMode-0-1-122 buttonTool-0-1-118">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-121"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="tableWrapper-0-1-130">
                    <table className="klaimTable-0-1-143">
                      <thead className="klaimThead-0-1-148">
                        <tr>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Payer</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Payer ID</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Claimed SAR</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Collected SAR</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Collected (%)</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Rejected SAR</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Rejected (%)</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Pending SAR</p>
                          </th>
                          <th className="klaimTh-0-1-147">
                            <p className="tableHeading-0-1-129">Pending (%)</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="klaimTbody-0-1-145">
                        <tr className="tableRow-0-1-131">
                          <td>DAMAN</td>
                          <td>A001</td>
                          <td>
                            <span>175,668.24</span>
                          </td>
                          <td>
                            <span>102,344.38</span>
                          </td>
                          <td>
                            <span>58.26</span>
                          </td>
                          <td>
                            <span>13,586</span>
                          </td>
                          <td>
                            <span>7.73</span>
                          </td>
                          <td>
                            <span>59,680.97</span>
                          </td>
                          <td>
                            <span>33.97</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>ADNIC</td>
                          <td>A002</td>
                          <td>
                            <span>45,398.19</span>
                          </td>
                          <td>
                            <span>29,841.31</span>
                          </td>
                          <td>
                            <span>65.73</span>
                          </td>
                          <td>
                            <span>6,351</span>
                          </td>
                          <td>
                            <span>13.99</span>
                          </td>
                          <td>
                            <span>9,203.90</span>
                          </td>
                          <td>
                            <span>20.27</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>AL BUHAIRA</td>
                          <td>A006</td>
                          <td>
                            <span>637.80</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>637.80</span>
                          </td>
                          <td>
                            <span>100</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>ADN TAKAFUL</td>
                          <td>A008</td>
                          <td>
                            <span>5,584.20</span>
                          </td>
                          <td>
                            <span>5,584.20</span>
                          </td>
                          <td>
                            <span>100</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>AL AIN AHLIA CO.</td>
                          <td>A010</td>
                          <td>
                            <span>15,441.94</span>
                          </td>
                          <td>
                            <span>14,176.64</span>
                          </td>
                          <td>
                            <span>91.81</span>
                          </td>
                          <td>
                            <span>781</span>
                          </td>
                          <td>
                            <span>5.06</span>
                          </td>
                          <td>
                            <span>483</span>
                          </td>
                          <td>
                            <span>3.13</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>ALICO/MELIFE</td>
                          <td>A011</td>
                          <td>
                            <span>3,153.50</span>
                          </td>
                          <td>
                            <span>1,742.40</span>
                          </td>
                          <td>
                            <span>55.25</span>
                          </td>
                          <td>
                            <span>322</span>
                          </td>
                          <td>
                            <span>10.21</span>
                          </td>
                          <td>
                            <span>1,088.10</span>
                          </td>
                          <td>
                            <span>34.50</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>ORIENT INSURANCE</td>
                          <td>A012</td>
                          <td>
                            <span>23,939.15</span>
                          </td>
                          <td>
                            <span>17,484.92</span>
                          </td>
                          <td>
                            <span>73.04</span>
                          </td>
                          <td>
                            <span>1,397</span>
                          </td>
                          <td>
                            <span>5.84</span>
                          </td>
                          <td>
                            <span>5,054.21</span>
                          </td>
                          <td>
                            <span>21.11</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>QATAR INSURANCE</td>
                          <td>A014</td>
                          <td>
                            <span>6,879.20</span>
                          </td>
                          <td>
                            <span>5,109.20</span>
                          </td>
                          <td>
                            <span>74.27</span>
                          </td>
                          <td>
                            <span>310</span>
                          </td>
                          <td>
                            <span>4.51</span>
                          </td>
                          <td>
                            <span>1,460</span>
                          </td>
                          <td>
                            <span>21.22</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>AL DHAFRA</td>
                          <td>A016</td>
                          <td>
                            <span>480.40</span>
                          </td>
                          <td>
                            <span>480.40</span>
                          </td>
                          <td>
                            <span>100</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-131">
                          <td>ZURICH INSURANCE</td>
                          <td>A017</td>
                          <td>
                            <span>22,839.28</span>
                          </td>
                          <td>
                            <span>20,049.70</span>
                          </td>
                          <td>
                            <span>87.79</span>
                          </td>
                          <td>
                            <span>1,376</span>
                          </td>
                          <td>
                            <span>6.02</span>
                          </td>
                          <td>
                            <span>1,412.20</span>
                          </td>
                          <td>
                            <span>6.18</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paginationContainer-0-1-128 right">
                      <div className="paginationContainer-0-1-152">
                        <span />
                        <span className="paginationLeftRightButton-0-1-153" role="button">
                          <img src="/static/media/arrow_left_icon.5d82b0df.svg" alt="arrow-left" />
                        </span>
                        <div className="numberButtonsContainer-0-1-150">
                          <div
                            role="button"
                            className="paginationNumberButton-0-1-154 paginationNumberButtonActive-0-1-155"
                          >
                            1
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            2
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            3
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            4
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            5
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            6
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            7
                          </div>
                        </div>
                        <span className="paginationLeftRightButton-0-1-153" role="button">
                          <img src="/static/media/arrow_right_icon.7c4eefa8.svg" alt="arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
