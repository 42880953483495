import {FC, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import NumberFormat from 'react-number-format';
import {Row, Col} from 'react-grid-system';
import {Card} from 'modules/Common/components/Card';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {roundOff} from 'modules/Common/utils/formatter';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {NoData} from 'modules/Common/components/NoData';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    ...commonStyles,
    summaryContainer: {
      marginTop: '0px',
      '@media (min-width: 1280px)': {
        marginTop: '45px',
      },
    },
  };
});

interface ComponentProps {
  summary: any;
  showLoading?: boolean;
}

const numFormattedView = (num: number) => (
  <span>
    <NumberFormat thousandSeparator value={roundOff(num)} displayType="text" />
  </span>
);

const AgeingClaimsSummary: FC<ComponentProps> = ({summary, showLoading}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <Card title="Summary" minHeight={'90%'} showLoading={showLoading}>
      <div className={classes.summaryContainer}>
        {summary &&
        summary.totalAmountPendingClaims === 0 &&
        summary.totalAmountPendingClaimsMoreThanMonth === 0 &&
        summary.totalNumberPendingClaims === 0 &&
        summary.totalNumberPendingClaimsMoreThanMonth === 0 ? (
          <NoData />
        ) : (
          <>
            <Row className={classes.boxesCont}>
              <Col md={12} lg={6}>
                <NumbersContainer
                  number={summary ? numFormattedView(summary.totalNumberPendingClaims) : 0}
                  label="Pending Claims"
                />
              </Col>
              <Col md={12} lg={6}>
                <NumbersContainer
                  number={summary ? numFormattedView(summary.totalNumberPendingClaimsMoreThanMonth) : 0}
                  label="> 45 Days"
                />
              </Col>
            </Row>
            <Row className={classes.boxesCont}>
              <Col md={12} lg={6}>
                <NumbersContainer
                  number={summary ? numFormattedView(summary.totalAmountPendingClaims) : 0}
                  label={`${getCurrencyToDisplay()} Pending Claims`}
                />
              </Col>
              <Col md={12} lg={6}>
                <NumbersContainer
                  number={summary ? numFormattedView(summary.totalAmountPendingClaimsMoreThanMonth) : 0}
                  label={`${getCurrencyToDisplay()} > 45 Days`}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </Card>
  );
};

export default AgeingClaimsSummary;
