import React from 'react';
import {Switch, useHistory} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {setNavOptions} from 'modules/Header/headerSlice';
import Header from 'modules/Header';
import WelcomeBanner from 'modules/Dashboard/components/WelcomeBanner';
import {ProcessingBanner} from 'modules/Dashboard/components/ProcessingBanner';
import {useStylesFromThemeFunction} from 'modules/Dashboard/containers/Dashboard/Dashboard';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {events, Mixpanel} from 'services/mixpanel';
import {HeaderIconsEnum, NavigationMenuItem} from 'interfaces/header.interfaces';

const Dashboard: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStylesFromThemeFunction();
  const {branches, user} = useAppSelector(authSelect);

  const navigations: NavigationMenuItem[] = [
    {link: '/dashboard/overview', title: 'Overview', leftIcon: HeaderIconsEnum.overview},
    {link: '/dashboard/rejections', title: 'Rejections', leftIcon: HeaderIconsEnum.rejection},
    {link: '/dashboard/ageing-claims', title: 'Ageing', leftIcon: HeaderIconsEnum.ageing},
    {link: '/dashboard/payments', title: 'Payments', leftIcon: HeaderIconsEnum.payments},
    {link: '/dashboard/clinician', title: 'Clinician', leftIcon: HeaderIconsEnum.clinicians},
    {link: '/dashboard/collection', title: 'Collection', leftIcon: HeaderIconsEnum.collection},
  ];

  React.useEffect(() => {
    history.push('/eligibility');
    // dispatch(
    //   setNavOptions({
    //     navOptions: navigations,
    //   }),
    // );
    // Mixpanel.track(events.pageViews.dashboardView);
    // history.push('/dashboard/overview');
  }, []);
  return null;

  return branches.length < 1 ||
    !process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES.split(',').includes(user.organization.country) ? (
    <WelcomeBanner />
  ) : (
    <>
      <Header />
      <div
        style={{
          background: '#6135FB',
          borderRadius: '0.375rem',
          padding: 4,
          margin: '30px 20px 10px',
          color: 'white',
          opacity: 0.95,
        }}
      >
        <h2 style={{fontSize: 14}}>Note: Dashboard feature is currently under construction.</h2>
      </div>
      {/* NOTE: Please Do not remove! (By: Nick Coloma) */}
      {/* {user.banner && <ProcessingBanner />} */}
      {/* <div className={`${classes.dashboardContainer} ${user.banner ? 'isProcessing' : ''}`}> */}
      <div className={classes.dashboardContainer}>
        <Switch>
          {routes &&
            routes.map(({routeKey, ...route}) => {
              return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
            })}
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
