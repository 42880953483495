import {FC, useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {ToggleSwitchActivePane} from 'modules/Common/strings';
import {Colors} from 'modules/Common/constants/Colors';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    toggleSwitchEL: {
      '& > div': {
        '&.active': {
          background: Colors.purpleLight,
          borderColor: Colors.purple,
          color: Colors.purple,
        },
        '&:first-child': {
          borderRadius: '10px 0 0 10px',
        },
        '&:last-child': {
          borderRadius: '0 10px 10px 0',
        },
        border: `1px solid ${Colors.grayLight}`,
        background: Colors.blueGrayLight,
        color: Colors.gray,
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: 12,
        padding: '7px 20px',
        textAlign: 'center',
        '@media (max-width: 400px)': {
          width: 'calc(100% - 40px)',
        },
        '@media (max-width: 768px)': {
          borderRadius: '10px !important',
          marginRight: 5,
          marginBottom: 5,
        },
      },
      margin: '5px 0',
      height: 'auto',
    },
  };
});

interface ComponentProps {
  leftLabel?: string;
  labels?: string[];
  onClick: (val: string) => void;
  rightLabel?: string;
  width?: number | string;
}

export const ToggleSwitch: FC<ComponentProps> = ({leftLabel, labels, rightLabel, onClick, width}) => {
  const classes = useStylesFromThemeFunction();
  let defaultActive: string | number = 'none';
  if (leftLabel) defaultActive = 'left';
  else if (labels) defaultActive = 0;
  const [active, setActive] = useState(defaultActive);

  useEffect(() => {
    /* set first label active */
    if (labels && labels?.length === 2 && active === 2) {
      setActive(0);
    }
  }, [labels]);

  let renderLabels = null;
  let renderLeftLabel = null;
  let renderRightLabel = null;
  if (labels && labels?.length > 0) {
    renderLabels = labels.map((item, i) => {
      return (
        <div
          key={i}
          role="button"
          className={`${active === i ? 'active' : ''}`}
          onClick={() => {
            setActive(i);
            onClick(item || '');
          }}
        >
          {item}
        </div>
      );
    });
  }
  if (leftLabel)
    renderLeftLabel = (
      <div
        role="button"
        className={`${active === ToggleSwitchActivePane.Left ? 'active' : ''}`}
        onClick={() => {
          setActive(ToggleSwitchActivePane.Left);
          onClick(leftLabel || '');
        }}
      >
        {leftLabel}
      </div>
    );

  if (rightLabel)
    renderRightLabel = (
      <div
        role="button"
        className={`${active === ToggleSwitchActivePane.Right ? 'active' : ''}`}
        onClick={() => {
          setActive(ToggleSwitchActivePane.Right);
          onClick(rightLabel || '');
        }}
      >
        {rightLabel}
      </div>
    );

  return (
    <div className={classes.toggleSwitchEL} style={{width: width || 'auto'}}>
      {renderLeftLabel}
      {renderLabels}
      {renderRightLabel}
    </div>
  );
};
