import {FC, useState, useEffect} from 'react';
import {NoData} from 'modules/Common/components/NoData';
import {Table} from 'modules/Dashboard/components/Table';
import {TableDataType} from 'modules/Dashboard/api/types';

interface ComponentProps {
  tableData: any;
  totalCount: number;
  tools: any;
  tablePaging: {offset: number; limit: number};
  setTablePaging: any;
  showLoading?: boolean;
}

const AgeingClaimsTable: FC<ComponentProps> = ({
  tableData,
  tools,
  showLoading,
  totalCount,
  setTablePaging,
  tablePaging,
}) => {
  const [structuredBucketData, setStructuredBucketData] = useState<any[]>([]);

  const headingNames = [
    'Receiver/TPA',
    '<= 15 days',
    '15 - 30 days',
    '30 - 45 days',
    '45 - 60 days',
    '60 - 90 days',
    '120 - 150 days',
    '150 - 180 days',
    '180 - 360 days',
    '> 360 days',
    'Total',
  ];

  useEffect(() => {
    if (tableData.length > 0) {
      const tData = tableData
        .map((data: any) => {
          const insurer = data.name && data.name !== '' ? data.name : data.key;
          const buckets = data.range?.buckets;

          const bucketItems: number[] = [];
          headingNames.forEach((name) => {
            if (name !== 'Receiver/TPA') {
              const bucketItem = buckets.filter((item: TableDataType) => item.key === name);
              if (bucketItem.length !== 0) bucketItems.push(bucketItem[0].rangeSum.value);
              else bucketItems.push(0);
            }
          });

          const sum = bucketItems.reduce((a, b) => a + b);
          bucketItems[bucketItems.length - 1] = sum;
          return {
            insurer_name: insurer,
            buckets: bucketItems,
            sumTotal: sum,
          };
        })
        .sort((itemA: {sumTotal: number}, itemB: {sumTotal: number}) => {
          return itemB.sumTotal - itemA.sumTotal;
        });

      setStructuredBucketData(tData);
    }
  }, [tableData]);

  let renderContent: any = <NoData />;
  if (tableData.length > 0)
    renderContent = (
      <Table
        title={'Ageing by Source'}
        headingData={headingNames}
        bodyData={structuredBucketData.map((item) => {
          const row = [...item.buckets];
          row.unshift(item.insurer_name);
          return row;
        })}
        countPerpage={tablePaging.limit || 0}
        page={tablePaging.limit}
        pushPaginationToRight
        totalResults={totalCount}
        showPagination
        handlePageChange={(pageNumber: number) => {
          const offset = pageNumber * 10;
          if (tablePaging) setTablePaging({...tablePaging, offset});
        }}
        tools={tools}
        showLoading={showLoading}
      />
    );

  return renderContent;
};

export default AgeingClaimsTable;
