import React, {useState} from 'react';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {Options} from 'modules/Dashboard/api/types';
import {MultiSelectTool} from 'modules/Dashboard/components/MultiSelectTool';
import KlaimSelect from 'modules/Common/components/KlaimSelect';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {Table} from 'modules/Dashboard/components/Table';
import {yearRange} from 'modules/Common/utils/dateRange';
import insurerIcon from 'assets/insurer_icon.svg';
import callendarIcon from 'assets/calendar_icon.svg';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {headerSelect, Branches} from 'modules/Header/headerSlice';
import {useAppSelector} from 'modules/App/store';
import {ClaimsBreakdownDateRangeFormat, PayerType} from 'modules/Dashboard/api/strings';
import {DASH} from 'modules/Dashboard/api';
import {getPaymentsSourceAllDataService} from 'modules/Dashboard/api/requests';
import {getPerInsurerTableDataService} from 'modules/Dashboard/containers/Payments/service';

interface ComponentProps {
  perInsurerPayments: any;
  perInsurerSelectedPayer: any;
  setPerInsurerSelectedPayer: any;
  isPerInsurerLoading?: boolean;
  perInsurerPaging: any;
  setPerInsurerPaging: any;
  insurerOptions: any;
  dateRange: any;
  totalCount: number;
}

export const PerInsurerTable: React.FC<ComponentProps> = ({
  perInsurerPayments,
  perInsurerSelectedPayer,
  setPerInsurerSelectedPayer,
  isPerInsurerLoading,
  perInsurerPaging,
  setPerInsurerPaging,
  insurerOptions,
  dateRange,
  totalCount,
}) => {
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  let yearRangeArray: number[] = [];
  let yearsArr: Options[] | undefined = [];
  if (dateRange.startDate && dateRange.endDate && yearsArr.length === 0) {
    yearRangeArray = yearRange(
      parseInt(dayjs(dateRange.startDate).format(`YYYY`), 10),
      parseInt(dayjs(dateRange.endDate).format(`YYYY`), 10),
    );
    yearsArr = yearRangeArray.map((y): Options => ({label: `${y}`, value: `${y}`}));
  }

  const [insurerSelectedYear, setInsurerSelectedYear] = useState<Options | null>(yearsArr[0]);

  const [paymentPerInsurerCsvData, setPaymentPerInsurerCsvData] = useState<string[][]>([[]]);

  if (perInsurerPayments && perInsurerPayments.data) {
    perInsurerPayments?.data.forEach((item: any) => {
      item?.buckets?.forEach((bucket: any) => {
        const folder = dayjs(bucket.date).format(`YYYY`);
      });
    });
  }

  /* Download csv for monthly payments Per insurer table data */
  const getCsvPerInsurerTableData = async () => {
    try {
      if (selectedDateType && selectedBranches.length > 0 && startDate && endDate) {
        const perMonthQueryHeader = {
          insurercodes: perInsurerSelectedPayer.map((item: Options) => item.value).join(','),
        };
        const perSourceQueryData: DASH.PaymentsSource.Request = {
          startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
          endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
          branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
          insurerType: PayerType.Insurer,
        };
        const paymentInsurerResponse = await getPaymentsSourceAllDataService(perSourceQueryData, perMonthQueryHeader);
        if (paymentInsurerResponse) {
          return {formatedCsvData: getPerInsurerTableDataService(paymentInsurerResponse, insurerSelectedYear)};
        }
      }
    } catch (error) {
      return {formatedCsvData: []};
    }
  };

  let perInsurerHeadingNames = ['Payer Name', 'Payer Code'];
  const perInsurerFormattedTableData: any[] = [];
  let paginationCheck = false;
  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (insurerSelectedYear && insurerSelectedYear.value)
    perInsurerPayments?.data.forEach((item: any, idx: number) => {
      const row: any = [];
      const heading: any = [];
      let total = 0;
      const newBucket: any = {};
      let months: any = 12;

      item?.buckets?.forEach((bucket: any, i: number) => {
        const year = dayjs(bucket.date).format(`YYYY`);
        if (insurerSelectedYear.value === year) {
          const bucketYear = dayjs(bucket.date).format(`YY`);
          const bucketMonth = dayjs(bucket.date).format(`MMM`);
          const currentYear = dayjs(new Date()).format(`YY`);
          const currentMonth = dayjs(new Date()).format(`MM`);
          if (bucketYear === currentYear) {
            months = parseInt(currentMonth, 10);
            monthNames = monthNames.slice(0, months);
          }
          newBucket[bucketMonth] = bucket.amount;
        }
      });
      monthNames.forEach((name: string, i: number) => {
        heading.push(`${name} '${dayjs(insurerSelectedYear.value).format(`YY`)}`);
        total += newBucket[name] || 0;
        row.push(<NumberFormat value={newBucket[name] || 0} displayType={'text'} thousandSeparator decimalScale={2} />);
      });
      if (idx + 1 === perInsurerPayments?.data.length) {
        perInsurerHeadingNames = perInsurerHeadingNames.concat(heading);
        perInsurerHeadingNames.push('Total');
      }
      row?.unshift(item.name || '-', item.id || '-');
      row?.push(<NumberFormat value={total} displayType={'text'} thousandSeparator decimalScale={2} />);
      perInsurerFormattedTableData.push(row);
      paginationCheck = true;
    });

  const perInsurerTools = (
    <div className="toolsWrapper">
      {yearsArr.length > 1 && (
        <KlaimDropdownContainer
          showDescription
          title="Select Year"
          leftIcon={callendarIcon}
          value={insurerSelectedYear?.value}
        >
          <KlaimSelect options={yearsArr} onChange={setInsurerSelectedYear} />
        </KlaimDropdownContainer>
      )}
      <MultiSelectTool
        options={insurerOptions}
        value={perInsurerSelectedPayer}
        onChange={(selected) => {
          setPerInsurerSelectedPayer(selected);
        }}
        overrideStrings={{
          allItemsAreSelected: 'All Insurers',
          selectAll: 'All Insurers',
          selectSomeItems: 'Select Insurer(s)',
        }}
        leftIcon={insurerIcon}
      />
      {perInsurerFormattedTableData.length === 0 ? (
        <div> </div>
      ) : (
        <DownloadDashBtn
          data={paymentPerInsurerCsvData}
          position
          filename={'Payment-MonthlyPaymentsPerInsurer'}
          getTableData={getCsvPerInsurerTableData}
        />
      )}
    </div>
  );

  return (
    <Table
      title={`Monthly Payments Per Insurer  (${getCurrencyToDisplay()} Amount)`}
      headingData={perInsurerHeadingNames}
      bodyData={perInsurerFormattedTableData || []}
      countPerpage={perInsurerPaging.limit || 0}
      page={perInsurerPaging.limit}
      pushPaginationToRight
      totalResults={totalCount}
      showPagination={paginationCheck}
      handlePageChange={(pageNumber: number) => {
        const offset = pageNumber * 10;
        if (perInsurerPaging) setPerInsurerPaging({...perInsurerPaging, offset});
      }}
      tools={perInsurerTools}
      showLoading={isPerInsurerLoading}
    />
  );
};
