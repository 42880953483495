import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import {Colors} from 'modules/Common/constants/Colors';
import {RejectionsTotalData} from 'modules/Dashboard/api/types';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const TotalGraph: React.FC<RejectionsTotalData> = ({data}) => {
  const color = data.map((item) => item.color);
  let total = 0;
  let rejectionRate: number | string = 0;
  let paidRate: number | string = 0;
  data.map((item) => {
    total += item.value;
    if (item.name === 'Rejected') rejectionRate = item.value;
    else if (item.name === 'Paid') paidRate = item.value;
    return item;
  });
  const ratePercentage = (rejectionRate * 100) / total;
  const paidRatePercentage = (paidRate * 100) / total;
  const classes = useStylesFromThemeFunction();
  const options = {
    tooltip: {
      trigger: 'none',
    },
    legend: {
      top: '0%',
      left: '15%',
    },
    series: [
      {
        name: 'Total',
        type: 'pie',
        radius: ['60%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
        },
        label: {
          show: true,
          position: 'center',
          fontSize: 20,
          fontWeight: 'bold',
          backgroundColor: Colors.white,
          formatter: (item: any) => {
            return item.name === 'Rejected' ? `${Math.round(ratePercentage)}%` : `${Math.round(paidRatePercentage)}%`;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
            backgroundColor: Colors.white,
            formatter: (item: any) => {
              return item.name === 'Rejected' ? `${Math.round(ratePercentage)}%` : `${Math.round(paidRatePercentage)}%`;
            },
          },
        },
        labelLine: {
          show: false,
        },
        data,
        color,
      },
    ],
  };
  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
