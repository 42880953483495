import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import {Colors} from 'modules/Common/constants/Colors';
import {TopTenRejectionsPerSourceData} from 'modules/Dashboard/api/types';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const TopTenRejectionsGraph: React.FC<TopTenRejectionsPerSourceData> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const options = {
    grid: {
      bottom: 40,
      left: 90,
      right: 10,
      top: 70,
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: 'Rejections',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barWidth: data.rejections.length > 20 ? '60%' : 34,
        data: data.rejections,
        itemStyle: {color: Colors.purple},
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          // formatter: '{value}',
          // label: {
          //   formatter: (params: any) => {
          //     const hlabel = data.dataToUse.filter((item: any) => item.id === params.value);
          //     let label = hlabel[0].id;
          //     if (data.labelToUse === 'name') {
          //       label = hlabel[0].name;
          //       if (label === '') label = hlabel[0].id;
          //     }
          //     return label;
          //   },
          // },
        },
        data: data.xLabels,
        type: 'category',
        axisLabel: {
          formatter: '{value}',
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };
  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
