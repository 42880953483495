import React from 'react';
import './style.css';
import AgeingClaimsGraph from '../AgeingClaims/components/Graph';

const data = {
  tools: [],
  histogram: [
    {
      key: '<= 15 days',
      from: 1654987234278,
      from_as_string: '2022-06-11T22:40:34.278Z',
      to: 1656283234278,
      to_as_string: '2022-06-26T22:40:34.278Z',
      doc_count: 2623,
      rangeSum: {
        value: 2914128.159477234,
      },
    },
    {
      key: '15 - 30 days',
      from: 1653691234278,
      from_as_string: '2022-05-27T22:40:34.278Z',
      to: 1654987234278,
      to_as_string: '2022-06-11T22:40:34.278Z',
      doc_count: 275,
      rangeSum: {
        value: 238873.54941558838,
      },
    },
    {
      key: '30 - 45 days',
      from: 1652395234278,
      from_as_string: '2022-05-12T22:40:34.278Z',
      to: 1653691234278,
      to_as_string: '2022-05-27T22:40:34.278Z',
      doc_count: 1137,
      rangeSum: {
        value: 1357305.985610962,
      },
    },
    {
      key: '45 - 60 days',
      from: 1651099234278,
      from_as_string: '2022-04-27T22:40:34.278Z',
      to: 1652395234278,
      to_as_string: '2022-05-12T22:40:34.278Z',
      doc_count: 0,
      rangeSum: {
        value: 0,
      },
    },
    {
      key: '45 - 60 days',
      from: 1651099234278,
      from_as_string: '2022-04-27T22:40:34.278Z',
      to: 1652395234278,
      to_as_string: '2022-05-12T22:40:34.278Z',
      doc_count: 0,
      rangeSum: {
        value: 0,
      },
    },
    {
      key: '60 - 90 days',
      from: 1648507234278,
      from_as_string: '2022-03-28T22:40:34.278Z',
      to: 1651099234278,
      to_as_string: '2022-04-27T22:40:34.278Z',
      doc_count: 558,
      rangeSum: {
        value: 646247.3093795776,
      },
    },
    {
      key: '90 - 120 days',
      from: 1645915234278,
      from_as_string: '2022-02-26T22:40:34.278Z',
      to: 1648507234278,
      to_as_string: '2022-03-28T22:40:34.278Z',
      doc_count: 389,
      rangeSum: {
        value: 494177.23974990845,
      },
    },
    {
      key: '120 - 150 days',
      from: 1643323234278,
      from_as_string: '2022-01-27T22:40:34.278Z',
      to: 1645915234278,
      to_as_string: '2022-02-26T22:40:34.278Z',
      doc_count: 193,
      rangeSum: {
        value: 250728.1409187317,
      },
    },
    {
      key: '150 - 180 days',
      from: 1640731234278,
      from_as_string: '2021-12-28T22:40:34.278Z',
      to: 1643323234278,
      to_as_string: '2022-01-27T22:40:34.278Z',
      doc_count: 73,
      rangeSum: {
        value: 117530.75929260254,
      },
    },
    {
      key: '180 - 360 days',
      from: 1625179234278,
      from_as_string: '2021-07-01T22:40:34.278Z',
      to: 1640731234278,
      to_as_string: '2021-12-28T22:40:34.278Z',
      doc_count: 180,
      rangeSum: {
        value: 186183.08934020996,
      },
    },
    {
      key: '> 360 days',
      to: 1625179234278,
      to_as_string: '2021-07-01T22:40:34.278Z',
      doc_count: 247,
      rangeSum: {
        value: 188454.42974853516,
      },
    },
  ],
};
const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-79">
        <div className="contentContainer-0-1-80">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '41.6667%',
                flex: '0 0 41.6667%',
                maxWidth: '41.6667%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-89" style={{minHeight: '90%'}}>
                <div className="header-0-1-90">
                  <div className="title-0-1-91">Summary</div>
                  <div className="tools-0-1-92" />
                </div>
                <div className="body-0-1-88">
                  <div className="summaryContainer-0-1-87">
                    <div
                      className="boxesCont-0-1-86"
                      style={{
                        marginLeft: '-15px',
                        marginRight: '-15px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        flexGrow: 0,
                        flexShrink: 0,
                        alignItems: 'normal',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          boxSizing: 'border-box',
                          minHeight: '1px',
                          position: 'relative',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          width: '50%',
                          flex: '0 0 50%',
                          maxWidth: '50%',
                          marginLeft: '0%',
                          right: 'auto',
                          left: 'auto',
                        }}
                      >
                        <div className="numCont-0-1-94">
                          <div className="numContNumber-0-1-96">
                            <span>
                              <span>1,427</span>
                            </span>
                          </div>
                          <div className="numContLabel-0-1-95">Pending Claims</div>
                        </div>
                      </div>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          minHeight: '1px',
                          position: 'relative',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          width: '50%',
                          flex: '0 0 50%',
                          maxWidth: '50%',
                          marginLeft: '0%',
                          right: 'auto',
                          left: 'auto',
                        }}
                      >
                        <div className="numCont-0-1-94">
                          <div className="numContNumber-0-1-96">
                            <span>
                              <span>1,029</span>
                            </span>
                          </div>
                          <div className="numContLabel-0-1-95">&gt; 45 Days</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="boxesCont-0-1-86"
                      style={{
                        marginLeft: '-15px',
                        marginRight: '-15px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        flexGrow: 0,
                        flexShrink: 0,
                        alignItems: 'normal',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          boxSizing: 'border-box',
                          minHeight: '1px',
                          position: 'relative',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          width: '50%',
                          flex: '0 0 50%',
                          maxWidth: '50%',
                          marginLeft: '0%',
                          right: 'auto',
                          left: 'auto',
                        }}
                      >
                        <div className="numCont-0-1-94">
                          <div className="numContNumber-0-1-96">
                            <span>
                              <span>379,028</span>
                            </span>
                          </div>
                          <div className="numContLabel-0-1-95">SAR Pending Claims</div>
                        </div>
                      </div>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          minHeight: '1px',
                          position: 'relative',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          width: '50%',
                          flex: '0 0 50%',
                          maxWidth: '50%',
                          marginLeft: '0%',
                          right: 'auto',
                          left: 'auto',
                        }}
                      >
                        <div className="numCont-0-1-94">
                          <div className="numContNumber-0-1-96">
                            <span>
                              <span>213,963</span>
                            </span>
                          </div>
                          <div className="numContLabel-0-1-95">SAR &gt; 45 Days</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '58.3333%',
                flex: '0 0 58.3333%',
                maxWidth: '58.3333%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-89" style={{minHeight: '90%'}}>
                <AgeingClaimsGraph histogram={data.histogram} tools={data.tools} showLoading={false} />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'relative',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '1 0 0px',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-89" style={{height: 'auto'}}>
                <div className="header-0-1-90">
                  <div className="title-0-1-91">Ageing by Source</div>
                  <div className="tools-0-1-92">
                    <div className="toolsWrapper">
                      <div className="downloadPopup-0-1-99 dashDLBtn">
                        <button className="klaimBtn-0-1-106 false undefined klaimBtnIconMode-0-1-109 buttonTool-0-1-105">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-108"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-88">
                  <div className="tableWrapper-0-1-147">
                    <table className="klaimTable-0-1-157">
                      <thead className="klaimThead-0-1-162">
                        <tr>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">Receiver/TPA</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">&lt;= 15 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">15 - 30 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">30 - 45 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">45 - 60 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">60 - 90 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">120 - 150 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">150 - 180 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">180 - 360 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">&gt; 360 days</p>
                          </th>
                          <th className="klaimTh-0-1-161">
                            <p className="tableHeading-0-1-146">Total</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="klaimTbody-0-1-159">
                        <tr className="tableRow-0-1-148">
                          <td>A001</td>
                          <td>
                            <span>7,641.99</span>
                          </td>
                          <td>
                            <span>20,359.15</span>
                          </td>
                          <td>
                            <span>19,008.51</span>
                          </td>
                          <td>
                            <span>8,507.44</span>
                          </td>
                          <td>
                            <span>4,163.88</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,507.5</span>
                          </td>
                          <td>
                            <span>61,188.47</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A012</td>
                          <td>
                            <span>2,118.01</span>
                          </td>
                          <td>
                            <span>219.80</span>
                          </td>
                          <td>
                            <span>1,280.60</span>
                          </td>
                          <td>
                            <span>1,435.80</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>5,298</span>
                          </td>
                          <td>
                            <span>10,352.21</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A002</td>
                          <td>
                            <span>3,198.30</span>
                          </td>
                          <td>
                            <span>6,005.60</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>9,203.90</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A011</td>
                          <td>
                            <span>768.90</span>
                          </td>
                          <td>
                            <span>319.20</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,829.70</span>
                          </td>
                          <td>
                            <span>2,917.80</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A023</td>
                          <td>
                            <span>321</span>
                          </td>
                          <td>
                            <span>1,546.70</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,867.70</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A014</td>
                          <td>
                            <span>584</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>876</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,460</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A017</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>944.20</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>468</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,412.20</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A006</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>637.80</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>637.80</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A010</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>483</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>483</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-148">
                          <td>A003</td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>270</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>270</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paginationContainer-0-1-145 right">
                      <div className="paginationContainer-0-1-152">
                        <span />
                        <span className="paginationLeftRightButton-0-1-153" role="button">
                          <img src="/static/media/arrow_left_icon.5d82b0df.svg" alt="arrow-left" />
                        </span>
                        <div className="numberButtonsContainer-0-1-150">
                          <div
                            role="button"
                            className="paginationNumberButton-0-1-154 paginationNumberButtonActive-0-1-155"
                          >
                            1
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            2
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            3
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            4
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            5
                          </div>
                          <div role="button" className="paginationNumberButton-0-1-154 ">
                            6
                          </div>
                        </div>
                        <span className="paginationLeftRightButton-0-1-153" role="button">
                          <img src="/static/media/arrow_right_icon.7c4eefa8.svg" alt="arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
