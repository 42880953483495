import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import {Colors} from 'modules/Common/constants/Colors';
import {PaymentsPerMonthData} from 'modules/Dashboard/api/types';
import {CollectionsPerMonthSeries, TotalClaimsVSAmountDateFormats} from 'modules/Dashboard/api/strings';
import {labelFormatter} from 'modules/Dashboard/common/utils';
import {LabelTitleFormat} from 'modules/Dashboard/common/enums';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
      maxWidth: '90%',
    },
  };
});

export const PerMonthGraph: React.FC<PaymentsPerMonthData> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const options = {
    grid: {
      bottom: 40,
      left: 90,
      right: 10,
      top: 70,
      // width: '90%',
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: CollectionsPerMonthSeries,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barMaxWidth: data.base.length > 15 ? '40%' : 34,
        barCategoryGap: '10%',
        data: data.base,
        itemStyle: {color: Colors.teal},
        name: 'Base',
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barMaxWidth: data.paid.length > 15 ? '40%' : 34,
        barCategoryGap: '10%',
        data: data.paid,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.purple},
        name: 'Paid',
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      formatter: (params: any) =>
        labelFormatter(params, LabelTitleFormat.dateRange, true, getCurrencyToDisplay(), true),
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          label: {
            formatter: (params: any) => {
              const end = dayjs(params.value)
                .add(dayjs(params.value).daysInMonth() - 1, 'day')
                .format(TotalClaimsVSAmountDateFormats.CompleteDate);
              return `${dayjs(params.value).format(TotalClaimsVSAmountDateFormats.CompleteDate)} - ${end}`;
            },
          },
        },
        data: data.xLabels,
        type: 'category',
        axisLabel: {
          formatter: (value: any, index: number) => {
            if (index === 0 || dayjs(value).format(TotalClaimsVSAmountDateFormats.Monthly) === 'Jan') {
              return dayjs(value).format(`MMM`);
            }
            return dayjs(value).format(TotalClaimsVSAmountDateFormats.Monthly);
          },
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };

  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
