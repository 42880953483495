import {FC, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {NoData} from 'modules/Common/components/NoData';
import {Card} from 'modules/Common/components/Card';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {RejectionsPerPeriodData} from 'modules/Dashboard/api/types';
import {PerPeriodGraph} from 'modules/Dashboard/containers/Rejections/components/PerPeriodGraph';
import {HistogramMonthFilterGroup, FilterGroupRejectionPeriod, DownloadCsv} from 'modules/Dashboard/common/enums';
import {DASH} from 'modules/Dashboard/api';
import {ClaimsBreakdownDateRangeFormat} from 'modules/Dashboard/api/strings';
import {getTopRejectionSourceDataService} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const RejectionsPerPeriod: FC<DASH.Rejection.Response> = ({data, showLoading}): JSX.Element | null => {
  const [filterGroup2, setFilterGroup2] = useState(HistogramMonthFilterGroup.MONTHLY);
  const [graphData, setGraphData] = useState<RejectionsPerPeriodData>({
    data: {
      rejections: [],
      xLabels: [],
      year: 'yearly',
    },
  });
  const filterGroup1 = FilterGroupRejectionPeriod.AED_AMOUNT;

  const [rejectionPerPeriodData, setRejectionPerPeriodData] = useState<string[][]>([[]]);

  const parseData = () => {
    let dataToUse = null;

    switch (filterGroup2) {
      case HistogramMonthFilterGroup.MONTHLY:
        dataToUse = data?.histogramPerMonth;
        break;
      case HistogramMonthFilterGroup.YEARLY:
        dataToUse = data?.histogramPerYear;
        break;
      default:
        dataToUse = data?.histogramPerMonth;
        break;
    }

    const parsedData: RejectionsPerPeriodData = {
      data: {
        rejections: [],
        xLabels: [],
        year: filterGroup2 === 'Monthly' ? 'monthly' : 'yearly',
      },
    };

    dataToUse?.forEach((item) => {
      const formattedDate = dayjs(item.date).format(ClaimsBreakdownDateRangeFormat.dateFormat);
      parsedData.data.xLabels.push(formattedDate);
      parsedData.data.rejections.push(klaimDecimal(item?.rejected?.claimAmount) || 0);
    });

    setRejectionPerPeriodData(getTopRejectionSourceDataService(parsedData, filterGroup1, filterGroup2));
    setGraphData(parsedData);
  };

  const perPeriodTools =
    graphData.data.rejections.length === 0 ? (
      <div />
    ) : (
      <div className="toolsWrapper">
        <ToggleSwitch
          labels={Object.values(HistogramMonthFilterGroup)}
          onClick={(val: string) => {
            setFilterGroup2(val as HistogramMonthFilterGroup);
            parseData();
          }}
        />
        <DownloadDashBtn data={rejectionPerPeriodData} position filename={'Rejections-PerPeriod'} />
      </div>
    );

  useEffect(() => {
    parseData();
  }, [filterGroup2, data]);

  let renderContent: any = <NoData />;
  if (data && data?.histogramPerMonth.length > 0 && data?.histogramPerYear.length > 0)
    renderContent = <PerPeriodGraph data={graphData.data} />;
  return (
    <Card
      title={`Rejections Per Period (${getCurrencyToDisplay()})`}
      minHeight={'90%'}
      tools={perPeriodTools}
      showLoading={showLoading}
    >
      {renderContent}
    </Card>
  );
};
