import {FC, useEffect, useState} from 'react';
import arrowDownIcon from 'assets/arrow_down_icon.svg';
import arrowLeftIcon from 'assets/arrow_left_icon.svg';
import arrowRightIcon from 'assets/arrow_right_icon.svg';
import Paginator from 'paginator';

import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    arrowDownIcon: {
      marginRight: 15,
      width: 10,
    },
    numberButtonsContainer: {
      background: '#f5f7fb',
      borderRadius: '30px',
      display: 'flex',
      height: '40px',
      marginLeft: '30px',
    },
    pageNumberWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    paginationContainer: {
      display: 'flex',
    },
    paginationLeftRightButton: {
      '&:hover': {
        background: '#6135fb',
        color: 'white',
        cursor: 'pointer',
      },
      alignItems: 'center',
      background: '#f5f7fb',
      borderRadius: '50%',
      display: 'flex',
      height: 20,
      justifyContent: 'center',
      marginLeft: 30,
      padding: 10,
      width: 20,
    },

    paginationNumberButton: {
      '&:hover': {
        background: '#6135fb',
        color: 'white',
        cursor: 'pointer',
      },
      background: '#f5f7fb',
      borderRadius: '50%',
      fontWeight: 600,
      height: 20,
      padding: 10,
      textAlign: 'center',
      width: 20,
    },
    paginationNumberButtonActive: {
      background: '#6135fb',
      color: 'white',
      cursor: 'pointer',
    },
    paginationRowsButton: {
      background: '#f5f7fb',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      fontWeight: 600,
      height: 20,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
    },
  };
});

interface ComponentProps {
  isShowRowCount: boolean;
  rowsOnPage: number;
  rowsPerPage: number;
  totalResults: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: FC<ComponentProps> = ({isShowRowCount, rowsOnPage, rowsPerPage, totalResults, onPageChange}) => {
  const classes = useStylesFromThemeFunction();
  const [selectedPage, setSelectedPage] = useState(1);
  const [pagination, setPagination] = useState<any>();
  const [pagesArrayLeft, setPagesArrayLeft] = useState([1, 2, 3, 4, 5]);

  useEffect(() => {
    const paginator = new Paginator(rowsPerPage, rowsOnPage);
    const paginationInfo = paginator.build(totalResults, selectedPage);
    setPagination(paginationInfo);
  }, [rowsPerPage, rowsOnPage, totalResults, selectedPage]);

  const handlePageChange = (pageNumber: number) => {
    const direction = pageNumber < selectedPage ? 'L' : 'R';
    if (pageNumber >= 1 && pageNumber <= 4) {
      setPagesArrayLeft([1, 2, 3, 4, 5]);
    } else if (
      pageNumber >= pagesArrayLeft[3] &&
      direction === 'R' &&
      pagesArrayLeft[4] + 1 !== pagination.total_pages - 4
    ) {
      setPagesArrayLeft(pagesArrayLeft.map((pal) => pal + 1));
    } else if (pageNumber > 2 && pageNumber <= pagesArrayLeft[1] && direction === 'L') {
      setPagesArrayLeft(pagesArrayLeft.map((pal) => pal - 1));
    }
    if (pageNumber >= 1 && pageNumber <= pagination.total_pages) {
      setSelectedPage(pageNumber);
      onPageChange(pageNumber - 1);
    }
  };

  return (
    <div className={classes.paginationContainer}>
      {isShowRowCount ? (
        <span className={classes.paginationRowsButton}>
          <img src={arrowDownIcon} alt="arrow-down" className={classes.arrowDownIcon} />
          {rowsPerPage}
        </span>
      ) : (
        <span />
      )}
      {totalResults === 0 ? (
        ''
      ) : (
        <span
          className={classes.paginationLeftRightButton}
          role="button"
          onClick={() => handlePageChange(selectedPage - 1)}
        >
          <img src={arrowLeftIcon} alt="arrow-left" />
        </span>
      )}

      <div className={classes.numberButtonsContainer}>
        {pagination?.total_pages > 10 &&
          pagesArrayLeft.map((pageNumber) => {
            return (
              <div key={pageNumber} className={classes.pageNumberWrapper}>
                <div
                  role="button"
                  onClick={() => handlePageChange(pageNumber)}
                  className={`${classes.paginationNumberButton} ${
                    selectedPage === pageNumber ? classes.paginationNumberButtonActive : ''
                  }`}
                >
                  {pageNumber}
                </div>
                {pageNumber === pagesArrayLeft[4] && (
                  <div
                    role="button"
                    onClick={() => handlePageChange(pageNumber)}
                    className={`${classes.paginationNumberButton}`}
                  >
                    ...
                  </div>
                )}
              </div>
            );
          })}
        {pagination?.total_pages > 10 &&
          [...Array(5)].map((pageNumber, index) => {
            return (
              <div key={pagination.total_pages - 4 + index} className={classes.pageNumberWrapper}>
                <div
                  role="button"
                  onClick={() => handlePageChange(pagination.total_pages - 4 + index)}
                  className={`${classes.paginationNumberButton} ${
                    selectedPage === pagination.total_pages - 4 + index ? classes.paginationNumberButtonActive : ''
                  }`}
                >
                  {pagination.total_pages - 4 + index}
                </div>
              </div>
            );
          })}
        {pagination?.total_pages <= 10 &&
          [...Array(pagination.total_pages)].map((pageNumber, index) => (
            <div
              key={index}
              role="button"
              onClick={() => handlePageChange(index + 1)}
              className={`${classes.paginationNumberButton} ${
                selectedPage === index + 1 ? classes.paginationNumberButtonActive : ''
              }`}
            >
              {index + 1}
            </div>
          ))}
      </div>
      {totalResults === 0 ? (
        ''
      ) : (
        <span
          className={classes.paginationLeftRightButton}
          role="button"
          onClick={() => handlePageChange(selectedPage + 1)}
        >
          <img src={arrowRightIcon} alt="arrow-right" />
        </span>
      )}
    </div>
  );
};

export default Pagination;
