import React from 'react';
import './style.css';
import {TotalGraph} from '../Rejections/components/TotalGraph';
import {RejectionsPerPeriod} from '../Rejections/components/RejectionsPerPeriod';
import {RejectionsPerSource} from '../Rejections/components/RejectionsPerSource';

const chart1Data = [
  {
    value: 1421132,
    name: 'Rejected',
    color: '#D90B0B',
  },
  {
    value: 10456232.703335762,
    name: 'Paid',
    color: '#55F0EA',
  },
];

const chart2Data = {
  histogramPerMonth: [
    {
      date: '2022-01-01T00:00:00.000Z',
      totalCount: 655,
      rejected: {
        totalClaims: 655,
        claimAmount: 305472,
      },
    },
    {
      date: '2022-02-01T00:00:00.000Z',
      totalCount: 640,
      rejected: {
        totalClaims: 640,
        claimAmount: 339283,
      },
    },
    {
      date: '2022-03-01T00:00:00.000Z',
      totalCount: 762,
      rejected: {
        totalClaims: 762,
        claimAmount: 377189,
      },
    },
    {
      date: '2022-04-01T00:00:00.000Z',
      totalCount: 542,
      rejected: {
        totalClaims: 542,
        claimAmount: 289758,
      },
    },
    {
      date: '2022-05-01T00:00:00.000Z',
      totalCount: 119,
      rejected: {
        totalClaims: 119,
        claimAmount: 109430,
      },
    },
  ],
  histogramPerYear: [
    {
      date: '2022-01-01T00:00:00.000Z',
      totalCount: 2718,
      rejected: {
        totalClaims: 2718,
        claimAmount: 1421132,
      },
    },
  ],
  acceptedClaims: {
    totalClaims: 16593,
    claimAmount: 10456232.703335762,
  },
  rejectedClaims: {
    totalClaims: 2718,
    claimAmount: 1421132,
  },
  firstSubmissionClaims: {
    totalCount: {
      totalClaims: 12221,
      claimAmount: 10994125.894356728,
    },
    expandedClaims: {
      pendingClaims: {
        totalClaims: 4372,
        claimAmount: 4915296.8331336975,
      },
      acceptedClaims: {
        totalClaims: 9240,
        claimAmount: 7701701.52408886,
      },
      rejectedClaims: {
        totalClaims: 1025,
        claimAmount: 917892,
      },
      partiallyPaidClaims: {
        totalClaims: 1956,
        claimAmount: 2374532.370267868,
      },
    },
    pendingClaims: {
      totalClaims: 4372,
      claimAmount: 4915296.8331336975,
    },
    rejectionRatioClaims: {
      totalClaims: 11.093073593073594,
      claimAmount: 11.918041709732318,
    },
  },
  firstResubmissionClaims: {
    pendingClaims: {
      totalClaims: 803,
      claimAmount: 541307.687789917,
    },
    acceptedClaims: {
      totalClaims: 297,
      claimAmount: 292453.3995819092,
    },
    partiallyPaidClaims: {
      totalClaims: 657,
      claimAmount: 912526.5203323364,
    },
    rejectedClaims: {
      totalClaims: 356,
      claimAmount: 273601,
    },
  },
  resubmissionClaims: {
    resubmitted: {
      totalClaims: 2047,
      claimAmount: 1331386.6641197205,
    },
    notResubmitted: {
      totalClaims: 933,
      claimAmount: 432222.70780944824,
    },
  },
  topTenRejectionReasons: {
    medicalReasons: [
      {
        denialCode: 'MNEC-004',
        totalAmount: '263702',
        totalCount: '557',
        denialCodeName:
          'Service is not clinically indicated based on good clinical practice, without additional supporting diagnoses/activities',
      },
      {
        denialCode: 'MNEC-003',
        totalAmount: '103433',
        totalCount: '303',
        denialCodeName: 'Service is not clinically indicated based on good clinical practice',
      },
      {
        denialCode: 'MNEC-005',
        totalAmount: '108235',
        totalCount: '131',
        denialCodeName: 'Service/supply may be appropriate, but too frequent',
      },
      {
        denialCode: 'MNEC-006',
        totalAmount: '43145',
        totalCount: '108',
        denialCodeName: 'Alternative service should have been utilized',
      },
    ],
    technicalReasons: [
      {
        denialCode: 'PRCE-001',
        totalAmount: '66694',
        totalCount: '373',
        denialCodeName: 'Calculation discrepancy ',
      },
      {
        denialCode: 'COPY-001',
        totalAmount: '40750',
        totalCount: '284',
        denialCodeName: 'Deductible/co-pay not collected from member',
      },
      {
        denialCode: 'MNEC-007',
        totalAmount: '173002',
        totalCount: '263',
        denialCodeName: 'Diagnosis is inconsistent with the procedure',
      },
      {
        denialCode: 'CLAI-018',
        totalAmount: '34874',
        totalCount: '148',
        denialCodeName: 'Claims Recalled By Provider',
      },
      {
        denialCode: 'NCOV-003',
        totalAmount: '81811',
        totalCount: '104',
        denialCodeName: 'Service(s) is (are) not covered',
      },
      {
        denialCode: 'DUPL-001',
        totalAmount: '28668',
        totalCount: '86',
        denialCodeName: 'Claim is a duplicate based on service codes and dates',
      },
    ],
  },
};
const chart3Data = {
  rejectionPerInsurer: [
    {
      id: 'INS012',
      key: 406591,
      totalCount: 800,
      totalAmount: 406591,
      name: 'OMAN',
    },
    {
      id: 'INS137',
      key: 210694,
      totalCount: 371,
      totalAmount: 210694,
      name: 'CIGNA',
    },
    {
      id: 'INS026',
      key: 125272,
      totalCount: 281,
      totalAmount: 125272,
      name: 'DAMAN',
    },
    {
      id: 'INS013',
      key: 108638,
      totalCount: 163,
      totalAmount: 108638,
      name: 'ALICO/METLIFE',
    },
    {
      id: 'INS028',
      key: 92145,
      totalCount: 139,
      totalAmount: 92145,
      name: 'AL AIN',
    },
    {
      id: 'INS001',
      key: 90156,
      totalCount: 110,
      totalAmount: 90156,
      name: 'DUBAI - ENAYA 1',
    },
    {
      id: 'INS010',
      key: 62224,
      totalCount: 92,
      totalAmount: 62224,
      name: 'AXA',
    },
    {
      id: 'INS008',
      key: 47673,
      totalCount: 279,
      totalAmount: 47673,
      name: 'ORIENT',
    },
    {
      id: 'INS019',
      key: 33362,
      totalCount: 50,
      totalAmount: 33362,
      name: 'QATAR INSURANCE',
    },
    {
      id: 'INS044',
      key: 27962,
      totalCount: 62,
      totalAmount: 27962,
      name: 'NATIONAL LIFE AND GENERAL',
    },
  ],
  rejectionPerTPA: [
    {
      id: 'INS012',
      key: 406591,
      totalCount: 800,
      totalAmount: 406591,
      name: 'INS012',
    },
    {
      id: 'TPA001',
      key: 388414,
      totalCount: 631,
      totalAmount: 388414,
      name: 'TPA001',
    },
    {
      id: 'TPA004',
      key: 151366,
      totalCount: 221,
      totalAmount: 151366,
      name: 'TPA004',
    },
    {
      id: 'INS026',
      key: 119669,
      totalCount: 278,
      totalAmount: 119669,
      name: 'INS026',
    },
    {
      id: 'INS013',
      key: 108638,
      totalCount: 163,
      totalAmount: 108638,
      name: 'INS013',
    },
    {
      id: 'TPA002',
      key: 72505,
      totalCount: 357,
      totalAmount: 72505,
      name: 'TPA002',
    },
    {
      id: 'INS010',
      key: 61784,
      totalCount: 91,
      totalAmount: 61784,
      name: 'INS010',
    },
    {
      id: 'TPA036',
      key: 51371,
      totalCount: 85,
      totalAmount: 51371,
      name: 'TPA036',
    },
    {
      id: 'TPA003',
      key: 39681,
      totalCount: 44,
      totalAmount: 39681,
      name: 'TPA003',
    },
    {
      id: 'INS028',
      key: 17509,
      totalCount: 16,
      totalAmount: 17509,
      name: 'INS028',
    },
  ],
  rejectionPerProcedureCode: [
    {
      id: 'COMPLETE CBC W/AUTO DIFF WBC',
      key: 212635,
      totalCount: 524,
      totalAmount: 212635,
      name: 'COMPLETE CBC W/AUTO DIFF WBC',
    },
    {
      id: 'Consultation Consultant',
      key: 211507,
      totalCount: 644,
      totalAmount: 211507,
      name: 'Consultation Consultant',
    },
    {
      id: 'Consultation Specialist',
      key: 133680,
      totalCount: 479,
      totalAmount: 133680,
      name: 'Consultation Specialist',
    },
    {
      id: 'C-REACTIVE PROTEIN',
      key: 119658,
      totalCount: 293,
      totalAmount: 119658,
      name: 'C-REACTIVE PROTEIN',
    },
    {
      id: 'ASSAY THYROID STIM HORMONE',
      key: 102213,
      totalCount: 234,
      totalAmount: 102213,
      name: 'ASSAY THYROID STIM HORMONE',
    },
    {
      id: 'ASSAY OF CREATININE',
      key: 89915,
      totalCount: 245,
      totalAmount: 89915,
      name: 'ASSAY OF CREATININE',
    },
    {
      id: 'LIPID PANEL',
      key: 78720,
      totalCount: 169,
      totalAmount: 78720,
      name: 'LIPID PANEL',
    },
    {
      id: 'ALANINE AMINO (ALT) (SGPT)',
      key: 76257,
      totalCount: 188,
      totalAmount: 76257,
      name: 'ALANINE AMINO (ALT) (SGPT)',
    },
    {
      id: 'TRANSFERASE (AST) (SGOT)',
      key: 74687,
      totalCount: 184,
      totalAmount: 74687,
      name: 'TRANSFERASE (AST) (SGOT)',
    },
    {
      id: 'THERAPEUTIC EXERCISES',
      key: 63316,
      totalCount: 170,
      totalAmount: 63316,
      name: 'THERAPEUTIC EXERCISES',
    },
  ],
  rejectionPerClinician: [
    {
      id: 'DHA-P-13495896',
      key: 262436,
      totalCount: 293,
      totalAmount: 262436,
      name: '',
    },
    {
      id: 'DHA-P-0063208',
      key: 103999,
      totalCount: 123,
      totalAmount: 103999,
      name: 'ZBIGNIEW JANUSZ BRODZINSKI',
    },
    {
      id: 'DHA-P-0158124',
      key: 91759,
      totalCount: 163,
      totalAmount: 91759,
      name: 'IMAN ABISOUROUR',
    },
    {
      id: 'DHA-P-0233363',
      key: 90826,
      totalCount: 268,
      totalAmount: 90826,
      name: 'DONATELLA CASIGLIA',
    },
    {
      id: 'DHA-P-0110554',
      key: 70392,
      totalCount: 160,
      totalAmount: 70392,
      name: 'ROWENA EADIE',
    },
    {
      id: 'DHA-P-0207756',
      key: 59814,
      totalCount: 123,
      totalAmount: 59814,
      name: 'GEHAD EL GERGAWY',
    },
    {
      id: 'DHA-P-0045363',
      key: 58528,
      totalCount: 161,
      totalAmount: 58528,
      name: 'RUPINDER RUPRAI',
    },
    {
      id: 'DHA-P-0120951',
      key: 55928,
      totalCount: 118,
      totalAmount: 55928,
      name: 'CHARALAMPOS ZOURELIDIS',
    },
    {
      id: 'DHA-P-0180544',
      key: 55418,
      totalCount: 136,
      totalAmount: 55418,
      name: 'FABRIZIO FACCHINI',
    },
    {
      id: 'DHA-P-0219100',
      key: 51950,
      totalCount: 103,
      totalAmount: 51950,
      name: 'FAISAL BADDAR',
    },
  ],
};

const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-79">
        <div className="contentContainer-0-1-80">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '33.3333%',
                flex: '0 0 33.3333%',
                maxWidth: '33.3333%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Rejected vs Paid (SAR)</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div className="container-0-1-160">
                    <TotalGraph data={chart1Data} />;
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'relative',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '66.6667%',
                flex: '0 0 66.6667%',
                maxWidth: '66.6667%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="body-0-1-83">
                  <div className="container-0-1-161">
                    <RejectionsPerPeriod data={chart2Data} showLoading={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="body-0-1-83">
                  <div className="container-0-1-166">
                    <RejectionsPerSource data={chart3Data} showLoading={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Top 10 Reasons for Rejection</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="toggleSwitchEL-0-1-93" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Count
                        </div>
                        <div role="button">SAR Amount</div>
                      </div>
                      <div className="toggleSwitchEL-0-1-93" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Medical Reasons
                        </div>
                        <div role="button">Technical Reasons</div>
                      </div>
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="container-0-1-108">
                    <div className="reasonItem-0-1-109">
                      <div className="reasonDescWrap-0-1-110">
                        <div className="reasonDescText-0-1-111">
                          <span>MNEC-004</span> Service is not clinically indicated based on good clinical practice,
                          without additional supporting diagnoses/activities
                        </div>
                        <div className="reasonDescCount-0-1-112">
                          <span>24</span>
                        </div>
                      </div>
                      <div className="reasonBar-0-1-113">
                        <div className="reasonBarFilled-0-1-114" style={{width: '100%'}} />
                      </div>
                    </div>
                    <div className="reasonItem-0-1-109">
                      <div className="reasonDescWrap-0-1-110">
                        <div className="reasonDescText-0-1-111">
                          <span>MNEC-005</span> Claims Recalled By Provider
                        </div>
                        <div className="reasonDescCount-0-1-112">
                          <span>23</span>
                        </div>
                      </div>
                      <div className="reasonBar-0-1-113">
                        <div className="reasonBarFilled-0-1-114" style={{width: '95.8333%'}} />
                      </div>
                    </div>
                    <div className="reasonItem-0-1-109">
                      <div className="reasonDescWrap-0-1-110">
                        <div className="reasonDescText-0-1-111">
                          <span>MNEC-003</span> Deductible/co-pay not collected from member
                        </div>
                        <div className="reasonDescCount-0-1-112">
                          <span>8</span>
                        </div>
                      </div>
                      <div className="reasonBar-0-1-113">
                        <div className="reasonBarFilled-0-1-114" style={{width: '33.3333%'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'relative',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">1st Submission Outcome</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-118"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>2072</span>
                        </div>
                        <div className="numContLabel-0-1-164">Total Claims</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>1,620</span>
                        </div>
                        <div className="numContLabel-0-1-164">Accepted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>236</span>
                        </div>
                        <div className="numContLabel-0-1-164">(Partially) Accepted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>216</span>
                        </div>
                        <div className="numContLabel-0-1-164">Rejected</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">13.33 %</div>
                        <div className="numContLabel-0-1-164">Rejection Ratio Count</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="boxesCont-0-1-118"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>944,525.72</span>
                        </div>
                        <div className="numContLabel-0-1-164">Total Claims SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>788,561.29</span>
                        </div>
                        <div className="numContLabel-0-1-164">SAR Accepted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>69,224.43</span>
                        </div>
                        <div className="numContLabel-0-1-164">Partially Accepted SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '16.6667%',
                        flex: '0 0 16.6667%',
                        maxWidth: '16.6667%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>86,740</span>
                        </div>
                        <div className="numContLabel-0-1-164">Rejected SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>11.00 %</span>
                        </div>
                        <div className="numContLabel-0-1-164">Rejection Ratio SAR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '41.6667%',
                flex: '0 0 41.6667%',
                maxWidth: '41.6667%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Resubmissions Breakdown</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-122"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>348</span>
                        </div>
                        <div className="numContLabel-0-1-164">Not Resubmitted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>104</span>
                        </div>
                        <div className="numContLabel-0-1-164">Resubmitted</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="boxesCont-0-1-122"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>84,511.32</span>
                        </div>
                        <div className="numContLabel-0-1-164">Not Resubmitted SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>36,573.52</span>
                        </div>
                        <div className="numContLabel-0-1-164">Resubmitted SAR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '75%',
                flex: '0 0 75%',
                maxWidth: '75%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Resubmitted Claims Final Outcome</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-126"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>36</span>
                        </div>
                        <div className="numContLabel-0-1-164">Still Pending</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>31</span>
                        </div>
                        <div className="numContLabel-0-1-164">Finally Accepted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>30</span>
                        </div>
                        <div className="numContLabel-0-1-164">Partially Accepted</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>20</span>
                        </div>
                        <div className="numContLabel-0-1-164">Still Rejected</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="boxesCont-0-1-126"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>2,411</span>
                        </div>
                        <div className="numContLabel-0-1-164">Still Pending SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>14,187</span>
                        </div>
                        <div className="numContLabel-0-1-164">Finally Accepted SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>6,233</span>
                        </div>
                        <div className="numContLabel-0-1-164">Partially Accepted SAR</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '25%',
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-163">
                        <div className="numContNumber-0-1-165">
                          <span>3,526</span>
                        </div>
                        <div className="numContLabel-0-1-164">Still Rejected SAR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
