import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {
  TotalClaimsLabels,
  ClaimsBreakdownStatus,
  KPIsLabels,
  ClaimsBreakdownFilters,
  TotalClaimsVSAmountKeys,
  ClaimsBreakdownCsv,
} from 'modules/Dashboard/api/strings';
import {klaimDecimal} from 'modules/Common/utils/formatter';

/* Service for overview total csv */
export const getToatlDataService = (overviewData: any) => {
  let formattedData: any = [];
  if (overviewData?.data?.totalClaims && overviewData?.data?.totalClaims > 0) {
    formattedData = [
      [TotalClaimsLabels.Total, ''],
      [TotalClaimsLabels.Claims, parseFloat(`${overviewData.data.totalClaims}`).toFixed(2)],
      [
        `${getCurrencyToDisplay()} ${TotalClaimsLabels.Claims}`,
        parseFloat(`${overviewData.data.claimAmount}`).toFixed(2),
      ],
    ];
  }
  return formattedData;
};

/* Service for overview breakdown csv */
export const getBreakdownDataService = (overviewData: any) => {
  let total = 0;
  let formattedData: any = [];
  if (overviewData?.data) {
    total += overviewData?.data?.pendingClaims.totalClaims || 0;
    total += overviewData?.data?.pendingClaims.claimAmount || 0;
    total += overviewData?.data?.acceptedClaims.claimAmount || 0;
    total += overviewData?.data?.acceptedClaims.totalClaims || 0;
    total += overviewData?.data?.partiallyPaidClaims.claimAmount || 0;
    total += overviewData?.data?.partiallyPaidClaims.totalClaims || 0;
    total += overviewData?.data?.rejectedClaims.totalClaims || 0;
    total += overviewData?.data?.rejectedClaims.claimAmount || 0;
  }
  /* check total is greater than zero */
  if (total > 0) {
    formattedData = [
      [ClaimsBreakdownStatus.Breakdown, ''],
      [ClaimsBreakdownStatus.Pending, parseFloat(`${overviewData.data.pendingClaims.totalClaims}`).toFixed(2)],
      [ClaimsBreakdownStatus.Accepted, parseFloat(`${overviewData.data.acceptedClaims.totalClaims}`).toFixed(2)],
      [
        ClaimsBreakdownStatus.PartiallyAccepted,
        parseFloat(`${overviewData.data.partiallyPaidClaims.totalClaims}`).toFixed(2),
      ],
      [ClaimsBreakdownStatus.Rejected, parseFloat(`${overviewData.data.rejectedClaims.totalClaims}`).toFixed(2)],
      [
        `${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Pending}`,
        parseFloat(`${overviewData.data.pendingClaims.claimAmount}`).toFixed(2),
      ],
      [
        `${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Accepted}`,
        parseFloat(`${overviewData.data.acceptedClaims.claimAmount}`).toFixed(2),
      ],
      [
        `${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.PartiallyAccepted}`,
        parseFloat(`${overviewData.data.partiallyPaidClaims.claimAmount}`).toFixed(2),
      ],
      [
        `${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Rejected}`,
        parseFloat(`${overviewData.data.rejectedClaims.claimAmount}`).toFixed(2),
      ],
    ];
  }
  return formattedData;
};

/* Service for overview KPIs csv */
export const getKpisDataService = (overviewData: any) => {
  let formattedData: any = [];
  if (
    overviewData.data.kpi.averageAmount &&
    overviewData.data.kpi.averageDaysBetweenEncounterAndSubmission &&
    overviewData.data.kpi.averageDaysBetweenSubmissionAndPayment
  ) {
    formattedData = [
      [KPIsLabels.KPIs, ''],
      [
        KPIsLabels.AverageAPC,

        `${parseFloat(overviewData.data.kpi.averageAmount).toFixed(2)} ${getCurrencyToDisplay()}`,
      ],
      [
        KPIsLabels.EncounterToClaim,
        `${parseFloat(overviewData.data.kpi.averageDaysBetweenEncounterAndSubmission).toFixed(2)} Days`,
      ],
      [
        KPIsLabels.SubmissionTPayment,
        `${parseFloat(overviewData.data.kpi.averageDaysBetweenSubmissionAndPayment).toFixed(2)} Days`,
      ],
    ];
  }
  return formattedData;
};

/* Service for overview claime breakdown per period csv */
export const getBreakdownPeriodDataService = (overviewData: any, activeTab1: string, activeTab2: string) => {
  let formattedData: any = [];
  let breakdownPeriodData: string[][] = [];

  let dataKey = TotalClaimsVSAmountKeys.Amount;
  let histogramScope = overviewData?.data?.histogramPerMonth;
  if (activeTab1 === ClaimsBreakdownFilters.Count) dataKey = TotalClaimsVSAmountKeys.Count;
  if (activeTab2 === ClaimsBreakdownFilters.Yearly) histogramScope = overviewData?.data?.histogramPerYear;

  if (histogramScope && histogramScope.length > 0) {
    histogramScope.forEach((item: any, index: number) => {
      const date = new Date(item.date);
      const getMonthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      breakdownPeriodData = [
        ...breakdownPeriodData,
        [
          activeTab2 === ClaimsBreakdownFilters.Yearly ? `${date.getFullYear()}` : getMonthYear,
          item.paid ? klaimDecimal(item.paid[dataKey]).toString() : '0',
          item.rejected ? klaimDecimal(item.rejected[dataKey]).toString() : '0',
          item.submitted ? klaimDecimal(item.submitted[dataKey]).toString() : '0',
          item.partially_paid ? klaimDecimal(item.partially_paid[dataKey]).toString() : '0',
        ],
      ];
    });

    formattedData = [
      [
        activeTab2 === ClaimsBreakdownFilters.Yearly ? ClaimsBreakdownCsv.Yearly : ClaimsBreakdownCsv.Monthly,
        `${ClaimsBreakdownStatus.Accepted} ${activeTab1}`,
        `${ClaimsBreakdownStatus.Denied} ${activeTab1}`,
        `${ClaimsBreakdownStatus.Pending} ${activeTab1}`,
        `${ClaimsBreakdownStatus.PartiallyAccepted} ${activeTab1}`,
      ],
      ...breakdownPeriodData,
    ];
  }

  return formattedData;
};
