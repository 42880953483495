/*
  Formatting numbers by scientific notation -> shorthand
*/
export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'K'}, // thousand
    {value: 1e6, symbol: 'M'}, // million
    {value: 1e9, symbol: 'B'}, // billion
    {value: 1e12, symbol: 'T'}, // trillion
    {value: 1e15, symbol: 'QD'}, // quadrillion
    {value: 1e18, symbol: 'QT'}, // quintillion
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => {
      return num >= i.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const roundOff = (num: number) => {
  return Math.round(num);
};
export const klaimDecimal = (num: number | string | undefined) => {
  let number = 0;
  if (typeof num === 'string') number = parseFloat(parseInt(num, 10).toFixed(2));
  if (typeof num === 'number') number = parseFloat(num.toFixed(2));
  return number;
};

export const numberWithCommas = (num: number | string) => {
  let final = '0';
  if (typeof num === 'number') final = num.toString();
  if (typeof num === 'string') final = num;
  return final.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
