import {StringifiableRecord} from 'query-string';
import {ClaimsBreakdownDateRangeFormat} from 'modules/Dashboard/api/strings';
import dayjs from 'dayjs';

export const yearRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(1)
    .map((_, idx) => start + idx);
};

export const formatDashboardRequestRange = (queryData: any) => {
  const finalQuery: StringifiableRecord = queryData;
  if (queryData && queryData.endDate && queryData.startDate) {
    finalQuery.endDate = `${dayjs(queryData.endDate).format('MM')}/${dayjs(queryData.endDate).daysInMonth()}/${dayjs(
      queryData.endDate,
    ).format('YYYY')}`;
    finalQuery.startDate = dayjs(queryData.startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat);
  }
  return finalQuery;
};

export const formatReportRequestMonthRange = (queryData: any) => {
  const finalQuery = queryData;
  if (queryData && queryData.end && queryData.start) {
    finalQuery.end = `${dayjs(queryData.end).format('M')}/${dayjs(queryData.end).daysInMonth()}/${dayjs(
      queryData.end,
    ).format('YYYY')}`;
    finalQuery.start = dayjs(queryData.start).format('M/D/YYYY');
  }
  return finalQuery;
};
