import React, {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import axiosInstance from 'services/axios';
import Button from 'modules/Common/components/Button';
import {headerSelect, setRequiredFilters, setSubmitButtonOnClick} from 'modules/Header/headerSlice';
import {events, Mixpanel} from 'services/mixpanel';
import dayjs from 'dayjs';
import timeLogo from 'assets/time.svg';
import queryString from 'query-string';
import {Modal, Pagination, PaginationProps, Popover, Table, Typography} from 'custom-test-antd';
import {ColumnsType} from 'antd/lib/table';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import {CLAIM_SPECIALTY_MAP, getClaimType} from './data';
import {useStylesFromThemeFunction} from './MidTableDrafts';
import {errorCleanUp, getClaimDrafts, getClaims, midtableSelect, postClaimsBulk} from '../../midTableSlice';
import * as Toast from '../../../Common/utils/toast';
import {Midtable} from '../../../../interfaces/midtable.interface';

const DATE_FORMAT = `DD MMM 'YY`;

const formatBranchDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [generatingReport, setGeneratingReport] = useState(false);

  const {
    startDate,
    endDate,
    submitOnClick,
    selectedPayer,
    selectedReceiver,
    selectedVisits,
    selectedSynced,
    selectedType,
    selectedSpecialties,
  } = useAppSelector(headerSelect);
  const {selectedBranches} = useAppSelector(headerSelect);
  const {
    midtableDrafts: {data: dataList, count: dataCount},
    totals,
    error,
    apiCallSuccess,
  } = useAppSelector(midtableSelect);

  const {search} = queryString.parse(location.search);
  const dataSource = dataList.map((dataEl, index) => ({
    ...dataEl,
    key: index,
  }));

  const getAdditionalRequestParams = () => {
    const prId =
      selectedBranches.length > 0 ? {providerId: selectedBranches.map((provider) => provider.value).join(',')} : {};
    const pId = selectedPayer.length > 0 ? {payerId: selectedPayer.map((el: any) => el.value).join(',')} : {};
    const vts = selectedVisits.length > 0 ? {claimType: selectedVisits.map((el: any) => el.value).join(',')} : {};
    const synced = selectedSynced.length === 1 ? {isSynchronized: selectedSynced.some((select) => select.value)} : {};
    const tps = selectedType.length > 0 ? {claimTypeMain: selectedType.map((el: any) => el.value).join(',')} : {};
    const spcts =
      selectedSpecialties.length > 0 ? {claimSpeciality: selectedSpecialties.map((el: any) => el.value).join(',')} : {};
    const rId = selectedReceiver.length > 0 ? {receiverId: selectedReceiver.map((el: any) => el.value).join(',')} : {};

    return {
      ...pId,
      ...prId,
      ...vts,
      ...tps,
      ...synced,
      ...spcts,
      ...rId,
    };
  };

  useEffect(() => {
    dispatch(
      setRequiredFilters({
        submitButton: false,
        submitAllButton: false,
        submitFormButton: false,
        search: true,
        TPAFilter: false,
        typesFilter: true,
        visitFilter: true,
        dateRangeFilterDays: true,
        branchesFilter: true,
        specialtiesFilter: true,
        syncedFilter: true,
        receiversFilter: true,
        payersFilter: true,
      }),
    );
  }, [location.pathname]);

  useEffect(() => {
    if (submitOnClick) {
      dispatch(
        postClaimsBulk({
          providerId: selectedBranches.map((provider) => provider.value).join(','),
          hisIds: selectedRowKeys.map((el) => Number(el)),
        }),
      );
      dispatch(setSubmitButtonOnClick(false));
    }
  }, [submitOnClick]);

  useEffect(() => {
    if (error) {
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      setSelectedRowKeys([]);
      Toast.success('Operation succeeded');
      setTimeout(() => {
        dispatch(
          getClaims({
            limit,
            offset: (offset - 1) * limit,
            visitDateStart: formatBranchDate(startDate),
            visitDateEnd: formatBranchDate(endDate),
            ...getAdditionalRequestParams(),
            search: search as string,
          }),
        );
      }, 1000);
      dispatch(errorCleanUp());
    }
  }, [apiCallSuccess]);

  useEffect(() => {
    dispatch(
      getClaimDrafts({
        limit,
        offset: (offset - 1) * limit,
        visitDateStart: formatBranchDate(startDate),
        visitDateEnd: formatBranchDate(endDate),
        ...getAdditionalRequestParams(),
        search: search as string,
        hasError: true,
      }),
    );
  }, [
    limit,
    offset,
    startDate,
    endDate,
    selectedBranches,
    selectedPayer,
    selectedVisits,
    selectedSynced,
    selectedType,
    selectedSpecialties,
    search,
    selectedReceiver,
  ]);

  useEffect(() => {
    Mixpanel.track(events.pageViews.midTableListView);
  }, []);

  const columns: ColumnsType<Midtable> = [
    {
      title: 'HIS ID',
      width: '150px',
      render: (_, {hisId, created_at, claimType, speciality}) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span>{hisId}</span>
            <span className={classes.date}>
              <img src={timeLogo} alt="timeLogo" />
              {formatDate(created_at)}
            </span>
            <span className={classes.date}>
              <b>{getClaimType(claimType, speciality?.name)}</b>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'TENANT ID',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.tenantId ? item.tenantId : '-'}</div>
        </div>
      ),
    },
    {
      title: 'PATIENT NAME / PATIENT NO.',
      render: (_, item) => (
        <div className={classes.label}>
          <div>{item.patientName ? item.patientName : '-'}</div>
          <div className={classes.errorCell}>{item.patientNo ? item.patientNo : '-'}</div>
        </div>
      ),
    },
    {
      title: 'DOCTOR NAME / DOCTOR LICENSE',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.doctorName ? item.doctorName : '-'}</div>
          <div className={classes.errorCell}>{item.doctorLicense ? item.doctorLicense : '-'}</div>
        </div>
      ),
    },
    {
      title: 'VISIT DATE',
      width: '150px',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.visitDate ? item.visitDate : '-'}</div>
        </div>
      ),
    },
    {
      title: 'CLAIM SPECIALITY',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>
            {item.claimSpeciality ? CLAIM_SPECIALTY_MAP[item.claimSpeciality] : '-'}
          </div>
        </div>
      ),
    },
    {
      title: 'RECEIVER ID / PAYER ID',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.receiverId ? item.receiverId : '-'}</div>
          <div className={classes.errorCell}>{item.payerId ? item.payerId : '-'}</div>
        </div>
      ),
    },
    {
      title: 'BATCH NO.',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.batchNo ? item.batchNo : '-'}</div>
        </div>
      ),
    },
    {
      title: 'INVOICE NO.',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>{item.invoiceNumber ? item.invoiceNumber : '-'}</div>
        </div>
      ),
    },
    {
      title: 'ERRORS',
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.errorCell}>
            <Popover
              content={
                <>
                  {item.errors.map((err) => (
                    <p>{err}</p>
                  ))}
                </>
              }
              title="Errors"
              trigger="hover"
              placement="topLeft"
            >
              {item.errors.map((err) => (
                <p>{err}</p>
              ))}
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  const paginationProps: PaginationProps = {
    current: offset,
    onChange: (page) => setOffset(page),
    total: dataCount,
    showSizeChanger: true,
    onShowSizeChange: (current, size) => setLimit(size),
  };

  return (
    <div className={classes.container}>
      {/* <div className={classes.header}>{getHeader().map((el) => labelValue(el.label, el.value))}</div> */}
      <Table
        whiteHeader
        firstColumnPadding
        className={classes.tableContainer}
        columns={columns}
        dataSource={[...dataSource]}
        scroll={{x: 1490}}
        footer={() => (
          <div className={classes.footerContent}>
            <Typography.Title level={5} className={classes.totalAmountText}>
              TOTAL AMOUNT OF ENTRIES: {dataCount}
            </Typography.Title>
            <Pagination {...paginationProps} />
          </div>
        )}
        pagination={false}
      />
      <div className={classes.flexEndRow}>
        <Button
          loading={generatingReport}
          buttonClassName={classes.button}
          onClick={async () => {
            setGeneratingReport(true);
            await axiosInstance.get('/reports/generate/midtable', {
              params: {
                providerId: selectedBranches.map((provider) => provider.value).join(','),
                startDate: formatBranchDate(startDate),
                endDate: formatBranchDate(endDate),
                ...getAdditionalRequestParams(),
                search: search as string,
                hasError: true,
              },
            });
            Modal.info({
              title: 'Generate Report',
              content:
                'We\'ve received your request for generating a report. The report will be available for download in the "Reporting" section',
              okText: 'Close',
              centered: true,
            });
            setGeneratingReport(false);
          }}
          title="Download Excel"
        />
      </div>
    </div>
  );
};

export default Component;
