import React from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';
import dayjs from 'dayjs';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {Card} from 'modules/Common/components/Card';
import {NoData} from 'modules/Common/components/NoData';
import {
  RejectionReasonsTopTenData,
  RejectionsTotalData,
  RejectionResubmissionData,
  RejectionFirstSubmissionData,
  RejectionResubmissionFinalOutcome,
} from 'modules/Dashboard/api/types';
import {TopTenRejectionReasonsGraph} from 'modules/Dashboard/containers/Rejections/components/TopTenRejectionReasonsGraph';
import {TotalGraph} from 'modules/Dashboard/containers/Rejections/components/TotalGraph';
import {
  ClaimsBreakdownDateRangeFormat,
  TopTenRejectionReasonKeys,
  TopTenRejectionReasonCodeNameKeys,
  DashForbiddenMessage,
  TopTenRejectionReasonCsv,
} from 'modules/Dashboard/api/strings';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {Colors} from 'modules/Common/constants/Colors';
import {getRejectionsDataService, getRejectionsSourceDataService} from 'modules/Dashboard/api/requests';
import * as Toast from 'modules/Common/utils/toast';
import {DASH} from 'modules/Dashboard/api';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {ResubmissionsBreakdown} from 'modules/Dashboard/containers/Rejections/components/ResubmissionsBreakdown';
import {FirstSubmission} from 'modules/Dashboard/containers/Rejections/components/FirstSubmission';
import {ResubmissionFinalOutcome} from 'modules/Dashboard/containers/Rejections/components/ResubmissionFinalOutcome';
import {FilterGroupTotalStats, TotalStatsKeyMap} from 'modules/Dashboard/common/enums';
import {RejectionsPerPeriod} from 'modules/Dashboard/containers/Rejections/components/RejectionsPerPeriod';
import {RejectionsPerSource} from 'modules/Dashboard/containers/Rejections/components/RejectionsPerSource';
import {
  getRejectionTotalDataService,
  getTop10ReasonsRejectionDataService,
} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {events, Mixpanel} from 'services/mixpanel';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import StaticView from '../StaticHTML/Rejections';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    ...commonStyles,
  };
});

export const Rejections: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, rejectionSelectedDateType} = useAppSelector(headerSelect);

  const [totalActiveTab, setTotalActiveTab] = React.useState(FilterGroupTotalStats.AED_AMOUNT);
  const [topTenRejectionsReasonsActiveTab1, setTopTenRejectionsReasonsActiveTab1] = React.useState('Count');
  const [topTenRejectionsReasonsActiveTab2, setTopTenRejectionsReasonsActiveTab2] = React.useState('Medical Reasons');

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSourceLoading, setIsSourceLoading] = React.useState(false);
  const [rejectionDashboardData, setRejectionDashboardData] = React.useState<DASH.Rejection.Response | null>();
  const [rejectionSourceData, setRejectionSourceData] = React.useState<DASH.RejectionSource.Response | null>();

  const rejectionResubmissionData: RejectionResubmissionData | undefined =
    rejectionDashboardData?.data?.resubmissionClaims;
  const rejectionFirstSubmissionData: RejectionFirstSubmissionData | undefined =
    rejectionDashboardData?.data?.firstSubmissionClaims;
  const rejectionResubmissionFinalOutcomeData: RejectionResubmissionFinalOutcome | undefined =
    rejectionDashboardData?.data?.firstResubmissionClaims;

  const [rejectionsTotalCsvData, setRejectionsTotalCsvData] = React.useState<string[][]>([[]]);
  const [top10ReasonsRejectionCsvData, setTop10ReasonsRejectionCsvData] = React.useState<string[][]>([[]]);

  let filteredReasonData = null;
  if (topTenRejectionsReasonsActiveTab2 === TopTenRejectionReasonCodeNameKeys.Medical)
    filteredReasonData = rejectionDashboardData?.data?.topTenRejectionReasons?.medicalReasons;
  if (topTenRejectionsReasonsActiveTab2 === TopTenRejectionReasonCodeNameKeys.Technical)
    filteredReasonData = rejectionDashboardData?.data?.topTenRejectionReasons?.technicalReasons;

  const TopTenRejectionReasonsData: RejectionReasonsTopTenData | undefined = {
    data: filteredReasonData?.map((item) => {
      const {denialCodeName, denialCode, totalAmount, totalCount} = item;
      const reason = {
        denialCodeName,
        denialCode,
        count: 0,
      };
      if (topTenRejectionsReasonsActiveTab1 === TopTenRejectionReasonKeys.Count)
        reason.count = parseInt(totalCount, 10);
      if (topTenRejectionsReasonsActiveTab1 === TopTenRejectionReasonKeys.Amount)
        reason.count = parseInt(totalAmount, 10);
      return reason;
    }),
  };

  const fetchRejectionDashboardData = async (queryData: any) => {
    if (startDate && endDate) {
      setIsLoading(true);
      try {
        const data = await getRejectionsDataService(queryData);
        if (data) {
          setIsLoading(false);
          setRejectionDashboardData(data);
          setRejectionsTotalCsvData(getRejectionTotalDataService(data, FilterGroupTotalStats.COUNT));
          setTop10ReasonsRejectionCsvData(
            getTop10ReasonsRejectionDataService(
              data,
              TopTenRejectionReasonKeys.Count,
              TopTenRejectionReasonCsv.MedicalLabel,
            ),
          );
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error === DashForbiddenMessage) {
          setRejectionDashboardData(null);
          setTop10ReasonsRejectionCsvData([]);
          setRejectionsTotalCsvData([]);
        } else Toast.error(error);
      }
    }
  };
  const fetchRejectionSourceData = async (queryData: any) => {
    if (startDate && endDate) {
      setIsSourceLoading(true);
      try {
        const data = await getRejectionsSourceDataService(queryData);

        if (data) {
          setIsSourceLoading(false);
          setRejectionSourceData(data);
        }
      } catch (error: any) {
        setIsSourceLoading(false);
        if (error === DashForbiddenMessage) setRejectionDashboardData(null);
        else Toast.error(error);
      }
    }
  };

  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        rejectionDateTypeFilter: true,
        dateRangeFilterMonth: true,
        branchesFilter: true,
      }),
    );
    Mixpanel.track(events.pageViews.dashboardRejection);
  }, []);

  React.useEffect(() => {
    const queryData = {
      dateType: rejectionSelectedDateType.value,
      startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
      endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
      branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
    };
    fetchRejectionDashboardData(queryData);
    fetchRejectionSourceData(queryData);
  }, [selectedBranches, startDate, endDate, rejectionSelectedDateType]);

  const topTenRejectionsReasonsTools =
    rejectionDashboardData?.data?.topTenRejectionReasons.medicalReasons.length === 0 &&
    rejectionDashboardData?.data?.topTenRejectionReasons.technicalReasons.length === 0 ? (
      <div> </div>
    ) : (
      <div className="toolsWrapper">
        <ToggleSwitch
          labels={[TopTenRejectionReasonKeys.Count, TopTenRejectionReasonKeys.Amount]}
          onClick={(val: string) => {
            setTopTenRejectionsReasonsActiveTab1(val);
            setTop10ReasonsRejectionCsvData(
              getTop10ReasonsRejectionDataService(rejectionDashboardData, val, topTenRejectionsReasonsActiveTab2),
            );
          }}
        />
        <ToggleSwitch
          labels={[TopTenRejectionReasonCodeNameKeys.Medical, TopTenRejectionReasonCodeNameKeys.Technical]}
          onClick={(val: string) => {
            setTopTenRejectionsReasonsActiveTab2(val);
            setTop10ReasonsRejectionCsvData(
              getTop10ReasonsRejectionDataService(rejectionDashboardData, topTenRejectionsReasonsActiveTab1, val),
            );
          }}
        />
        <DownloadDashBtn
          data={top10ReasonsRejectionCsvData}
          position
          filename={'Rejections-Top10ReasonsForRejection'}
        />
      </div>
    );

  const renderTopTenRejectionReasonsGraph = (
    <Card
      title="Top 10 Reasons for Rejection"
      minHeight={'90%'}
      tools={topTenRejectionsReasonsTools}
      showLoading={isLoading}
    >
      <TopTenRejectionReasonsGraph data={TopTenRejectionReasonsData.data} />
    </Card>
  );

  // line 236 download button
  // const totalTools = (
  //   <div>
  //     <div className="toolsWrapper">
  //       <DownloadDashBtn data={rejectionsTotalCsvData} position={false} filename={`Rejections-Total`} />
  //     </div>
  //   </div>
  // );

  const renderTotal = () => {
    const paidClaims = rejectionDashboardData?.data?.acceptedClaims;
    const rejectedClaims = rejectionDashboardData?.data?.rejectedClaims;
    const keyToUse = totalActiveTab === FilterGroupTotalStats.COUNT ? TotalStatsKeyMap.COUNT : TotalStatsKeyMap.AMOUNT;

    const parsedData: RejectionsTotalData = {
      data: [
        {
          value: (rejectedClaims && rejectedClaims[keyToUse]) || 0,
          name: 'Rejected',
          color: Colors.red,
        },
        {
          value: (paidClaims && paidClaims[keyToUse]) || 0,
          name: 'Paid',
          color: Colors.teal,
        },
      ],
    };
    let renderContent: any = <NoData />;
    if (
      paidClaims &&
      rejectedClaims &&
      paidClaims.totalClaims + paidClaims.claimAmount + rejectedClaims.totalClaims + rejectedClaims.claimAmount > 0
    ) {
      let total = 0;
      parsedData.data.forEach((item) => {
        total += item.value;
      });
      if (total > 0) {
        renderContent = <TotalGraph data={parsedData.data} />;
      }
    }
    return (
      <Card title={`Rejected vs Paid (${getCurrencyToDisplay()})`} minHeight={'90%'} showLoading={isLoading}>
        {renderContent}
      </Card>
    );
  };

  /* Show error toast no data */
  const getNoDataContent = (
    <>
      <h2>There is no data for that selection.</h2>
      <p>Please try with another filter selection.</p>
    </>
  );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticView />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col style={{position: 'static'}} lg={12} xl={4}>
                {renderTotal()}
              </Col>
              <Col lg={12} xl={8}>
                <RejectionsPerPeriod data={rejectionDashboardData?.data} showLoading={isLoading} />
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} lg={12}>
                <RejectionsPerSource data={rejectionSourceData?.data} showLoading={isSourceLoading} />
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} lg={12}>
                {renderTopTenRejectionReasonsGraph}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FirstSubmission
                  expandedClaims={rejectionFirstSubmissionData?.expandedClaims}
                  totalCount={rejectionFirstSubmissionData?.totalCount}
                  pendingClaims={rejectionFirstSubmissionData?.pendingClaims}
                  rejectionRatioClaims={rejectionFirstSubmissionData?.rejectionRatioClaims}
                  showLoading={isLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} lg={12} xl={5}>
                <ResubmissionsBreakdown
                  resubmitted={rejectionResubmissionData?.resubmitted}
                  notResubmitted={rejectionResubmissionData?.notResubmitted}
                  showLoading={isLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} lg={12} xl={9}>
                <ResubmissionFinalOutcome
                  partiallyPaidClaims={rejectionResubmissionFinalOutcomeData?.partiallyPaidClaims}
                  rejectedClaims={rejectionResubmissionFinalOutcomeData?.rejectedClaims}
                  acceptedClaims={rejectionResubmissionFinalOutcomeData?.acceptedClaims}
                  pendingClaims={rejectionResubmissionFinalOutcomeData?.pendingClaims}
                  showLoading={isLoading}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
