import {FC, useEffect, useState} from 'react';

import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {NoData} from 'modules/Common/components/NoData';
import {Card} from 'modules/Common/components/Card';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {createUseStyles} from 'react-jss';
import {TopTenRejectionsPerSourceData} from 'modules/Dashboard/api/types';
import {DASH} from 'modules/Dashboard/api';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {TopTenRejectionKeys} from 'modules/Dashboard/api/strings';
import {TopTenRejectionsGraph} from 'modules/Dashboard/containers/Rejections/components/TopTenRejectionsGraph';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {getTopRejectionPerSourceDataService} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';
import {klaimDecimal} from 'modules/Common/utils/formatter';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    ...commonStyles,
  };
});

export const RejectionsPerSource: FC<DASH.RejectionSource.Response> = ({data, showLoading}): JSX.Element | null => {
  const classes = useStylesFromThemeFunction();

  const [topTenRejectionsActiveTab, setTopTenRejectionsActiveTab] = useState(TopTenRejectionKeys.Insurer as string);
  const [graphData, setGraphData] = useState<TopTenRejectionsPerSourceData>({
    data: {
      rejections: [],
      xLabels: [],
      labelToUse: '',
      dataToUse: null,
    },
  });

  const [topRejectionPerSourceData, setTopRejectionPerSourceData] = useState<string[][]>([[]]);

  const parseData = () => {
    let dataToUse = null;
    let labelToUse = '';

    switch (topTenRejectionsActiveTab) {
      case TopTenRejectionKeys.Procedure:
        dataToUse = data?.rejectionPerProcedureCode;
        labelToUse = 'name';
        break;
      case TopTenRejectionKeys.Clinician:
        dataToUse = data?.rejectionPerClinician;
        labelToUse = 'name';
        break;
      case TopTenRejectionKeys.TPA:
        dataToUse = data?.rejectionPerTPA;
        labelToUse = 'name';
        break;
      default:
        dataToUse = data?.rejectionPerInsurer;
        labelToUse = 'id';
        break;
    }

    const parsedData: TopTenRejectionsPerSourceData = {
      data: {
        rejections: [],
        xLabels: [],
        labelToUse,
        dataToUse: null,
      },
    };

    dataToUse
      ?.sort((a, b) => {
        return b.totalAmount - a.totalAmount;
      })
      .forEach((item) => {
        parsedData.data.rejections.push(klaimDecimal(item.totalAmount));
        if (labelToUse === 'id') parsedData.data.xLabels.push(item.id.length > 0 ? item.id : item.name);
        else if (labelToUse === 'name') parsedData.data.xLabels.push(item.name.length > 0 ? item.name : item.id);
      });

    parsedData.data.dataToUse = dataToUse;

    setTopRejectionPerSourceData(getTopRejectionPerSourceDataService(parsedData, topTenRejectionsActiveTab));
    setGraphData(parsedData);
  };

  const topTenRejectionsTools =
    graphData.data.rejections.length === 0 ? (
      <div> </div>
    ) : (
      <div className="toolsWrapper">
        <ToggleSwitch
          labels={[
            TopTenRejectionKeys.Insurer,
            TopTenRejectionKeys.TPA,
            TopTenRejectionKeys.Procedure,
            TopTenRejectionKeys.Clinician,
          ]}
          onClick={(val: string) => {
            setTopTenRejectionsActiveTab(val);
          }}
        />
        <DownloadDashBtn data={topRejectionPerSourceData} position filename={'Rejections-Top10RejectionsPerSource'} />
      </div>
    );

  useEffect(() => {
    parseData();
  }, [topTenRejectionsActiveTab, data]);

  let renderContent: any = <NoData />;
  if (graphData.data.rejections.length > 0) renderContent = <TopTenRejectionsGraph data={graphData.data} />;
  return (
    <Card
      title={`Top 10 Rejections Per Source (${getCurrencyToDisplay()} Amount)`}
      minHeight={'90%'}
      tools={topTenRejectionsTools}
      showLoading={showLoading}
    >
      {renderContent}
    </Card>
  );
};
