import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import {Colors} from 'modules/Common/constants/Colors';
import {ClaimsBreakdownData} from 'modules/Dashboard/api/types';
import {
  ClaimsBreakdownSeries,
  TotalClaimsVSAmountDateFormats,
  ClaimsBreakdownFilters,
} from 'modules/Dashboard/api/strings';
import {labelFormatter} from 'modules/Dashboard/common/utils';
import {LabelTitleFormat} from 'modules/Dashboard/common/enums';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const ClaimsBreakdown: React.FC<ClaimsBreakdownData> = ({data, scope, tab}) => {
  const tabIdentifier = () => {
    if (tab === ClaimsBreakdownFilters.Count) {
      return '';
    }
    return getCurrencyToDisplay();
  };
  const classes = useStylesFromThemeFunction();
  const options = {
    grid: {
      bottom: 40,
      left: 90,
      right: 10,
      top: 70,
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: ClaimsBreakdownSeries,
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barWidth: data.accepted.length > 20 ? '60%' : 34,
        data: data.accepted,
        itemStyle: {color: Colors.teal},
        name: ClaimsBreakdownSeries.Accepted,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.accepted.length > 20 ? '60%' : 34,
        data: data.denied,
        itemStyle: {color: Colors.red},
        name: ClaimsBreakdownSeries.Denied,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.accepted.length > 20 ? '60%' : 34,
        data: data.pending,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.purple},
        name: ClaimsBreakdownSeries.Pending,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.accepted.length > 20 ? '60%' : 34,
        data: data.partially_paid,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.yellow},
        name: ClaimsBreakdownSeries.PartiallyAccepted,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      formatter: (params: any) => labelFormatter(params, LabelTitleFormat.dateRange, true, tabIdentifier(), true),
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          label: {
            formatter: (params: any) => {
              const identifiedParams = dayjs(params.value).isLeapYear();
              const endMonthly = dayjs(params.value)
                .add(dayjs(params.value).daysInMonth() - 1, 'day')
                .format(TotalClaimsVSAmountDateFormats.CompleteDate);
              const endYearly = identifiedParams
                ? dayjs(params.value).dayOfYear(366).format(TotalClaimsVSAmountDateFormats.CompleteDate)
                : dayjs(params.value).dayOfYear(365).format(TotalClaimsVSAmountDateFormats.CompleteDate);
              // const end = dayjs(params.value)
              //   .add(dayjs(params.value).daysInMonth() - 1, 'day')
              //   .add(1, 'year')
              //   .add(-1, 'month')
              //   .format(TotalClaimsVSAmountDateFormats.CompleteDate);
              const start = dayjs(new Date(params.value).toUTCString()).format(
                TotalClaimsVSAmountDateFormats.CompleteDate,
              );
              return `${start} - ${scope === ClaimsBreakdownFilters.Monthly ? endMonthly : endYearly}`;
            },
          },
        },
        data: data.xLabels,
        type: 'category',
        axisLabel: {
          formatter: (value: any, index: number) => {
            if (scope === ClaimsBreakdownFilters.Monthly) {
              if (index === 0 || dayjs(value).format(TotalClaimsVSAmountDateFormats.Monthly) === 'Jan') {
                return dayjs(value).format(TotalClaimsVSAmountDateFormats.MonthWithYear);
              }
              return dayjs(value).format(TotalClaimsVSAmountDateFormats.Monthly);
            }
            return dayjs(new Date(value).toUTCString()).add(1, 'day').format(TotalClaimsVSAmountDateFormats.Yearly);
          },
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };
  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
