import {FC} from 'react';
import ReactECharts from 'echarts-for-react';
import {NoData} from 'modules/Common/components/NoData';
import {Card} from 'modules/Common/components/Card';
import {AgeingOrderBy, AgeingOrderByNames} from 'modules/Dashboard/api/strings';
import {HistogramDataType, SeriesData} from 'modules/Dashboard/api/types';

import {Colors} from 'modules/Common/constants/Colors';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

interface ComponentProps {
  histogram: any;
  tools: any;
  showLoading?: boolean;
}
const AgeingClaimsGraph: FC<ComponentProps> = ({histogram, tools, showLoading}) => {
  let histogramDataOptions = {};

  if (histogram) {
    const histogramGraphData: SeriesData[] = [];
    const arrangedHistogram = histogram.sort((a: HistogramDataType, b: HistogramDataType) => {
      return AgeingOrderBy.indexOf(a.key) - AgeingOrderBy.indexOf(b.key);
    });

    arrangedHistogram.forEach((element: HistogramDataType) => {
      const itemExists = histogramGraphData.filter((item: SeriesData) => item.name === element.key);
      const roundedOff = klaimDecimal(element.rangeSum.value);
      if (itemExists.length === 0) histogramGraphData.push({name: element.key, data: roundedOff});
    });

    histogramDataOptions = {
      grid: {
        left: 120,
        right: 40,
        top: 20,
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
        },
        type: 'category',
        data: AgeingOrderByNames,
        nameLocation: 'middle',
      },
      yAxis: {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
        nameLocation: 'middle',
      },
      tooltip: {
        axisPointer: {
          crossStyle: {
            color: Colors.gray,
          },
          type: 'cross',
        },
        trigger: 'axis',
      },
      series: [
        {
          barWidth: histogramGraphData.length > 20 ? '60%' : 34,
          data: histogramGraphData.map((item) => item.data),
          itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.purple},
          type: 'bar',
          // label: {
          //   show: true,
          //   position: 'top',
          //   formatter: (params: any) => {
          //     return nFormatter(params.value, 2);
          //   },
          // },
        },
      ],
    };
  }

  return (
    <Card
      title={`Pending Claims Ageing (${getCurrencyToDisplay()} Amount)`}
      minHeight={'90%'}
      tools={tools}
      showLoading={showLoading}
    >
      {histogram[0] && histogram[0].doc_count !== 0 ? (
        <ReactECharts style={{padding: 10}} option={histogramDataOptions} />
      ) : (
        <NoData />
      )}
    </Card>
  );
};

export default AgeingClaimsGraph;
