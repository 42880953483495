import {FC, useState} from 'react';
import NumberFormat from 'react-number-format';
import {Row, Col} from 'react-grid-system';
import {NoData} from 'modules/Common/components/NoData';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {Card} from 'modules/Common/components/Card';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {RejectionFirstSubmissionData, StatItem, ExpandedClaims} from 'modules/Dashboard/api/types';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {getFirstSubmissionDataService} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';
import {forEach} from 'lodash';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const FirstSubmission: FC<RejectionFirstSubmissionData> = ({
  totalCount,
  expandedClaims,
  rejectionRatioClaims,
  showLoading,
}) => {
  const classes = useStylesFromThemeFunction();

  // line 187 download button
  // const firstSubmissionTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn
  //       data={getFirstSubmissionDataService(totalCount, expandedClaims, rejectionRatioClaims)}
  //       position
  //       filename={`Rejections-1stSubmissionOutcome`}
  //     />
  //   </div>
  // );

  const totalValueRejection = rejectionRatioClaims?.totalClaims;
  if (!totalValueRejection) {
    return null;
  }
  const totalRejectionRatioCount = Math.round(totalValueRejection * 100) / 100;

  let renderContent: any = <NoData />;
  let total = 0;
  let xKeys: any;
  if (expandedClaims) {
    xKeys = Object.keys(expandedClaims);
    xKeys.forEach((x: string) => {
      const claim: StatItem = expandedClaims[x];
      total += claim?.claimAmount || 0;
      total += claim?.totalClaims || 0;
    });
  }
  if (totalCount) {
    total += totalCount?.claimAmount || 0;
    total += totalCount?.totalClaims || 0;
  }
  if (rejectionRatioClaims) {
    total += rejectionRatioClaims?.claimAmount || 0;
    total += rejectionRatioClaims?.totalClaims || 0;
  }

  if (total > 0)
    renderContent = (
      <>
        <Row className={classes.boxesCont}>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={<NumberFormat value={totalCount?.totalClaims} displayType={'text'} decimalScale={2} />}
              label={'Total Claims'}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.acceptedClaims?.totalClaims}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={'Accepted'}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.partiallyPaidClaims?.totalClaims}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={'(Partially) Accepted'}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.rejectedClaims?.totalClaims}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={'Rejected'}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3}>
            <NumbersContainer number={`${totalRejectionRatioCount} %`} label={'Rejection Ratio Count'} />
          </Col>
        </Row>

        <Row className={classes.boxesCont}>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={
                <NumberFormat value={totalCount?.claimAmount} displayType={'text'} decimalScale={2} thousandSeparator />
              }
              label={`Total Claims ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.acceptedClaims?.claimAmount}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={`${getCurrencyToDisplay()} Accepted`}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.partiallyPaidClaims?.claimAmount}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={`Partially Accepted ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={2}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={expandedClaims?.rejectedClaims?.claimAmount}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                />
              }
              label={`Rejected ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col sm={12} md={12} lg={8} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={rejectionRatioClaims?.claimAmount}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                  suffix=" %"
                />
              }
              label={`Rejection Ratio ${getCurrencyToDisplay()}`}
            />
          </Col>
        </Row>
      </>
    );
  return (
    <Card title="1st Submission Outcome" height={'auto'} showLoading={showLoading}>
      {renderContent}
    </Card>
  );
};
