import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import {Colors} from 'modules/Common/constants/Colors';
import {TopTenCliniciansData} from 'modules/Dashboard/api/types';
import {CliniciansTopTenSeries, TopTenClinicianKeys} from 'modules/Dashboard/api/strings';
import {labelFormatter} from 'modules/Dashboard/common/utils';
import {LabelTitleFormat} from 'modules/Dashboard/common/enums';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const TopTenGraph: React.FC<TopTenCliniciansData> = ({data, tab}) => {
  const tabIdentifier = () => {
    if (tab === TopTenClinicianKeys.Count) {
      return '';
    }
    return getCurrencyToDisplay();
  };
  const classes = useStylesFromThemeFunction();
  const options = {
    grid: {
      bottom: 40,
      left: 90,
      right: 10,
      top: 70,
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: CliniciansTopTenSeries,
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barWidth: data.submitted.length > 20 ? '60%' : 34,
        data: data.paid,
        itemStyle: {color: Colors.teal},
        name: CliniciansTopTenSeries.Paid,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.submitted.length > 20 ? '60%' : 34,
        data: data.rejected,
        itemStyle: {color: Colors.red},
        name: CliniciansTopTenSeries.Rejected,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.submitted.length > 20 ? '60%' : 34,
        data: data.submitted,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.purple},
        name: CliniciansTopTenSeries.Pending,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
      {
        barWidth: data.submitted.length > 20 ? '60%' : 34,
        data: data.partially_paid,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.yellow},
        name: CliniciansTopTenSeries.PartiallyPaid,
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      formatter: (params: any) => labelFormatter(params, LabelTitleFormat.string, true, tabIdentifier(), true),
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          label: {
            formatter: '{value}',
          },
        },
        data: data.xLabels,
        type: 'category',
        axisLabel: {
          interval: 0,
          formatter(value: string) {
            if (value.length > 10) {
              return `${value.substring(0, 6)}\n${value.substr(6)}`;
            }
            return value;
          },
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };
  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
