import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {Card} from 'modules/Common/components/Card';
import Pagination from 'modules/Common/components/Pagination';
import GlobalTable from 'modules/Common/components/Table';
import {OverlayLoadingSpinner} from 'modules/Common/components/OverlayLoadingSpinner';
import {NoData} from 'modules/Common/components/NoData';
import {Colors} from 'modules/Common/constants/Colors';
import NumberFormat from 'react-number-format';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    paginationContainer: {
      '&.right': {
        justifyContent: 'flex-end !important',
      },
      alignItems: 'center',
      borderTop: `1px solid ${Colors.grayWithOpacity}`,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      paddingBottom: 15,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 15,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
      fontSize: '.8vw',
    },
    tableWrapper: {
      '& th:first-child': {
        paddingLeft: 50,
      },
      '& td:first-child': {
        paddingLeft: 50,
      },
      flexGrow: 1,
    },
    tableRow: {
      fontSize: '.7vw',
    },
  };
});

interface ComponentProps {
  title: string;
  headingData: any[];
  bodyData: any;
  showPagination?: boolean;
  page?: number;
  countPerpage?: number | 0;
  totalResults?: number | 0;
  pushPaginationToRight?: boolean;
  handlePageChange: (pageNumber: number) => void;
  tools: any;
  showLoading?: boolean;
}

export const Table: FC<ComponentProps> = ({
  headingData,
  bodyData,
  showPagination,
  page,
  countPerpage,
  pushPaginationToRight,
  totalResults,
  title,
  handlePageChange,
  tools,
  showLoading,
}) => {
  const classes = useStylesFromThemeFunction();
  const renderTableHeadings = headingData?.map((name) => {
    return <p className={classes.tableHeading}>{name}</p>;
  });

  const TableRowItem = ({idx, data}: {idx: number; data: any}) => (
    <tr key={idx} className={classes.tableRow}>
      {data.map((value: any, _idx: number) => {
        if (typeof value === 'number')
          value = <NumberFormat thousandSeparator value={value} displayType="text" decimalScale={2} />;
        return <td key={_idx + 1}>{value}</td>;
      })}
    </tr>
  );

  let renderContent: any = <NoData />;
  if (bodyData.length > 0)
    renderContent = (
      <GlobalTable
        loading={false}
        tableHeadings={renderTableHeadings}
        renderBody={() => (
          <>
            {bodyData.map((value: any, index: number) => {
              return <TableRowItem idx={index} data={value} key={index} />;
            })}
          </>
        )}
      />
    );
  return (
    <Card title={title} height={'auto'} tools={tools}>
      <div className={classes.tableWrapper}>
        {showLoading && <OverlayLoadingSpinner />}
        {renderContent}
        {showPagination && (
          <div className={`${classes.paginationContainer} ${pushPaginationToRight ? 'right' : 'left'}`}>
            <Pagination
              isShowRowCount={false}
              rowsOnPage={countPerpage || 0}
              rowsPerPage={page || 0}
              totalResults={totalResults || 0}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
