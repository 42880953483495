import React from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {NoData} from 'modules/Common/components/NoData';
import {RejectionReasonsTopTenData} from 'modules/Dashboard/api/types';
import NumberFormat from 'react-number-format';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
    reasonItem: {
      display: 'block',
      marginBottom: 15,
    },
    reasonDescWrap: {display: 'flex', justifyContent: 'space-between', fontSize: 12, marginBottom: 5},

    reasonDescText: {
      paddingLeft: 20,
      '& span': {
        fontWeight: 700,
      },
    },
    reasonDescCount: {},
    reasonBar: {
      width: '100%',
      borderRadius: 10,
      background: Colors.grayLight,
      height: 17,
    },
    reasonBarFilled: {
      borderRadius: 10,
      background: Colors.purple,
      height: 17,
    },
  };
});

export const TopTenRejectionReasonsGraph: React.FC<RejectionReasonsTopTenData> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const numbers: number[] = [];
  let renderGraph: any = <NoData />;
  if (data && data.length > 0) {
    data
      ?.sort((a, b) => {
        return b.count - a.count;
      })
      .map((item) => {
        numbers.push(item.count);
        return item.count;
      });
    const max = Math.max(...numbers);
    renderGraph = data?.map((item, i) => {
      const width = (item.count * 100) / max;
      return (
        <div key={`item_${i}`} className={classes.reasonItem}>
          <div className={classes.reasonDescWrap}>
            <div className={classes.reasonDescText}>
              <span>{item.denialCode}</span> {item.denialCodeName}
            </div>
            <div className={classes.reasonDescCount}>
              <NumberFormat value={item.count} displayType={'text'} thousandSeparator />
            </div>
          </div>
          <div className={classes.reasonBar}>
            <div className={classes.reasonBarFilled} style={{width: `${width}%`}} />
          </div>
        </div>
      );
    });
  }

  return <div className={classes.container}>{renderGraph}</div>;
};
