import React, {FC, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {get} from 'lodash';
import {ClaimStatus} from 'modules/Claims/api/types';
import {useHistory} from 'react-router';
import {useStylesFromThemeFunction} from './Components/ClaimForm';
import Patient from './Components/Patient';
import VitalSigns from './Components/VitalSigns';
import ClaimDiagnosis from './Components/Diagnosis';
import ClaimLab from './Components/ClaimLab';
import ClaimActivities from './Components/ClaimActivities';
import ClaimRadiology from './Components/ClaimRadiology';
import {useAppDispatch, useAppSelector} from '../../../../App/store';
import {
  claimSelect,
  errorClaimCleanup,
  // errorCleanUp,
  getHistory,
  resubmitClaim,
} from '../../../claimsSlice';

import {getGenders, getMaritalStatuses, getNationalities, midtableSelect} from '../../../../MidTable/midTableSlice';
import * as Toast from '../../../../Common/utils/toast';
import Button from '../../../../Common/components/Button';
import {getEligibilityForm} from '../../../../Eligibility/eligibilitySlice';
import {getHistory as getPreAuthHistory, preAuthV2Select} from '../../../../PreAuthV2/PreAuthV2Slice';
import {submitClaim} from '../../../api';

export const checkErroredFields = (errors, fieldCriteria) => {
  if (errors && errors.length > 0) {
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      if (error.message?.includes(fieldCriteria)) {
        return true;
      }
    }
  }
  return false;
};

const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {nationalities, apiCallSuccess} = useAppSelector(midtableSelect);
  const {error, isCancellationSuccess, priodClaimResubmitNewId} = useAppSelector(claimSelect);
  const {history: claim} = useAppSelector(claimSelect);
  const [submission, setSubmission] = useState<any>({});
  const [financialData, setFinancialData] = useState();
  const [hisId, setHisId] = useState();
  const {history: preAuthDetail} = useAppSelector(preAuthV2Select);
  const [midtable, setMidatble] = useState<any>();
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);

  useEffect(() => {
    setMidatble(claim);
  }, [claim]);
  useEffect(() => {
    setMidatble(preAuthDetail);
    setSubmission(get(preAuthDetail, 'submissions[0]', {}));
  }, [preAuthDetail]);

  const history = useHistory();

  const useQuery = () => {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const id = query.get('id');
  const eligibilityId = query.get('eligibilityId');
  const preAuthId = query.get('preAuthId');
  const providerId = query.get('providerId');

  useEffect(() => {
    if (id)
      dispatch(
        getHistory({
          id,
          providerId,
        }),
      );
    dispatch(getNationalities({providerId}));
  }, [location.pathname]);

  useEffect(() => {
    setFinancialData(midtable?.financialData);
    setHisId(midtable?.hisId);
    setSubmission(get(midtable, 'submissions[0]', {}));
  }, [midtable]);

  useEffect(() => {
    if (error) {
      Toast.error(get(error, 'payload.response.data.message[0]', 'there was an error'));
      dispatch(errorClaimCleanup());
    }
  }, [error]);

  useEffect(() => {
    if (isCancellationSuccess) {
      Toast.success('Operation succeeded');
      if (priodClaimResubmitNewId) {
        setTimeout(() => {
          history.push(`/claims/details/${priodClaimResubmitNewId}?providerId=${providerId}`);
        }, 1000);
      } else {
        history.push(`/claims/details/${id}?providerId=${providerId}`);
      }
      // dispatch(errorCleanUp());
      // dispatch(
      //   getClaim({
      //     id,
      //     providerId,
      //   }),
      // );
    }
  }, [isCancellationSuccess, priodClaimResubmitNewId]);

  React.useEffect(() => {
    if (providerId && eligibilityId) {
      dispatch(getEligibilityForm({id: eligibilityId, providerId}));
    }
  }, [providerId, eligibilityId]);

  React.useEffect(() => {
    if (providerId && preAuthId) {
      dispatch(getPreAuthHistory({id: preAuthId, providerId}));
    }
  }, [providerId, preAuthId, dispatch]);

  return (
    <div className={classes.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}
        className={classes.popupContainer}
      >
        <div
          style={{
            position: 'relative',
          }}
          className={classes.popupContainer}
        >
          <Button
            title={'Submit claim'}
            disabled={disableSubmitButton}
            onClick={() => {
              if (preAuthId || claim?.status === ClaimStatus.error || claim?.status === ClaimStatus.submitting) {
                setDisableSubmitButton(true);
                const {
                  claimId,
                  relatedToPriorClaimRel,
                  relatedToPriorClaim,
                  careTeam,
                  item,
                  ...rest
                } = submission.claim;
                const form = {
                  // id: id || get(preAuthDetail, 'submissions[0].claimId', ''),
                  providerId,
                  ...submission,
                  // claim: {...rest, relatedToPriorClaimRel: 'prior'},
                  claim: {
                    ...rest,
                    // relatedToPa: get(preAuthDetail, 'responses[0].response.preAuthRef'),
                    paResp: get(preAuthDetail, 'responses[0].response.preAuthIdentifier'),
                    careTeam: careTeam.map((el) => {
                      const {practitioner, ...care} = el;
                      return care;
                    }),
                    item: item.map((el) => {
                      return {...el, itemQty: +el.itemQty};
                    }),
                    diagnosis: rest.diagnosis.map((d) => ({...d, diagOnAdmission: d.diagOnAdmission ? 'y' : 'n'})),
                    supportingInfo: rest.supportingInfo?.map((si) => ({
                      ...si,
                      category: si.category,
                      valueQuantity: +si.valueQuantity,
                    })),
                  },
                  formerClaim: id,
                  financialData,
                  hisId,
                  type: 'claim-request',
                };
                delete form.id;
                delete form.claimId;
                submitClaim(form)
                  .then((resp) => {
                    setTimeout(() => {
                      history.push(`/claims/details/${resp.data?.id}?providerId=${providerId}`);
                      // if (claim?.status === ClaimStatus.error) {
                      //   history.push(`/midtable`);
                      // } else {
                      //   history.push(`/claims/details/${resp.data?.id}?providerId=${providerId}`);
                      // }
                    }, 1000);
                  })
                  .catch((err: any) => {
                    if (err.response?.data) {
                      err.response?.data?.stack?.forEach((e) => {
                        Toast.error(e);
                      });
                    }
                  });
              }
            }}
          />
          {!disableSubmitButton &&
            !preAuthId &&
            claim?.status !== ClaimStatus.error &&
            claim?.status !== ClaimStatus.submitting && (
              <div className={classes.popup}>
                <h1
                  onClick={async () => {
                    setDisableSubmitButton(true);
                    dispatch(
                      resubmitClaim({
                        id: id || get(preAuthDetail, 'submissions[0].claimId', ''),
                        providerId,
                        ...submission,
                        claim: {
                          ...submission.claim,
                          relatedToPriorClaimRel: 'prior',
                          item: submission.claim.item.map((item) => ({...item, itemQty: +item.itemQty})),
                        },
                        financialData,
                        hisId,
                      }),
                    );
                  }}
                >
                  Prior Claim
                </h1>
                <h1
                  onClick={() => {
                    setDisableSubmitButton(true);
                    dispatch(
                      resubmitClaim({
                        id: id || get(preAuthDetail, 'submissions[0].claimId', ''),
                        providerId,
                        ...submission,
                        claim: {...submission.claim, relatedToPriorClaimRel: 'associated'},
                        financialData,
                        hisId,
                      }),
                    );
                  }}
                >
                  Associated Claim
                </h1>
                <h1
                  onClick={() => {
                    setDisableSubmitButton(true);
                    dispatch(
                      resubmitClaim({
                        id: id || get(preAuthDetail, 'submissions[0].claimId', ''),
                        providerId,
                        ...submission,
                        claim: {...submission.claim, relatedToPriorClaimRel: 'extend'},
                        financialData,
                        hisId,
                      }),
                    );
                  }}
                >
                  Authorization to extend
                </h1>
              </div>
            )}
        </div>
      </div>
      {midtable?.responses[midtable.responses.length - 1].errors?.length > 0 && (
        <div style={{background: 'white', borderRadius: 10, padding: 10}}>
          <p>Errors:</p>
          {midtable?.responses[midtable.responses.length - 1].errors.map((err) => (
            <p style={{fontSize: 12, color: 'red'}}>- {err?.description ? err?.description : err?.display}</p>
          ))}
        </div>
      )}
      <Patient
        data={get(midtable, 'submissions[0].patient', {})}
        coverage={get(midtable, 'submissions[0].coverage', {})}
        providerId={providerId}
        nationalities={nationalities}
        submissionData={submission}
        setSubmission={setSubmission}
        errors={midtable?.responses[midtable.responses.length - 1].errors}
      />
      <VitalSigns
        data={get(midtable, 'submissions[0].claim.supportingInfo', [])}
        providerId={providerId}
        setSubmission={setSubmission}
        submission={submission}
      />
      <ClaimDiagnosis
        data={get(submission, 'claim.diagnosis', [])}
        providerId={providerId}
        setSubmission={setSubmission}
        submission={submission}
        errors={midtable?.responses[midtable.responses.length - 1].errors}
      />
      <ClaimActivities
        items={get(submission, 'claim.item', [])}
        response={get(midtable, 'responses', [])}
        careTeam={get(submission, 'claim.careTeam', [])}
        setSubmission={setSubmission}
        submission={submission}
        providerId={providerId}
        diagnosis={get(submission, 'claim.diagnosis', [])}
        supportingInfo={get(submission, 'claim.supportingInfo', [])}
        errors={midtable?.responses[midtable.responses.length - 1].errors}
      />
      {/* <ClaimLab data={midtable} /> */}
      {/* <ClaimRadiology data={midtable} /> */}
    </div>
  );
};

export default Component;
