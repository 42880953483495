import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    toastMsg: {
      position: 'fixed',
      bottom: '10px',
      right: '10px',
      zIndex: 1001,
    },
    innerToastMsg: {
      width: '300px',
      background: Colors.white,
      boxShadow: `${Colors.RMSC.toastShadow} 1px 1px 3px`,
      padding: '20px 30px',
      borderRadius: '10px',
      textAlign: 'left',
      '& span': {
        position: 'absolute',
        background: Colors.lightGreen,
        height: '44px',
        left: '14px',
        borderRadius: '20px',
        width: '6px',
      },
      '& h2': {
        fontSize: '16px',
        margin: '0',
        color: Colors.blackLight,
      },
      '& p': {
        fontSize: '13px',
        margin: '0',
        color: Colors.grayLighter,
      },
    },
  };
});

interface ComponentProps {
  overrideContent?: any | undefined;
}

export const SuccessToast: FC<ComponentProps> = ({overrideContent}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.toastMsg}>
      <div className={classes.innerToastMsg}>
        <span>&nbsp;</span>
        {overrideContent}
      </div>
    </div>
  );
};
