import {FC, useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import dlIcon from 'assets/dl_icon_v2.svg';
import {Colors} from 'modules/Common/constants/Colors';
import Button from 'modules/Common/components/Button';
import dlOptionIcon from 'assets/dl_report_icon.svg';
import dlOptionIconColor from 'assets/dl_option_icon_color.svg';
import XLSX from 'xlsx';
import {DownloadFileTypes} from 'modules/Common/strings';
import {NoDataSelections} from 'modules/Common/components/NoDataSelections';
import {SuccessToast} from 'modules/Common/components/SuccessToast';
import {useStateWithPromise} from 'modules/Common/hooks/useStateWithPromise';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    downloadIcon: {
      backgroundImage: `url(${dlIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: 20,
      paddingLeft: 20,
      paddingRight: 10,
    },
    downloadBtn: {
      alignItems: 'center',
      background: Colors.purple,
      border: 'none',
      borderRadius: 10,
      boxSizing: 'border-box',
      color: Colors.white,
      display: 'flex',
      fontSize: 12,
      fontWeight: 500,
      height: 35,
      MozAppearance: 'none',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 18,
      paddingTop: 10,
    },
    downloadPopup: {
      '&:click': {
        '& $downloadPopupContent': {display: 'block'},
        cursor: 'pointer',
      },
      position: 'relative',
    },
    downloadPopupContent: {
      '& hr': {
        border: 'none',
        background: Colors.RMSC.hrBorder,
        height: '1px',
      },
      backgroundColor: Colors.white,
      borderRadius: 10,
      boxSizing: 'border-box',
      display: 'block',
      fontSize: 14,
      MozBoxShadow: `0px 16px 22px 3px ${Colors.RMSC.shadow}`,
      MozBoxSizing: 'border-box',
      paddingBottom: 10,
      paddingTop: 10,
      position: 'absolute',
      WebkitBoxShadow: `0px 5px 10px ${Colors.RMSC.downloadBtnShadow}`,
      WebkitBoxSizing: 'border-box',
      width: '150px',
      zIndex: 1,
    },
    downloadPopupItem: {
      '&:hover': {
        background: Colors.blueGrayLight,
        color: Colors.purple,
        '& img': {
          content: `url(${dlOptionIconColor})`,
        },
      },
      cursor: 'pointer',
      fontWeight: 400,
      fontSize: '0.8rem',
      marginBottom: 0,
      marginTop: 0,
      paddingBottom: 10,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 10,
      float: 'left',
      textAlign: 'left',
      width: '101px',
      '& img': {width: '15px', position: 'relative', right: '10px', top: '5px'},
    },
    downloadPopupText: {
      fontWeight: 300,
      fontSize: 12,
      textAlign: 'center',
    },
    downloadBtnDisabled: {
      background: Colors.purpleLight,
    },
    popupPosition: {
      right: 0,
    },
    buttonTool: {
      position: 'relative',
      padding: '5px 10px',
      border: `1px solid ${Colors.grayLight}`,
      background: Colors.blueGrayLight,
      '& img': {
        marginRight: 0,
        height: 15,
        filter: 'invert(75%)',
      },
    },
  };
});

interface ComponentProps {
  disabled?: boolean;
  data: string[][] | null;
  position: boolean;
  filename: string;
  getTableData?: () => any;
}

export const DownloadDashBtn: FC<ComponentProps> = ({
  disabled = false,
  data,
  position = true,
  filename,
  getTableData,
}) => {
  const classes = useStylesFromThemeFunction();
  const [expanded, setExpanded] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [fileData, setFileData] = useState<any | null>(null);
  const [startDownload, setStartDownload] = useStateWithPromise(false);

  /* Set Request data in Local State */
  useEffect(() => {
    if (data) {
      setFileData(data);
    }
  }, [data]);

  const downloadFile = (fileType: string, downloadData: any): void => {
    /*  converts an array of arrays of JS data to a worksheet */
    const ws = XLSX.utils.aoa_to_sheet(downloadData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, `${getModifiedFileName(fileType)}`);
  };

  /* Download cloud button file type options */
  const setSelectedFileExtensionAndDownloadFile = async (fileType: string) => {
    /* Set Local Variable */
    let localFileData = fileData;
    /* Close File Type popup */
    setExpanded(false);
    /* Start Downloading */
    await setStartDownload(true);
    /* Check TableData Download File Request */
    if (getTableData instanceof Function) {
      localFileData = await setRequestedTableData(getTableData);
    }
    /* Check Data exists */
    if (localFileData && localFileData.length > 0) {
      /* Download FIle */
      downloadFile(fileType, localFileData);
    } else {
      /* Show Error Notification when No Data  */
      showErrorDownloadNotification();
    }
    /* Stop Download */
    await setStartDownload(false);
  };

  /* Call table API request callback and set table data in local state  */
  const setRequestedTableData = (callBackFn: any) => {
    return new Promise((resolve, reject) => {
      callBackFn()
        .then((response: any) => {
          if (response) {
            const {formatedCsvData} = response;
            if (formatedCsvData.length > 0) {
              setFileData(formatedCsvData);
              resolve(formatedCsvData);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((err: any) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(null);
        });
    });
  };

  /* Get Modified File Name */
  const getModifiedFileName = (ext: string) => {
    return `${filename}-${new Date().getTime()}.${ext}`;
  };

  /* show notifications on csv download */
  const showErrorDownloadNotification = async () => {
    await setStartDownload(false);
    setShowErrorToast(true);
    setTimeout(() => {
      setShowErrorToast(false);
    }, 2000);
  };

  /* Content in error toast */
  const getNoDataContent = (
    <>
      <h2>There is no data for that selection.</h2>
      <p>Please try with another filter selection.</p>
    </>
  );

  /* Content in  Success toast */
  const getSuccessContent = (
    <>
      <h2>Download has started!.</h2>
      <p>Please wait, this might take a few minutes…</p>
    </>
  );

  return (
    <div
      className={`${classes.downloadPopup} dashDLBtn`}
      onMouseLeave={() => setExpanded(false)}
      onMouseEnter={() => setExpanded(true)}
    >
      <Button iconLeft={dlIcon} buttonClassName={classes.buttonTool} onClick={() => !disabled} />
      {expanded && (
        <div className={`${classes.downloadPopupContent} ${position && classes.popupPosition}`}>
          <p className={classes.downloadPopupText}>Download all records as</p>
          <hr />
          <>
            {DownloadFileTypes?.map((option: {label: string; value: string}, index) => (
              <p
                key={index}
                className={classes.downloadPopupItem}
                onClick={() => {
                  setSelectedFileExtensionAndDownloadFile(option.value);
                  setExpanded(false);
                }}
              >
                {<img alt="icon" src={dlOptionIcon} />}
                {option.label}
              </p>
            ))}
          </>
        </div>
      )}
      {/* Display Download Report Status Popup  */}
      {startDownload && <SuccessToast overrideContent={getSuccessContent} />}
      {/* Error Toast */}
      {showErrorToast && <NoDataSelections overrideContent={getNoDataContent} />}
    </div>
  );
};
