import {roundOff} from 'modules/Common/utils/formatter';
import {TopTenClinicianKeys, TopTenClinicianCsv, ClinicianTableNames} from 'modules/Dashboard/api/strings';

/* Service for claims per ordering clinician csv */
export const getClaimsPerOrderingAllData = (clinicianData: any) => {
  let formatedData: any = [];
  let claimPerOrdingData: any[][] = [];
  if (clinicianData && clinicianData.length > 0) {
    clinicianData.forEach((item: any) => {
      const numsAmount: any = {};
      const numsCount: any = {};
      item.buckets.forEach((bucket: any) => {
        numsAmount[bucket.key] = bucket.totalAmount;
        numsCount[bucket.key] = bucket.totalCount;
      });
      const netAmount: any = Object.values(numsAmount).reduce((a: any, b) => a + b, 0);
      const netCount: any = Object.values(numsCount).reduce((a: any, b) => a + b, 0);
      const rejection = numsCount.rejected ? (numsCount.rejected / netCount) * 100 : 0;
      claimPerOrdingData = [
        ...claimPerOrdingData,
        [
          item.id || '-',
          item.noOfClaims,
          roundOff(netAmount),
          roundOff(numsAmount.paid || 0),
          roundOff(numsAmount.submitted || 0),
          roundOff(numsAmount.rejected || 0),
          rejection.toFixed(2),
        ],
      ];
    });

    formatedData = [ClinicianTableNames, ...claimPerOrdingData];
  }
  return formatedData;
};

/* Service for top 10 ordering clinicians csv */
export const getTop10OrderingDataService = (clinicianData: any, activeTab: string) => {
  let formatedData: any = [];
  let PerProcedure: string[][] = [];
  if (
    clinicianData &&
    clinicianData.data &&
    clinicianData.data.topTenOrderingClinicians &&
    clinicianData.data.topTenOrderingClinicians.length > 0
  ) {
    clinicianData.data.topTenOrderingClinicians.forEach((item: any) => {
      let paid = 0;
      let rejected = 0;
      let pending = 0;
      let partiallyPaid = 0;
      item.categoriesByStatus.forEach((catItem: any) => {
        let num = catItem.totalCount ? catItem.totalCount : 0;
        if (activeTab === TopTenClinicianKeys.Amount) num = catItem.totalAmount ? catItem.totalAmount : 0;
        switch (catItem.status) {
          case TopTenClinicianCsv.Submitted:
            pending = num;
            break;
          case TopTenClinicianCsv.Paid:
            paid = num;
            break;
          case TopTenClinicianCsv.Rejected:
            rejected = num;
            break;
          case TopTenClinicianCsv.Partial:
            partiallyPaid = num;
            break;
          default:
            break;
        }
      });
      PerProcedure = [...PerProcedure, [item.id, paid, rejected, pending, partiallyPaid]];
    });
    formatedData = [
      [
        TopTenClinicianCsv.CliniciansLabel,
        `${TopTenClinicianCsv.PaidLabel} ${activeTab}`,
        `${TopTenClinicianCsv.RejectedLabel} ${activeTab}`,
        `${TopTenClinicianCsv.PendingLabel} ${activeTab}`,
        `${TopTenClinicianCsv.PartialLabel} ${activeTab}`,
      ],
      ...PerProcedure,
    ];
  }

  return formatedData;
};
