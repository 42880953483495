import {
  PaymentStatisticsCsv,
  CumulativePaymentCsv,
  TopPerProcedureCsv,
  PaymentPerTPACsv,
  PaymentPerInsurerCsv,
} from 'modules/Dashboard/api/strings';
import {Options} from 'modules/Dashboard/api/types';
import dayjs from 'dayjs';

/* Service for Payment Statistics csv */
export const getPaymentSatDataService = (paymentData: any) => {
  let formatedData: any = [];
  let total = 0;
  if (paymentData?.data?.paymentStats) {
    total += paymentData?.data?.paymentStats.avgAEDPerMonth || 0;
    total += paymentData?.data?.paymentStats.avgAEDPerWeek || 0;
    total += paymentData?.data?.paymentStats.encounterDaysToPayment || 0;
    total += paymentData?.data?.paymentStats.submissionDaysToPayment || 0;
  }
  if (total > 0) {
    formatedData = [
      [PaymentStatisticsCsv.PaymentStatistics, ''],
      [PaymentStatisticsCsv.AvgPerWeek, parseFloat(paymentData.data.paymentStats.aveAEDPerWeek).toFixed(2)],
      [PaymentStatisticsCsv.AvgPerMonth, parseFloat(paymentData.data.paymentStats.avgAEDPerMonth).toFixed(2)],
      // Commented this code because it can be used in future.
      // [
      //   PaymentStatisticsCsv.DaysSubmiision,
      //   parseFloat(paymentData.data.paymentStats.submissionDaysToPayment).toFixed(2),
      // ],
      [PaymentStatisticsCsv.DaysEncounter, parseFloat(paymentData.data.paymentStats.encounterDaysToPayment).toFixed(2)],
    ];
  }
  return formatedData;
};

/* Service for Cumulative payments per month csv */
export const getCumulativePaymentDataService = (paymentData: any) => {
  let formatedData: any = [];
  let cumulativePaymentData: string[][] = [];
  if (paymentData && paymentData.data.cumulativePayments && paymentData.data.cumulativePayments.length > 0) {
    paymentData.data.cumulativePayments.forEach((item: any, index: number) => {
      const date = new Date(item.date);
      const getMonthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      let base = item.cumulativePaymentsAmount;
      let paid = 0;
      if (index > 0) {
        base = item.cumulativePaymentsAmount - item.paymentsAmount;
        paid = item.paymentsAmount;
      }
      cumulativePaymentData = [...cumulativePaymentData, [getMonthYear, base, paid]];
    });
    formatedData = [
      [CumulativePaymentCsv.Month, CumulativePaymentCsv.Base, CumulativePaymentCsv.Paid],
      ...cumulativePaymentData,
    ];
  }

  return formatedData;
};

/* Service for Top 10 payments per procedure code csv */
export const getTopPerProcedureDataService = (paymentData: any) => {
  let formatedData: any = [];
  let PerProcedure: string[][] = [];
  if (
    paymentData &&
    paymentData.data.topTenPaymentPerProcedureCode &&
    paymentData.data.topTenPaymentPerProcedureCode.length > 0
  ) {
    paymentData.data.topTenPaymentPerProcedureCode.forEach((item: any) => {
      PerProcedure = [...PerProcedure, [item.procedureCode, item.totalAmount]];
    });
    formatedData = [[TopPerProcedureCsv.ProcedureCode, TopPerProcedureCsv.AEDAmount], ...PerProcedure];
  }

  return formatedData;
};

/* Service for monthly payments per TPA csv */
export const getPerTPATableDataService = (paymentData: any, selectedYear: Options | null) => {
  let formattedData: string[][] = [];
  if (selectedYear && selectedYear.value && paymentData) {
    const perTPAHeadingNames: string[] = [PaymentPerTPACsv.ReceiverName, PaymentPerTPACsv.ReceiverCode];
    const tpaData: any = [];
    paymentData?.data.forEach((item: any, idx: number) => {
      const row: any = [];
      let total = 0;
      item?.buckets?.forEach((bucket: any) => {
        const folder = dayjs(bucket.date).format(`YYYY`);
        if (selectedYear.value === folder) {
          total += bucket.amount;
          if (idx + 1 === paymentData.data.length) perTPAHeadingNames.push(dayjs(bucket.date).format(`MMM 'YY`));
          row.push(bucket.amount);
        }
      });
      if (idx + 1 === paymentData?.data.length) perTPAHeadingNames.push('Total');
      row?.unshift(item.name || '-', item.id || '-');
      row?.push(total);
      tpaData.push(row);
      formattedData = [perTPAHeadingNames, ...tpaData];
    });
  }
  return formattedData;
};

/* Service for monthly payments per Insurer csv */
export const getPerInsurerTableDataService = (paymentData: any, selectedYear: Options | null) => {
  let formattedData: string[][] = [];
  if (selectedYear && selectedYear.value && paymentData) {
    const perInsurerHeadingNames: string[] = [PaymentPerInsurerCsv.ReceiverName, PaymentPerInsurerCsv.ReceiverCode];
    const insurerData: any = [];
    paymentData?.data.forEach((item: any, idx: number) => {
      const row: any = [];
      let total = 0;
      item?.buckets?.forEach((bucket: any) => {
        const folder = dayjs(bucket.date).format(`YYYY`);
        if (selectedYear.value === folder) {
          total += bucket.amount;
          if (idx + 1 === paymentData.data.length) perInsurerHeadingNames.push(dayjs(bucket.date).format(`MMM 'YY`));
          row.push(bucket.amount);
        }
      });
      if (idx + 1 === paymentData?.data.length) perInsurerHeadingNames.push('Total');
      row?.unshift(item.name || '-', item.id || '-');
      row?.push(total);
      insurerData.push(row);
      formattedData = [perInsurerHeadingNames, ...insurerData];
    });
  }
  return formattedData;
};
