import React from 'react';
import {createUseStyles} from 'react-jss';
import ReactECharts from 'echarts-for-react';
import {Colors} from 'modules/Common/constants/Colors';
import {PaymentsPerProcedureData} from 'modules/Dashboard/api/types';
import {labelFormatter} from 'modules/Dashboard/common/utils';
import {LabelTitleFormat} from 'modules/Dashboard/common/enums';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      margin: '30px auto 0',
      width: '90%',
    },
  };
});

export const PerProcedureGraph: React.FC<PaymentsPerProcedureData> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const getXLabelData = (xLabelData: string[]) => {
    const modifiedXLabelData: string[] = [];
    xLabelData.map((element: string) => {
      const wordArray = element.split(/([A-Za-z0-9/-]+ [A-Za-z0-9/-]+)/g);
      modifiedXLabelData.push(wordArray.join('\n'));
      return 0;
    });
    return modifiedXLabelData;
  };
  const options = {
    grid: {
      bottom: 60,
      left: 90,
      right: 10,
      top: 70,
    },
    legend: {
      itemHeight: 15,
      itemWidth: 15,
      left: {
        data: 'Rejections',
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: [
      {
        barWidth: data.count.length > 20 ? '60%' : 34,
        data: data.count,
        itemStyle: {barBorderRadius: [2.5, 2.5, 0, 0], color: Colors.purple},
        name: 'Procedure',
        roundCap: true,
        stack: 'Month',
        type: 'bar',
      },
    ],
    tooltip: {
      formatter: (params: any) => labelFormatter(params, LabelTitleFormat.string, false, getCurrencyToDisplay()),
      axisPointer: {
        crossStyle: {
          color: Colors.gray,
        },
        type: 'cross',
      },
      trigger: 'axis',
    },
    xAxis: [
      {
        axisLine: {
          lineStyle: {
            color: Colors.gray,
            type: 'solid',
          },
          onZero: true,
          show: true,
        },
        axisPointer: {
          type: 'shadow',
          label: {
            formatter: '{value}',
            width: 150,
            fontSize: 10,
          },
        },
        data: getXLabelData(data.xLabels),
        type: 'category',
        axisLabel: {
          interval: 0,
          formatter: '{value}',
          fontSize: 6.5,
          fontWeight: 'bolder',
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          lineStyle: {
            color: Colors.grayLight,
            type: 'dashed',
            width: 1,
          },
          show: true,
        },
        type: 'value',
      },
    ],
  };
  return (
    <div className={classes.container}>
      <ReactECharts option={options} />
    </div>
  );
};
