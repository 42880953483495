import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

export const FilterGroupTotalStats = {
  COUNT: 'Count',
  AED_AMOUNT: `${getCurrencyToDisplay()} Amount`,
};

export const FilterGroupRejectionPeriod = {
  COUNT: 'Count',
  AED_AMOUNT: `${getCurrencyToDisplay()} Amount`,
  PERCENTAGE_REJECTION: '% Rejection',
};

export enum HistogramMonthFilterGroup {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum TotalStatsKeyMap {
  COUNT = 'totalClaims',
  AMOUNT = 'claimAmount',
}

export enum DownloadCsv {
  MONTHLY = 'Month',
  YEARLY = 'Year',
}

export enum FirstSubmissionOutcomeCsv {
  FirstSubmissionOutcome = '1st Submission Outcome',
  TotalClaims = 'Total Claims',
  Accepted = 'Accepted',
  PartiallyAccepted = '(Partially) Accepted',
  Rejected = 'Rejected',
  RejectionRatioCount = 'Rejection Ratio Count',
  TotalClaimsAED = 'Total Claims AED',
  AEDAccepted = 'AED Accepted',
  PartiallyAcceptedAED = 'Partially Accepted AED',
  RejectedAED = 'Rejected AED',
  RejectionRatioAED = 'Rejection Ratio AED',
}

export enum ResubmissionBreakdownCsv {
  ResubmissionsBreakdown = 'Resubmissions Breakdown',
  NotResubmitted = 'Not Resubmitted',
  Resubmitted = 'Resubmitted',
  NotResubmittedAED = 'Not Resubmitted AED',
  ResubmittedAED = 'Resubmitted AED',
}

export enum ResubmittedClaimsFOCsv {
  ResubmittedClaimsFinalOutcome = 'Resubmitted Claims Final Outcome',
  StillPending = 'Still Pending',
  FinallyAccepted = 'Finally Accepted',
  PartiallyAccepted = 'Partially Accepted',
  StillRejected = 'Still Rejected',
  StillPendingAED = 'Still Pending AED',
  FinallyAcceptedAED = 'Finally Accepted AED',
  PartiallyAcceptedAED = 'Partially Accepted AED',
  StillRejectedAED = 'Still Rejected AED',
}

export enum RejectionCsv {
  Status = 'Status',
  Rejected = 'Rejected',
  Paid = 'Paid',
  RejectionRate = 'Rejection Rate',
  Rejections = 'Rejections',
  RejectionsAmount = 'Rejections Amount',
  DenialCode = 'Denial code',
  Rejection = 'Rejection',
}

export enum LabelTitleFormat {
  date = 'date',
  dateRange = 'date-range',
  string = 'string',
}

export enum ClaimsKeyMap {
  ClaimsTotal = 'claimAmount',
  ClaimsValue = 'totalClaims',
}
