import queryString, {StringifiableRecord} from 'query-string';
import {DASH} from 'modules/Dashboard/api';
import {restAPIRequest} from 'modules/Common/utils/restRequestsService';
import {formatDashboardRequestRange} from 'modules/Common/utils/dateRange';

export const getOverviewDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Overview.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/overview',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getAgeingClaimsDataService = async (queryData: StringifiableRecord) => {
  return Promise.all([getAgeingClaimsSummaryService(queryData), getAgeingClaimsTableDataService(queryData)]).then(
    (values) => {
      const data: DASH.Model.AgeingClaimsDataType = {
        summary: values[0].data.summary,
        tableData: values[1].data.data,
        totalCount: values[1].data.totalCount,
      };

      return {data};
    },
  );
};

export const getAgeingClaimsSummaryService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.AgeingClaims.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: queryData,
    url: '/dashboard/ageing',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getAgeingClaimsTableDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.AgeingClaimsTable.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: queryData,
    url: '/dashboard/ageing/source',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getAgeingClaimsTableAllDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.AgeingClaimsAllTable.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: queryData,
    url: '/dashboard/ageing/source/all',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getCollectionsDataService = async (queryData: StringifiableRecord, header: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Collections.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/collections',
  });

  const data = await request(requestUrl, null, 'GET', header);
  return {data};
};

export const getCollectionsSourceDataService = async (queryData: StringifiableRecord, header: StringifiableRecord) => {
  const request = restAPIRequest<DASH.CollectionsSource.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/collections/source',
  });
  const data = await request(requestUrl, null, 'GET', header);
  return {data};
};

export const getCollectionsSourceAllTabelData = async (queryData: StringifiableRecord, header: StringifiableRecord) => {
  const request = restAPIRequest<DASH.CollectionsSource.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/collections/source/all',
  });
  const data = await request(requestUrl, null, 'GET', header);
  return {data};
};

export const getPayersDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Payers.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: queryData,
    url: '/payers',
  });
  const data = await request(requestUrl);
  return {data};
};

export const getRejectionsDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Rejection.Response>();

  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/rejection',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getRejectionsSourceDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.RejectionSource.Response>();

  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/rejections/source',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getPaymentsDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Rejection.Response>();

  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/payments',
  });

  const {data} = await request(requestUrl);
  return {data};
};

export const getPaymentsSourceDataService = async (queryData: StringifiableRecord, header: StringifiableRecord) => {
  const request = restAPIRequest<DASH.RejectionSource.Response>();

  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/payments/source',
  });

  const {data} = await request(requestUrl, null, 'GET', header);
  return {data};
};

export const getPaymentsSourceAllDataService = async (queryData: StringifiableRecord, header: StringifiableRecord) => {
  const request = restAPIRequest<DASH.RejectionSource.Response>();

  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/payments/source/all',
  });

  const {data} = await request(requestUrl, null, 'GET', header);
  return {data};
};

export const getTopTenCliniciansDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Collections.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/clinician',
  });

  const data = await request(requestUrl);
  return data;
};

export const getCliniciansClaimsDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Collections.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/clinician/source',
  });

  const data = await request(requestUrl);
  return data;
};

export const getCliniciansClaimsAllTableDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.CliniciansAllTableData.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: formatDashboardRequestRange(queryData),
    url: '/dashboard/clinician/source/all',
  });

  const data = await request(requestUrl);
  return data;
};
