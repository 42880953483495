import {FC, useState, useEffect} from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import {NoData} from 'modules/Common/components/NoData';
import {Card} from 'modules/Common/components/Card';
import {Options, PaymentsPerMonthData, PaymentsPerProcedureData} from 'modules/Dashboard/api/types';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {PerProcedureGraph} from 'modules/Dashboard/containers/Payments/components/PerProcedureGraph';
import {PerMonthGraph} from 'modules/Dashboard/containers/Payments/components/PerMonthGraph';
import {PerInsurerTable} from 'modules/Dashboard/containers/Payments/components/PerInsurerTable';
import {PerTPATable} from 'modules/Dashboard/containers/Payments/components/PerTPATable';
import {
  ClaimsBreakdownDateRangeFormat,
  PayersClassifications,
  PayerType,
  DashForbiddenMessage,
} from 'modules/Dashboard/api/strings';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {
  getPaymentsDataService,
  getPaymentsSourceDataService,
  getPayersDataService,
} from 'modules/Dashboard/api/requests';
import * as Toast from 'modules/Common/utils/toast';
import {DASH} from 'modules/Dashboard/api';

import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {
  getPaymentSatDataService,
  getCumulativePaymentDataService,
  getTopPerProcedureDataService,
} from 'modules/Dashboard/containers/Payments/service';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import {events, Mixpanel} from 'services/mixpanel';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import StaticOverview from '../StaticHTML/Payments';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const Payments: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  const [perTPAPaging, setPerTPAPaging] = useState({offset: 0, limit: 10});
  const [perInsurerPaging, setPerInsurerPaging] = useState({offset: 0, limit: 10});

  const [isLoading, setIsLoading] = useState(false);
  const [isPerInsurerLoading, setIsPerInsurerLoading] = useState(false);
  const [isPerTPALoading, setIsPerTPALoading] = useState<boolean>(false);
  const [paymentsData, setPaymentsData] = useState<DASH.Payments.Response | null>();
  const [paymentsPerTPAData, setPaymentsPerTPAData] = useState<DASH.PaymentsSource.Response | null>();
  const [paymentsPerInsurerData, setPaymentsPerInsurerData] = useState<DASH.PaymentsSource.Response | null>();
  const [perInsurerSelectedPayer, setPerInsurerSelectedPayer] = useState<Options[]>([]);
  const [perTPASelectedPayer, setPerTPASelectedPayer] = useState<Options[]>([]);
  const [payersData, setPayersData] = useState<DASH.Payers.Response | null>();
  const [insurerOptions, setInsurerOptions] = useState<Options[]>([]);
  const [TPAOptions, setTPAOptions] = useState<Options[]>([]);

  const [perProcedureCsvData, setPerProcedureCsvData] = useState<string[][]>([]);
  const [paymentStatisticsCsvData, setPaymentStatisticsCsvData] = useState<string[][]>([]);
  const [cumulativePaymentCsvData, setCumulativePaymentCsvData] = useState<string[][]>([]);

  useEffect(() => {
    dispatch(
      setRequiredFilters({
        dateRangeFilterMonth: true,
        branchesFilter: true,
        staticDateType: 'Last Remittance Advice Date',
      }),
    );
    Mixpanel.track(events.pageViews.dashboardPayments);
  }, []);

  const fetchPaymentsData = async (queryData: any) => {
    if (startDate && endDate) {
      setIsLoading(true);
      try {
        const data = await getPaymentsDataService(queryData);

        if (data) {
          setIsLoading(false);
          setPaymentsData(data);
          setPaymentStatisticsCsvData(getPaymentSatDataService(data));
          setCumulativePaymentCsvData(getCumulativePaymentDataService(data));
          setPerProcedureCsvData(getTopPerProcedureDataService(data));
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error === DashForbiddenMessage) {
          setPaymentsData(null);
          setPaymentStatisticsCsvData([]);
          setCumulativePaymentCsvData([]);
          setPerProcedureCsvData([]);
        } else Toast.error(error);
      }
    }
  };

  const fetchPaymentsSourceData = async (insurerType: string, selectedPayer: Options[], queryData: any) => {
    if (startDate && endDate && payersData?.data && payersData?.data?.length > 0) {
      let paging = perTPAPaging;
      if (insurerType === PayerType.TPA) {
        setIsPerTPALoading(true);
        paging = perTPAPaging;
      } else if (insurerType === PayerType.Insurer) {
        setIsPerInsurerLoading(true);
        paging = perInsurerPaging;
      }

      const perSourceQueryData: DASH.PaymentsSource.Request = {
        startDate: queryData.startDate,
        endDate: queryData.endDate,
        branchCodes: queryData.branchCodes,
        insurerType,
        offset: paging.offset,
        limit: paging.limit,
      };

      const perMonthQueryHeader = {insurercodes: selectedPayer.map((item: Options) => item.value).join(',')};

      try {
        const data = await getPaymentsSourceDataService(perSourceQueryData, perMonthQueryHeader);
        setIsPerInsurerLoading(false);
        setIsPerTPALoading(false);
        if (data && insurerType === PayerType.TPA) {
          setPaymentsPerTPAData(data);
        } else if (data && insurerType === PayerType.Insurer) {
          setPaymentsPerInsurerData(data);
        }
      } catch (error: any) {
        setIsPerInsurerLoading(false);
        setIsPerTPALoading(false);
        if (error === DashForbiddenMessage) {
          if (insurerType === PayerType.TPA) {
            setPaymentsPerTPAData(null);
          } else if (insurerType === PayerType.Insurer) {
            setPaymentsPerInsurerData(null);
          }
        } else Toast.error(error);
      }
    }
  };

  useEffect(() => {
    if (selectedDateType && selectedBranches.length > 0 && startDate && endDate) {
      const query = {
        dateType: selectedDateType.value,
        startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
      };
      fetchPaymentsData(query);
    }
  }, [selectedDateType, selectedBranches, startDate, endDate]);

  useEffect(() => {
    if (selectedDateType && selectedBranches.length > 0 && startDate && endDate) {
      const query = {
        dateType: selectedDateType.value,
        startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
      };
      fetchPaymentsSourceData(PayerType.Insurer, perInsurerSelectedPayer, query);
    }
  }, [selectedBranches, startDate, endDate, perInsurerSelectedPayer, perInsurerPaging]);

  useEffect(() => {
    if (selectedDateType && selectedBranches.length > 0 && startDate && endDate) {
      const query = {
        dateType: selectedDateType.value,
        startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
      };
      fetchPaymentsSourceData(PayerType.TPA, perTPASelectedPayer, query);
    }
  }, [selectedBranches, startDate, endDate, perTPASelectedPayer, perTPAPaging]);

  const insurerOptionsArr: Options[] = [];
  const FilteredInsurerOptionsArr: string[] = [];
  const TPAOptionsArr: Options[] = [];
  const FilteredTPAOptionsArr: string[] = [];
  useEffect(() => {
    (async () => {
      if (selectedBranches && selectedBranches.length > 0 && startDate && endDate) {
        try {
          const {data} = await getPayersDataService({limit: 300});
          if (data) {
            setPayersData({...data});
            data?.data?.forEach((item: any) => {
              if (item.classification === PayersClassifications.Insurance) {
                const newOpt: Options = {
                  label: item.longName,
                  value: item.payerCode,
                };
                if (FilteredInsurerOptionsArr.indexOf(item.payerCode) < 0) {
                  FilteredInsurerOptionsArr.push(item.payerCode);
                  insurerOptionsArr.push(newOpt);
                }
              } else if (item.classification === PayersClassifications.TPA) {
                const newOpt: Options = {
                  label: item.longName,
                  value: item.payerCode,
                };
                if (FilteredTPAOptionsArr.indexOf(item.payerCode) < 0) {
                  FilteredTPAOptionsArr.push(item.payerCode);
                  TPAOptionsArr.push(newOpt);
                }
              }
            });
            setTPAOptions([]);
            setInsurerOptions([]);
            setTPAOptions(TPAOptionsArr);
            setInsurerOptions(insurerOptionsArr);
            setPerTPASelectedPayer(TPAOptionsArr);
            setPerInsurerSelectedPayer(insurerOptionsArr);
          }
        } catch (error: any) {
          if (error === DashForbiddenMessage) {
            setTPAOptions([]);
            setInsurerOptions([]);
            setPerTPASelectedPayer([]);
            setPerInsurerSelectedPayer([]);
          } else Toast.error(error);
        }
      }
    })();
  }, [selectedBranches]);

  const statsData = paymentsData?.data?.paymentStats;
  const cumulativePayments = paymentsData?.data?.cumulativePayments;
  const topTenPayments = paymentsData?.data?.topTenPaymentPerProcedureCode;
  const perTPAPayments = paymentsPerTPAData?.data;

  const perInsurerPayments = paymentsPerInsurerData?.data;

  const perMonthData: PaymentsPerMonthData = {
    data: {
      paid: [],
      base: [],
      xLabels: [],
    },
  };

  cumulativePayments?.forEach((item, i) => {
    perMonthData.data.xLabels.push(item.date);
    let base = item.cumulativePaymentsAmount;
    let paid = item.paymentsAmount;
    if (i > 0) {
      base = item.cumulativePaymentsAmount - item.paymentsAmount;
      paid = item.paymentsAmount;
    }
    perMonthData.data.paid.push(klaimDecimal(paid));
    perMonthData.data.base.push(klaimDecimal(base));
  });

  const PerProceduredData: PaymentsPerProcedureData = {
    data: {
      count: [],
      xLabels: [],
    },
  };

  topTenPayments?.forEach((item) => {
    PerProceduredData.data.xLabels.push(item.procedureName || item.procedureCode);
    PerProceduredData.data.count.push(klaimDecimal(item.totalAmount));
  });

  /**
   * CARD TOOLS
   */
  const perProcedureTools =
    PerProceduredData.data.count.length === 0 ? (
      <div />
    ) : (
      <div className="toolsWrapper">
        <DownloadDashBtn data={perProcedureCsvData} position filename={'Payments-Top10PaymentsPerProcedureCode'} />
      </div>
    );

  const PerMonthTools =
    perMonthData.data.base.length === 0 && perMonthData.data.paid.length === 0 ? (
      <div />
    ) : (
      <div className="toolsWrapper">
        <DownloadDashBtn data={cumulativePaymentCsvData} position filename={'Payments-CumulativePaymentsPerMonth'} />
      </div>
    );

  // line 382 download button
  // const firstSubmissionTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn data={paymentStatisticsCsvData} position filename={'Payments-PaymentStatistics'} />
  //   </div>
  // );

  let renderPerProcedureGraph: any = <NoData />;
  if (PerProceduredData.data.count.length > 0)
    renderPerProcedureGraph = <PerProcedureGraph data={PerProceduredData.data} />;

  let renderPerMonthGraph: any = <NoData />;
  if (perMonthData.data.paid.length > 0 && perMonthData.data.base.length > 0)
    renderPerMonthGraph = <PerMonthGraph data={perMonthData.data} />;

  let renderStatistics: any = <NoData />;
  let total = 0;
  if (statsData) {
    total += statsData?.avgAEDPerMonth || 0;
    total += statsData?.aveAEDPerWeek || 0;
    total += statsData?.encounterDaysToPayment || 0;
    total += statsData?.submissionDaysToPayment || 0;
  }
  if (total > 0)
    renderStatistics = (
      <Row className={classes.boxesCont}>
        <Col md={12} lg={6} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={statsData?.aveAEDPerWeek || 0}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
              />
            }
            label={`${getCurrencyToDisplay()}  Avg. per week`}
          />
        </Col>
        <Col md={12} lg={6} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={statsData?.avgAEDPerMonth || 0}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
              />
            }
            label={`${getCurrencyToDisplay()}  Avg. per month`}
          />
        </Col>
        {/* NOTE: DO NOT REMOVE (HIDDEN TEMPORARILY) */}
        {/* <Col md={12} lg={6} xl={3}>
          <NumbersContainer
            number={
              <NumberFormat
                value={statsData?.submissionDaysToPayment || 0}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
              />
            }
            label={`Submission to payment`}
          />
        </Col> */}
        <Col md={12} lg={6} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={statsData?.encounterDaysToPayment || 0}
                displayType={'text'}
                thousandSeparator
                decimalScale={0}
              />
            }
            label={'Encounter to Payment (Days)'}
          />
        </Col>
      </Row>
    );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticOverview />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <Card title="Payment Statistics" height={'auto'} showLoading={isLoading}>
                  {renderStatistics}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <Card
                  title={`Cumulative Payments per Month`}
                  minHeight={'90%'}
                  tools={PerMonthTools}
                  showLoading={isLoading}
                >
                  {renderPerMonthGraph}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <Card
                  title={`Top 10 Payments Per Procedure Code (${getCurrencyToDisplay()} Amount)`}
                  minHeight={'90%'}
                  tools={perProcedureTools}
                  showLoading={isLoading}
                >
                  {renderPerProcedureGraph}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <PerTPATable
                  perInsurerPayments={perTPAPayments}
                  totalCount={paymentsPerTPAData?.data?.totalCount || 0}
                  // totalCount={perTPAPayments.totalCount || 0}
                  perInsurerSelectedPayer={perTPASelectedPayer}
                  setPerInsurerSelectedPayer={setPerTPASelectedPayer}
                  isPerInsurerLoading={isPerTPALoading}
                  perInsurerPaging={perTPAPaging}
                  setPerInsurerPaging={setPerTPAPaging}
                  insurerOptions={TPAOptions}
                  dateRange={{startDate, endDate}}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <PerInsurerTable
                  perInsurerPayments={perInsurerPayments}
                  totalCount={paymentsPerInsurerData?.data?.totalCount || 0}
                  perInsurerSelectedPayer={perInsurerSelectedPayer}
                  setPerInsurerSelectedPayer={setPerInsurerSelectedPayer}
                  isPerInsurerLoading={isPerInsurerLoading}
                  perInsurerPaging={perInsurerPaging}
                  setPerInsurerPaging={setPerInsurerPaging}
                  insurerOptions={insurerOptions}
                  dateRange={{startDate, endDate}}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
