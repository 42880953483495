import {FC, useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';

import {getAgeingClaimsDataService, getAgeingClaimsTableAllDataService} from 'modules/Dashboard/api/requests';
import * as Toast from 'modules/Common/utils/toast';
import {DASH} from 'modules/Dashboard/api';
import {SummaryType} from 'modules/Dashboard/api/types';
import dayjs from 'dayjs';
import {DashForbiddenMessage, ClaimsBreakdownDateRangeFormat, DateTypesToUse} from 'modules/Dashboard/api/strings';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import AgeingClaimsGraph from 'modules/Dashboard/containers/AgeingClaims/components/Graph';
import AgeingClaimsTable from 'modules/Dashboard/containers/AgeingClaims/components/Table';
import AgeingClaimsSummary from 'modules/Dashboard/containers/AgeingClaims/components/Summary';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {events, Mixpanel} from 'services/mixpanel';
import {useAppDispatch, useAppSelector} from 'modules/App/store';

import {
  getSummaryDataService,
  getPendingClaimsDataService,
  getAgeingBySourceDataService,
} from 'modules/Dashboard/containers/AgeingClaims/service';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import StaticAgeing from '../StaticHTML/Ageing';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    ...commonStyles,
  };
});

export const AgeingClaims: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<SummaryType | null>();
  const [histogramData, setHistogramData] = useState<DASH.Model.AgeingClaimsHistogramDataType[]>([]);
  const [claimsTableData, setClaimsTableData] = useState<DASH.Model.AgeingClaimTableDataType[]>([]);

  const [summaryCsvData, setSummaryCsvData] = useState<string[][]>([[]]);
  const [pendingClaimsCsvData, setPendingClaimsCsvData] = useState<string[][]>([[]]);
  const [ageingBySourceCsvData, setAgeingBySourceCsvData] = useState<string[][]>([[]]);
  const [claimsTotalCount, setClaimsTotalCount] = useState<number>(0);
  const [tablePaging, setTablePaging] = useState({offset: 0, limit: 10});

  // let claimsTotalCount = 0;

  const fetchData = async (queryData: any) => {
    setIsLoading(true);
    try {
      const claimsResponse = await getAgeingClaimsDataService(queryData);
      if (claimsResponse) {
        const {summary, tableData, totalCount} = claimsResponse.data;
        const histograms = [...summary.histogram.buckets];
        setSummaryData(summary);
        setSummaryCsvData(getSummaryDataService(summary));
        setHistogramData(histograms);
        setPendingClaimsCsvData(getPendingClaimsDataService(histograms));
        setClaimsTableData(tableData);
        setClaimsTotalCount(totalCount || 0);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error === DashForbiddenMessage) {
        setSummaryData(null);
        setHistogramData([]);
        setClaimsTableData([]);
        setSummaryCsvData([]);
        setPendingClaimsCsvData([]);
      }
    }
  };

  /* For csv download table data */
  const getCsvTableData = async () => {
    try {
      const queryData = {
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
      };
      const claimsResponse = await getAgeingClaimsTableAllDataService(queryData);
      if (claimsResponse) {
        const {data} = claimsResponse.data;
        return {formatedCsvData: getAgeingBySourceDataService(data)};
      }
    } catch (error) {
      return {formatedCsvData: []};
    }
  };

  useEffect(() => {
    dispatch(
      setRequiredFilters({
        dateTypeFilter: true,
        dateRangeFilterMonth: false,
        branchesFilter: true,
        dateTypesToUse: DateTypesToUse.Ageing,
      }),
    );
    Mixpanel.track(events.pageViews.dashboardAgeing);
  }, []);

  useEffect(() => {
    if (selectedDateType && startDate && endDate)
      fetchData({
        dateType: selectedDateType.value,
        startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
        offset: tablePaging.offset,
        limit: tablePaging.limit,
      });
  }, [selectedBranches, tablePaging, selectedDateType, startDate, endDate]);

  // line 146 download button
  // const summaryTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn data={summaryCsvData} position={false} filename={'Ageing-Summary'} />
  //   </div>
  // );

  const graphTools =
    pendingClaimsCsvData.length === 0 ? (
      <div> </div>
    ) : (
      <div className="toolsWrapper">
        <DownloadDashBtn data={pendingClaimsCsvData} position filename={'Ageing-PendingClaimsAgeing'} />
      </div>
    );

  const tableTools = (
    <div className="toolsWrapper">
      <DownloadDashBtn
        data={ageingBySourceCsvData}
        position
        filename={'Ageing-AgeingBySource'}
        getTableData={getCsvTableData}
      />
    </div>
  );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticAgeing />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col style={{position: 'static'}} sm={5}>
                <AgeingClaimsSummary summary={summaryData} showLoading={isLoading} />
              </Col>
              <Col style={{position: 'static'}} sm={7}>
                <AgeingClaimsGraph histogram={histogramData} tools={graphTools} showLoading={isLoading} />
              </Col>
            </Row>
            <Row>
              <Col>
                <AgeingClaimsTable
                  totalCount={claimsTotalCount}
                  tableData={claimsTableData}
                  tools={tableTools}
                  showLoading={isLoading}
                  tablePaging={tablePaging}
                  setTablePaging={setTablePaging}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
