import dayjs from 'dayjs';
import {numberWithCommas} from 'modules/Common/utils/formatter';
import {LabelTitleFormat} from 'modules/Dashboard/common/enums';

export const labelFormatter = (
  params: any,
  titleFormat: LabelTitleFormat,
  showPercent = false,
  magnitude = '',
  showTotal = false,
) => {
  let total = 0;
  params.forEach((item: any) => {
    total += item.value;
  });
  let title = '--';

  let body = params.map((item: any, i: number) => {
    const percentage = Math.round((item.value / total) * 100);
    if (i === 0) {
      if (titleFormat === LabelTitleFormat.string) title = item.axisValueLabel;
      if (titleFormat === LabelTitleFormat.date) title = dayjs(item.axisValueLabel).format('MMMM YYYY');
      if (titleFormat === LabelTitleFormat.dateRange) {
        const from = item.axisValueLabel.split(' - ')[0];
        const to = item.axisValueLabel.split(' - ')[1];
        title = `${dayjs(from).format('MMM D, YYYY')} - ${dayjs(to).format('MMM D, YYYY')}`;
      }
    }
    return `<div class="chart-tooltip-item"> 
    <div class="left">
      <div class="box" style="background: ${item.color}"></div>
      <div class="name">${item.seriesName}</div>
    </div>
    <div class="right">
    <div>${numberWithCommas(item.value)}</div>
    ${magnitude ? `<div>${magnitude}</div>` : ''}
    ${showPercent ? `<div>(${percentage}%)</div>` : ''}
    </div>
    </div>`;
  });

  body = body.toString().replace(/>,/g, '>');
  const content = `
    <div class="chart-custom-tooltip">
      <div class="chart-tooltip-heading">${title}</div>
      <div class="chart-tooltip-body">${body}</div>
      ${
        showTotal
          ? `
      <div class="chart-tooltip-total">
        <div class="chart-tooltip-item"> 
          <div class="left">
          Total 
          </div>
          <div class="right">
          ${numberWithCommas(total.toFixed(2))} ${magnitude}
          </div>
        </div>
      </div>
      `
          : ''
      }
    </div>
  `;
  return content;
};
