import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {roundOff} from 'modules/Common/utils/formatter';
import {AgeingCsvTableNames, AgeingCsv} from 'modules/Dashboard/api/strings';

/* Service for Summary csv */
export const getSummaryDataService = (ageingData: any) => {
  let formattedData: any = [];
  let total = 0;
  if (ageingData) {
    total += ageingData.totalNumberPendingClaims || 0;
    total += ageingData.totalAmountPendingClaims || 0;
    total += ageingData.totalNumberPendingClaimsMoreThanMonth || 0;
    total += ageingData.totalAmountPendingClaimsMoreThanMonth || 0;
  }
  if (total > 0) {
    formattedData = [
      [AgeingCsv.Summary, ''],
      [AgeingCsv.PendingClaim, roundOff(ageingData ? ageingData.totalNumberPendingClaims : 0)],
      [AgeingCsv.FourtyFiveDays, roundOff(ageingData ? ageingData.totalAmountPendingClaims : 0)],
      [AgeingCsv.PendingClaimCurrency, roundOff(ageingData ? ageingData.totalNumberPendingClaimsMoreThanMonth : 0)],
      [AgeingCsv.FourtyFiveDaysCurrency, roundOff(ageingData ? ageingData.totalAmountPendingClaimsMoreThanMonth : 0)],
    ];
  }

  return formattedData;
};

/* Service for Pending claims ageing csv */
export const getPendingClaimsDataService = (ageingData: any) => {
  let formattedData: any = [];
  let pendingClaimsData: string[][] = [];
  let total = 0;
  if (ageingData) {
    ageingData.forEach((item: any) => {
      total += item.rangeSum.value || 0;
    });
  }
  if (total > 0) {
    ageingData.forEach((item: any) => {
      pendingClaimsData = [...pendingClaimsData, [item.key, roundOff(item.rangeSum.value)]];
    });
    formattedData = [[AgeingCsv.DayRange, AgeingCsv.AgeingAmount], ...pendingClaimsData];
  }

  return formattedData;
};

/* Service for ageing by source csv */
export const getAgeingBySourceDataService = (ageingData: any) => {
  let ageingBySourceData: any[][] = [];

  if (ageingData && ageingData.length > 0) {
    ageingData.forEach((item: any) => {
      let rangeValueData: number[] = [];
      AgeingCsvTableNames.forEach((name) => {
        let status = true;
        item.range.buckets.forEach((bucketItem: any) => {
          if (bucketItem.key === name && status) {
            rangeValueData.push(roundOff(bucketItem.rangeSum.value));
            status = false;
          }
        });
      });

      const total = rangeValueData.reduce((a, b) => a + b);
      rangeValueData = [item.key, ...rangeValueData, total];
      ageingBySourceData = [...ageingBySourceData, rangeValueData];
    });

    ageingBySourceData = ageingBySourceData.sort((itemA, itemB) => {
      return itemB[itemB.length - 1] - itemA[itemA.length - 1];
    });

    ageingBySourceData = [AgeingCsvTableNames, ...ageingBySourceData];
  }

  return ageingBySourceData;
};
