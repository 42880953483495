import Main from 'modules/Main';

import Auth from 'modules/Auth/v3';
import Login from 'modules/Auth/v3/features/Login';
import Register from 'modules/Auth/v3/features/Register';
import EmailPasswordReset from 'modules/Auth/v3/features/Forgot/EmailPasswordReset';
import RegistrationComplete from 'modules/Auth/v3/features/Register/CompletedRegistration';
import EmailInstructions from 'modules/Auth/v3/features/Forgot/EmailPasswordInstructions';
import UpdatePassword from 'modules/Auth/v3/features/Forgot/UpdatePassword';
import Invite from 'modules/Auth/v3/features/Invite';
import VerifyEmail from 'modules/Auth/v3/features/Verify';

import Eligibility from 'modules/Eligibility';
import EligibilityForm from 'modules/Eligibility/features/EligibilityForm';
import EgligibilityHistory from 'modules/Eligibility/features/EligibilityHistory';
import EligibilityHistoryDetails from 'modules/Eligibility/features/EligibilityHistoryDetails';

import ClaimsList from 'modules/Claims/containers/ClaimsList';
import ClaimForm from 'modules/Claims/containers/ClaimForm';
import ClaimsDraftList from 'modules/Claims/containers/ClaimsDraftList';
import ClaimDetails from 'modules/Claims/containers/ClaimDetails';

import Settings from 'modules/Settings';

import AccountSettings from 'modules/Settings/features/AccountSettings';
import EditInfo from 'modules/Settings/features/AccountSettings/EditInfo';
import Info from 'modules/Settings/features/AccountSettings/Info';

import BranchManagement from 'modules/Settings/features/BranchManagement';
import Branch from 'modules/Settings/features/BranchManagement/Branches';

import UserManagement from 'modules/Settings/features/UserManagement';
import AddStaffMember from 'modules/Settings/features/UserManagement/AddUser';
import EditUser from 'modules/Settings/features/UserManagement/EditUser';
import Users from 'modules/Settings/features/UserManagement/Users';

import BranchVerify from 'modules/Settings/features/BranchManagement/BranchVerify';
import BranchAdd from 'modules/Settings/features/BranchManagement/BranchAdd';
import BranchEdit from 'modules/Settings/features/BranchManagement/BranchEdit';

import InsuranceCompanies from 'modules/Settings/features/InsuranceCompanies';
import InsuranceCompaniesList from 'modules/Settings/features/InsuranceCompanies/InsuranceCompaniesList';
import InsuranceCompaniesAdd from 'modules/Settings/features/InsuranceCompanies/insuranceCompaniesAdd';
import InsuranceCompaniesEdit from 'modules/Settings/features/InsuranceCompanies/insuranceCompaniesEdit';

import Practitioners from 'modules/Settings/features/Practitioners';
import PractitionersList from 'modules/Settings/features/Practitioners/PractitionersList';
import PractitionersAdd from 'modules/Settings/features/Practitioners/PractitionersAdd';
import PractitionersEdit from 'modules/Settings/features/Practitioners/PractitionersEdit';

import Insurance from 'modules/Settings/features/Insurance';
import InsuranceList from 'modules/Settings/features/Insurance/InsuranceList';
import InsuranceEdit from 'modules/Settings/features/Insurance/InsuranceEdit';
import InsuranceAdd from 'modules/Settings/features/Insurance/InsuranceAdd';

import Contract from 'modules/Settings/features/Contracts';
import ContractList from 'modules/Settings/features/Contracts/ContractList';
import ContractAdd from 'modules/Settings/features/Contracts/ContractAdd';

import AccessManagementContainer, {AccessManagement} from 'modules/Settings/features/AccessManagement';
import AddRoles from 'modules/Settings/features/AccessManagement/RoleAdd';
import EditRoles from 'modules/Settings/features/AccessManagement/RoleEdit';

import PreAuth from 'modules/PreAuth/containers/PreAuth';
import PreAuthForm from 'modules/PreAuth/containers/PreAuthForm';
import PreAuthResponse from 'modules/PreAuth/containers/PreAuthReponse';
import PreAuthHistory from 'modules/Claims/containers/PreAuthHistory';

import {IRoute} from 'interfaces/route.interface';

import {Collection} from 'modules/Dashboard/containers/Collection';
// import Reports from 'modules/Reports/containers/Reports';
import ReportsContainer from 'components/Reports/ReportsContainer';
import {AgeingClaims} from 'modules/Dashboard/containers/AgeingClaims';
import {Overview} from 'modules/Dashboard/containers/Overview';
import {Rejections} from 'modules/Dashboard/containers/Rejections';
import {Clinicians} from 'modules/Dashboard/containers/Clinicians';
import Dashboard from 'modules/Dashboard/containers/Dashboard';
import {Payments as UAEPayments} from 'modules/Dashboard/containers/Payments';
import Payments from '../Payments';
import PaymentsDetails from '../Payments/features/PaymentDetails';
import PaymentsList from '../Payments/features/PaymentsList';
import EmailSignature from '../EmailSignature';
import Pharmacy from '../Pharmacy';
import ErxList from '../Pharmacy/features/ErxList';
import ErxDescription from '../Pharmacy/features/ErxDescription';
import ErxPrescriptionReference from '../Pharmacy/features/ErxReferencePrescription';
import ErxDispense from '../Pharmacy/features/ErxDispense';
import ErxViewDispense from '../Pharmacy/features/ErxViewDispense';
import Claims from '../Claims/containers/Claims';
import NotFound from './NotFound';
import NotAuthorized from './NotAuthorized';
import {permissions} from '../Common/constants/Permissions';
import PriorRequest from '../Pharmacy/features/PriorRequest';
import PreAuthFormV2 from '../PreAuthV2/features/PreAuthFormV2';
import PreAuthHistoryV2 from '../PreAuthV2/features/PreAuthHistoryV4';
import PreAuthHistoryDetails from '../PreAuthV2/features/PreAuthHistoryDetailsV4';
import PreAuthV2 from '../PreAuthV2';
import Patients from '../Patients';
import PatientsList from '../Patients/PatientsList';
import PatientsAdd from '../Patients/PatientsAdd';
import PatientsEdit from '../Patients/PatientsEdit';
import PatientView from '../Patients/PatientView';
import MidTable from '../MidTable';
import MidTableList from '../MidTable/features/MidTableList';
import MidTableForm from '../MidTable/features/MidTableForm';
import MidTableDrafts from '../MidTable/features/MidTableDrafts';
import ContractEdit from '../Settings/features/Contracts/ContractEdit';

const ROUTES: IRoute[] = [
  {
    path: '/auth',
    routeKey: 'AUTH',
    routeType: 'public',
    component: Auth,
    routes: [
      {
        path: '/auth/verify-email/:hash',
        routeKey: 'VERIFY_EMAIL',
        component: VerifyEmail,
      },
      {
        path: '/auth/invite/:hash',
        routeKey: 'EMAIL_INVITE',
        component: Invite,
      },
      {
        path: '/auth/password-update/change-password/:hash',
        routeKey: 'PASSWORD_UPDATE',
        component: UpdatePassword,
      },
      {
        path: '/auth/email-instructions',
        routeKey: 'EMAIL_INSTRUCTIONS',
        component: EmailInstructions,
      },
      {
        path: '/auth/update-password',
        routeKey: 'UPDATE_PASSWORD',
        component: EmailPasswordReset,
      },
      {
        path: '/auth/registration-complete',
        routeKey: 'REGISTRATION_COMPLETE',
        component: RegistrationComplete,
      },
      {
        path: '/auth/create-account',
        routeKey: 'CREATE_ACCOUNT',
        component: Register,
      },
      {
        path: '/auth/sign-in',
        routeKey: 'SIGN_IN',
        component: Login,
      },
      {
        path: '/auth',
        routeKey: 'SIGN_IN',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    routeKey: 'MAIN',
    component: Main,
    routeType: 'private',
    routes: [
      {
        component: Claims,
        routeKey: 'CLAIMS',
        path: '/claims',
        routeType: 'private',
        countries: process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES
          ? [...process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES.split(','), 'KSA']
          : [],
        routes: [
          {
            component: PreAuthHistory,
            routeKey: 'PRE_AUTH_HISTORY',
            path: '/claims/pre-auth-history',
            routeType: 'private',
          },
          {
            component: ClaimForm,
            routeKey: 'CLAIM_FORM',
            path: '/claims/form/:id',
          },
          {
            component: ClaimForm,
            routeKey: 'CLAIM_FORM',
            path: '/claims/form',
            routeType: 'private',
          },
          {
            component: ClaimsDraftList,
            routeKey: 'CLAIM_DRAFT_LIST',
            path: '/claims/draft',
            routeType: 'private',
          },
          {
            component: ClaimDetails,
            routeKey: 'CLAIM_DETAILS',
            path: '/claims/details/:id',
          },
          {
            component: ClaimDetails,
            routeKey: 'CLAIM_DETAILS',
            path: '/claims/details',
            routeType: 'private',
          },
          {
            component: ClaimsList,
            routeKey: 'CLAIMS_LIST',
            path: '/claims',
            requiredPermission: permissions.list_claims.identifier,
            countries: process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES
              ? [...process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES.split(','), 'KSA']
              : [],
          },
        ],
      },
      {
        component: Payments,
        routeKey: 'PAYMENTS',
        path: '/payments',
        routeType: 'private',
        requiredPermission: permissions.list_payments.identifier,
        countries: process.env.REACT_APP_PAYMENTS_AVAILABLE_COUNTRIES
          ? [...process.env.REACT_APP_PAYMENTS_AVAILABLE_COUNTRIES.split(','), 'KSA']
          : [],
        routes: [
          {
            component: PaymentsDetails,
            routeKey: 'PAYMENTS_DETAILS',
            path: '/payments/details',
            routeType: 'private',
          },
          {
            exact: true,
            component: PaymentsList,
            routeKey: 'PAYMENTS_LIST',
            path: '/payments',
          },
        ],
      },
      {
        component: MidTable,
        routeKey: 'MIDTABLE',
        path: '/midtable',
        routeType: 'private',
        requiredPermission: permissions.list_payments.identifier,
        countries: process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES
          ? [...process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES.split(','), 'KSA']
          : [],
        routes: [
          {
            exact: true,
            component: MidTableList,
            routeKey: 'MIDTABLE_LIST',
            path: '/midtable',
          },
          {
            exact: true,
            component: MidTableDrafts,
            routeKey: 'DRAFTS',
            path: '/midtable/errors',
          },
          {
            component: MidTableForm,
            routeKey: 'MIDTABLE_FORM',
            path: '/midtable/form/:id/:providerId',
          },
        ],
      },
      {
        component: Dashboard,
        routeKey: 'DASHBOARD',
        path: '/dashboard',
        routeType: 'private',
        countries: process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES
          ? process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES.split(',')
          : [],
        routes: [
          {
            component: Overview,
            routeKey: 'DASHBOARD_OVERVIEW',
            path: '/dashboard/overview',
            requiredPermission: permissions.view_overview_dashboard.identifier,
          },
          {
            component: Collection,
            routeKey: 'DASHBOARD_COLLECTION',
            path: '/dashboard/collection',
            routeType: 'private',
            requiredPermission: permissions.view_collections_dashboard.identifier,
          },
          {
            routeKey: 'DASHBOARD_REJECTIONS',
            component: Rejections,
            path: '/dashboard/rejections',
            routeType: 'private',
            requiredPermission: permissions.view_collections_dashboard.identifier,
          },
          {
            routeKey: 'DASHBOARD_PAYMENTS',
            component: UAEPayments,
            path: '/dashboard/payments',
            requiredPermission: permissions.view_payments_dashboard.identifier,
          },
          {
            routeKey: 'DASHBOARD_CLINICIANS',
            component: Clinicians,
            path: '/dashboard/clinician',
            requiredPermission: permissions.view_clinicians_dashboard.identifier,
          },
          {
            routeKey: 'AGEING_CLAIMS',
            component: AgeingClaims,
            path: '/dashboard/ageing-claims',
            routeType: 'private',
            requiredPermission: permissions.view_aging_dashboard.identifier,
          },
          {
            component: Overview,
            routeKey: 'DASHBOARD_OVERVIEW_BASE',
            path: '/dashboard',
            routeType: 'private',
            exact: true,
            countries: process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES
              ? process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES.split(',')
              : [],
          },
          {
            component: NotFound,
            path: '*',
            exact: true,
            routeKey: 'NOT_FOUND',
          },
        ],
      },
      {
        component: Eligibility,
        routeKey: 'ELIGIBILITY',
        path: '/eligibility',
        routeType: 'private',
        routes: [
          {
            routeKey: 'ELIGIBILITY_DETAIL',
            path: '/eligibility/details/:id',
            exact: true,
            component: EligibilityHistoryDetails,
          },
          {
            routeKey: 'ELIGIBILITY_CHECK_WITH_ITEM',
            path: '/eligibility/form/:id',
            component: EligibilityForm,
          },
          {
            routeKey: 'ELIGIBILITY_CHECK',
            path: '/eligibility/form',
            component: EligibilityForm,
          },
          {
            routeKey: 'ELIGIBILITY_DEFAULT',
            component: EgligibilityHistory,
            path: '/eligibility',
          },
        ],
      },
      {
        component: PreAuthV2,
        routeKey: 'PRE_AUTH',
        path: '/pre-auth',
        routeType: 'private',
        routes: [
          {
            component: PreAuthFormV2,
            routeKey: 'PRE_AUTH_FORM_RESUBMIT',
            path: '/pre-auth/form/:id/resubmit',
          },
          {
            component: PreAuthFormV2,
            routeKey: 'PRE_AUTH_FORM',
            path: '/pre-auth/form',
          },
          {
            component: PreAuthResponse,
            routeKey: 'PRE_AUTH_RESPONSE',
            path: '/pre-auth/response',
          },
          {
            component: PreAuthHistoryDetails,
            routeKey: 'PRE_AUTH_HISTORY_VIEW',
            path: '/pre-auth/details/:id',
            exact: true,
          },
          {
            component: PreAuthHistoryV2,
            routeKey: 'PRE_AUTH_HISTORY',
            path: '/pre-auth',
          },
        ],
      },
      // {
      //   exact: true,
      //   routeKey: 'REPORTS',
      //   component: Reports,
      //   path: '/report',
      //   routeType: 'private',
      //   countries: process.env.REACT_APP_REPORTS_AVAILABLE_COUNTRIES
      //     ? process.env.REACT_APP_REPORTS_AVAILABLE_COUNTRIES.split(',')
      //     : [],
      // },
      {
        component: Settings,
        routeKey: 'SETTTINGS',
        path: '/settings',
        routeType: 'private',
        routes: [
          {
            component: AccountSettings,
            routeKey: 'ACCOUNT_SETTINGS',
            path: '/settings/account',
            routes: [
              {
                routeKey: 'EDIT_ACCOUNT',
                path: '/settings/account/:path',
                component: EditInfo,
              },
              {
                routeKey: 'ACCOUNT_SETTINGS',
                path: '/settings/account',
                component: Info,
              },
            ],
            routeType: 'private',
          },
          {
            component: BranchManagement,
            routeKey: 'BRANCH_MANAGEMENT',
            path: '/settings/branch-management',
            routeType: 'private',
            routes: [
              {
                routeKey: 'BRANCH_MANAGEMENT_VERIFY',
                component: BranchVerify,
                path: '/settings/branch-management/verify',
              },
              {
                routeKey: 'BRANCH_MANAGEMENT_ADD',
                component: BranchAdd,
                path: '/settings/branch-management/add',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'BRANCH_MANAGEMENT_EDIT',
                component: BranchEdit,
                path: '/settings/branch-management/edit/:id',
                requiredPermission: permissions.edit_branches.identifier,
              },
              {
                routeKey: 'BRANCH',
                component: Branch,
                path: '/settings/branch-management',
                requiredPermission: permissions.view_branches.identifier,
              },
            ],
          },
          {
            component: InsuranceCompanies,
            routeKey: 'INSURANCE_COMPANIES',
            path: '/settings/insurance-companies',
            routeType: 'private',
            routes: [
              {
                routeKey: 'INSURANCE_COMPANIES_ADD',
                component: InsuranceCompaniesAdd,
                path: '/settings/insurance-companies/add',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'INSURANCE_COMPANIES_EDIT',
                component: InsuranceCompaniesEdit,
                path: '/settings/insurance-companies/edit/:id',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'INSURANCE',
                component: InsuranceCompaniesList,
                path: '/settings/insurance-companies',
              },
            ],
          },
          {
            component: Practitioners,
            routeKey: 'FACILITY_PRACTITIONERS',
            path: '/settings/practitioners',
            routeType: 'private',
            routes: [
              {
                routeKey: 'PRACTITIONERS_ADD',
                component: PractitionersAdd,
                path: '/settings/practitioners/add',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'PRACTITIONERS_UPDATE',
                component: PractitionersEdit,
                path: '/settings/practitioners/edit/:id',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'PRACTITIONERS',
                component: PractitionersList,
                path: '/settings/practitioners',
                requiredPermission: permissions.view_branches.identifier,
              },
            ],
          },
          {
            component: UserManagement,
            routeKey: 'USER_MANAGEMENT_CONTAINER',
            path: '/settings/user-management',
            routeType: 'private',
            routes: [
              {
                routeKey: 'ADD_USER',
                component: AddStaffMember,
                path: '/settings/user-management/add-members',
              },
              {
                routeKey: 'EDIT_USER',
                component: EditUser,
                path: '/settings/user-management/edit/:id',
              },
              {
                routeKey: 'STAFF_MANAGEMENT',
                component: Users,
                path: '/settings/user-management',
              },
            ],
          },
          {
            component: AccessManagementContainer,
            routeKey: 'ACCESS_MANAGEMENT',
            path: '/settings/access-management',
            routeType: 'private',
            routes: [
              {
                routeKey: 'EDIT_ROLES',
                path: '/settings/access-management/edit-roles/:id',
                component: EditRoles,
                requiredPermission: permissions.edit_user_roles.identifier,
              },
              {
                routeKey: 'ADD_ROLES',
                path: '/settings/access-management/add-roles',
                component: AddRoles,
                requiredPermission: permissions.add_user_roles.identifier,
              },
              {
                routeKey: 'ACCESS_MANAGEMENT_CONTENT',
                path: '/settings/access-management',
                component: AccessManagement,
                requiredPermission: permissions.view_user_roles.identifier,
              },
            ],
          },
          {
            component: Insurance,
            routeKey: 'INSURANCES_MANAGEMENT',
            path: '/settings/insurances-management',
            routeType: 'private',
            routes: [
              {
                routeKey: 'INSURANCES_MANAGEMENT_EDIT',
                component: InsuranceEdit,
                path: '/settings/insurances-management/edit/:id',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'INSURANCES_MANAGEMENT_ADD',
                component: InsuranceAdd,
                path: '/settings/insurances-management/add',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'INSURANCES_MANAGEMENT_LIST',
                component: InsuranceList,
                path: '/settings/insurances-management',
                requiredPermission: permissions.view_branches.identifier,
              },
            ],
          },
          {
            component: Contract,
            routeKey: 'CONTRACTS_MANAGEMENT',
            path: '/settings/contracts-management',
            routeType: 'private',
            routes: [
              {
                routeKey: 'CONTRACTS_MANAGEMENT_EDIT',
                component: ContractEdit,
                path: '/settings/contracts-management/edit/:id',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'CONTRACTS_MANAGEMENT_ADD',
                component: ContractAdd,
                path: '/settings/contracts-management/add',
                requiredPermission: permissions.add_branches.identifier,
              },
              {
                routeKey: 'CONTRACTS_MANAGEMENT_LIST',
                component: ContractList,
                path: '/settings/contracts-management',
                requiredPermission: permissions.view_branches.identifier,
              },
            ],
          },
          {
            routeKey: 'SETTINGS_BASE',
            component: () => null,
            path: '/settings',
            routeType: 'private',
          },
        ],
      },

      {
        component: Patients,
        routeKey: 'PATIENTS',
        path: '/patients',
        routeType: 'private',
        routes: [
          {
            component: PatientsAdd,
            routeKey: 'PATIENTS_ADD',
            path: '/patients/add',
            routeType: 'private',
          },
          {
            component: PatientsEdit,
            routeKey: 'PATIENTS_EDIT',
            path: '/patients/edit/:id',
          },
          {
            component: PatientView,
            routeKey: 'PATIENT_VIEW',
            path: '/patients/:id',
          },
          {
            component: PatientsList,
            routeKey: 'PATIENTS_LIST',
            path: '/patients',
            routeType: 'private',
          },
        ],
      },
      {
        component: ReportsContainer,
        routeKey: 'REPORTING',
        path: '/reporting',
        routeType: 'private',
      },
      {
        component: Pharmacy,
        routeKey: 'PHARMACY',
        path: '/pharmacy',
        countries: process.env.REACT_APP_PHARMACY_AVAILABLE_COUNTRIES
          ? process.env.REACT_APP_PHARMACY_AVAILABLE_COUNTRIES.split(',')
          : [],
        routeType: 'private',
        routes: [
          {
            component: ErxViewDispense,
            routeKey: 'ERX_VIEW_DISPENSE',
            path: '/pharmacy/erx/view-dispense/:viewdispenseId',
          },
          {
            component: ErxDispense,
            routeKey: 'ERX_DISPENSE',
            path: '/pharmacy/erx/dispense/:authorizeId',
          },
          {
            component: ErxDescription,
            routeKey: 'ERX_DESCRIPTION',
            path: '/pharmacy/erx/:erxId',
          },
          {
            component: ErxPrescriptionReference,
            routeKey: 'ERX_PRESCRIPTION_REFERENCE',
            path: '/pharmacy/prescription/:erxRefId',
          },
          {
            component: PriorRequest,
            routeKey: 'PRIOR_REQUEST',
            path: '/pharmacy/prior-request',
          },
          {
            exact: true,
            component: ErxList,
            routeKey: 'ERX_LIST',
            path: '/pharmacy',
          },
        ],
      },
      {
        component: EmailSignature,
        routeKey: 'EMAIL_SIGNATURE',
        path: '/email-signature',
        exact: true,
        routeType: 'private',
      },
      {
        component: Dashboard,
        routeKey: 'DASHBOARD_OVERVIEW_LANDING',
        path: '/',
        exact: true,
        routeType: 'private',
        redirect: '/dashboard/overview',
      },
      {
        component: NotAuthorized,
        path: '/not-authorized',
        exact: true,
        routeKey: 'NOT_AUTHORIZED',
      },
      {
        component: NotFound,
        path: '*',
        exact: true,
        routeKey: 'NOT_FOUND',
      },
    ],
  },
];

export default ROUTES;
