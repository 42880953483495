import {FC} from 'react';
import {ClaimsBreakdownDateTypes, ClaimsBreakdownFilters} from 'modules/Dashboard/api/strings';

export enum Accents {
  purple,
  teal,
  red,
  yellow,
}

export interface DateTypeOptions {
  label: ClaimsBreakdownDateTypes;
  value: ClaimsBreakdownDateTypes;
}

export interface Options {
  label: string;
  value: string;
}

export interface CustomSelectItem {
  checked: boolean;
  option: DateTypeOptions;
  disabled?: boolean;
  onClick: FC;
}

export interface ClaimsBreakdownData {
  data: {
    partially_paid: number[];
    accepted: number[];
    denied: number[];
    xLabels: string[];
    pending: number[];
  };
  scope: string;
  tab: string;
}

export interface CollectionPerMonthData {
  data: {
    collected: number[];
    rejected: number[];
    xLabels: string[];
    pending: number[];
  };
  scope: string;
}

export interface TopTenCliniciansData {
  data: {
    submitted: number[];
    paid: number[];
    rejected: number[];
    partially_paid: number[];
    xLabels: string[];
  };
  tab: string;
}

export interface RejectionsPerPeriodData {
  data: {
    rejections: number[];
    xLabels: string[];
    year: string;
  };
}
export interface TopTenRejectionsPerSourceData {
  data: {
    rejections: number[];
    xLabels: string[];
    labelToUse?: string;
    dataToUse?: any;
  };
}
export interface RejectionsPerSourceData {
  data: {
    rejections: number[];
  };
}
export interface RejectionsTopTenData {
  data: {
    rejections: number[];
    xLabels: string[];
  };
}
interface RejectionReasonsTopTenItem {
  denialCodeName: string;
  count: number;
  denialCode: string;
}
export interface RejectionReasonsTopTenData {
  data: RejectionReasonsTopTenItem[] | undefined;
}
interface RejectionsTotalItem {
  value: number;
  name: string;
  color?: string;
}
export interface RejectionsTotalData {
  data: RejectionsTotalItem[];
}

export interface NumbersContainerData {
  number: JSX.Element | string | number;
  label: string;
  accentColor?: Accents;
  customWidth?: string | number;
}

export interface SummaryType {
  totalNumberPendingClaims: number;
  totalAmountPendingClaims: number;
  totalNumberPendingClaimsMoreThanMonth: number;
  totalAmountPendingClaimsMoreThanMonth: number;
}

export interface HistogramDataType {
  key: string;
  from?: number;
  from_as_string?: string;
  to?: number;
  to_as_string?: string;
  doc_count?: number;
  rangeSum: RangeSumType;
  hist: number;
}

export interface RangeSumType {
  value: number;
}

export interface SeriesData {
  name: string;
  data: number;
}

export interface TableDataType {
  key: string;
  to?: number;
  to_as_string?: string;
  doc_count?: number;
  rangeSum: RangeSum;
}

export interface RangeSum {
  value: number;
}

export interface StatItem {
  totalClaims?: number | 0;
  claimAmount?: number | 0;
}

export interface RejectionResubmissionData {
  resubmitted?: StatItem;
  notResubmitted?: StatItem;
  showLoading?: boolean;
}

export interface ExpandedClaims {
  [key: string]: StatItem;
}

export interface RejectionFirstSubmissionData {
  totalCount?: StatItem;
  pendingClaims?: StatItem;
  rejectionRatioClaims?: StatItem;
  expandedClaims?: ExpandedClaims;
  showLoading?: boolean;
}
export interface PaymentsPerMonthData {
  data: {
    paid: number[];
    base: number[];
    xLabels: string[];
  };
}
export interface PaymentsPerProcedureData {
  data: {
    count: number[];
    xLabels: string[];
  };
}

export interface RejectionResubmissionFinalOutcome {
  pendingClaims?: StatItem;
  acceptedClaims?: StatItem;
  partiallyPaidClaims?: StatItem;
  rejectedClaims?: StatItem;
  showLoading?: boolean;
}
