export enum HeaderIconsEnum {
  overview = 'overview',
  rejection = 'rejection',
  ageing = 'ageing',
  payments = 'payments',
  clinicians = 'clinicians',
  collection = 'collection',
}

export interface NavigationMenuItem {
  link: string;
  title: string;
  leftIcon?: HeaderIconsEnum;
  isHidden?: boolean;
}
