import React from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';
import NumberFormat from 'react-number-format';
import {NoData} from 'modules/Common/components/NoData';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {Card} from 'modules/Common/components/Card';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Table} from 'modules/Dashboard/components/Table';
import {TopTenGraph} from 'modules/Dashboard/containers/Clinicians/components/TopTenGraph';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import dayjs from 'dayjs';
import {TopTenCliniciansData} from 'modules/Dashboard/api/types';
import {DASH} from 'modules/Dashboard/api';
import {ClaimsBreakdownDateRangeFormat, TopTenClinicianKeys, DashForbiddenMessage} from 'modules/Dashboard/api/strings';
import * as Toast from 'modules/Common/utils/toast';
import {
  getTopTenCliniciansDataService,
  getCliniciansClaimsDataService,
  getCliniciansClaimsAllTableDataService,
} from 'modules/Dashboard/api/requests';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {
  getTop10OrderingDataService,
  getClaimsPerOrderingAllData,
} from 'modules/Dashboard/containers/Clinicians/service';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import {events, Mixpanel} from 'services/mixpanel';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import StaticOverview from '../StaticHTML/Clinitians';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const Clinicians: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  const [topTenToolSrc, setTopTenToolSrc] = React.useState(TopTenClinicianKeys.Amount as string);
  const [perClinicianPaging, setPerClinicianPaging] = React.useState({offset: 0, limit: 10});
  const [topTenData, setTopTenData] = React.useState<DASH.Clinicians.Response | null>();
  const [claimsPerClinician, setClaimsPerClinician] = React.useState<DASH.CliniciansSource.Response | null>();
  const [perClinicianLoading, setPerClinicianLoading] = React.useState<boolean>(true);
  const [topTenLoading, setTopTenLoading] = React.useState<boolean>(true);

  const [perProcedureCsvData, setPerProcedureCsvData] = React.useState<string[][]>([[]]);
  const [cliamPerOrderingCsvData, setCliamPerOrderingCsvData] = React.useState<string[][]>([[]]);

  /* csv download table data for claims per ordering */
  const getCsvTableData = async () => {
    try {
      const alldata = await getCliniciansClaimsAllTableDataService(perSourceQueryData);
      if (alldata) {
        const {data} = alldata.data;
        return {formatedCsvData: getClaimsPerOrderingAllData(data)};
      }
    } catch (error) {
      return {formatedCsvData: []};
    }
  };

  const topTenQueryData: DASH.Clinicians.Request = {
    dateType: selectedDateType.value,
    startDate,
    endDate,
    branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
  };

  const perSourceQueryData: DASH.CliniciansSource.Request = {
    dateType: selectedDateType.value,
    startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
    endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
    branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
    offset: perClinicianPaging.offset,
    limit: perClinicianPaging.limit,
  };

  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        dateRangeFilterMonth: true,
        branchesFilter: true,
        staticDateType: 'First Submission Date',
      }),
    );
    Mixpanel.track(events.pageViews.dashboardClinician);
  }, []);

  React.useEffect(() => {
    (async () => {
      if (startDate && endDate) {
        try {
          setTopTenLoading(true);
          const data = await getTopTenCliniciansDataService(topTenQueryData);
          if (data) {
            setTopTenLoading(false);
            setTopTenData(data);
            setPerProcedureCsvData(getTop10OrderingDataService(data, topTenToolSrc));
          }
        } catch (error: any) {
          setTopTenLoading(false);
          if (error === DashForbiddenMessage) {
            setTopTenData(null);
            setPerProcedureCsvData([]);
          } else Toast.error(error);
        }
      }
    })();
  }, [selectedDateType, selectedBranches, startDate, endDate]);

  React.useEffect(() => {
    (async () => {
      if (startDate && endDate) {
        try {
          setPerClinicianLoading(true);
          const data = await getCliniciansClaimsDataService(perSourceQueryData);
          if (data) {
            setPerClinicianLoading(false);
            setClaimsPerClinician(data);
          }
        } catch (error: any) {
          setPerClinicianLoading(false);
          if (error === DashForbiddenMessage) setClaimsPerClinician(null);
          else Toast.error(error);
        }
      }
    })();
  }, [selectedDateType, selectedBranches, startDate, endDate, perClinicianPaging]);

  const FormattedTopTenData: TopTenCliniciansData = {
    data: {
      submitted: [],
      paid: [],
      rejected: [],
      partially_paid: [],
      xLabels: [],
    },
    tab: topTenToolSrc,
  };

  const histogramData = topTenData?.data?.topTenOrderingClinicians;
  histogramData?.forEach((stack) => {
    FormattedTopTenData.data.xLabels.push(stack.name || stack.id);
    stack.categoriesByStatus.forEach((cat) => {
      let num = cat.totalCount ? cat.totalCount : 0;
      if (topTenToolSrc === TopTenClinicianKeys.Amount) num = cat.totalAmount ? cat.totalAmount : 0;
      num = klaimDecimal(num);
      switch (cat.status) {
        case 'submitted':
          FormattedTopTenData.data.submitted.push(num);
          break;
        case 'paid':
          FormattedTopTenData.data.paid.push(num);
          break;
        case 'partially_paid':
          FormattedTopTenData.data.partially_paid.push(num);
          break;
        default:
          FormattedTopTenData.data.rejected.push(num);
      }
    });
  });

  const topTenTools =
    topTenData?.data.topTenOrderingClinicians.length === 0 ? (
      <div />
    ) : (
      <div className="toolsWrapper">
        <ToggleSwitch
          labels={[TopTenClinicianKeys.Amount, TopTenClinicianKeys.Count]}
          onClick={(val: string) => {
            setTopTenToolSrc(val);
            setPerProcedureCsvData(getTop10OrderingDataService(topTenData, val));
          }}
        />
        <DownloadDashBtn data={perProcedureCsvData} position filename={'Payments-Top10OrderingClinicians'} />
      </div>
    );

  const perClinicianTools =
    topTenData?.data.topTenOrderingClinicians.length === 0 ? (
      <div />
    ) : (
      <div className="toolsWrapper">
        <DownloadDashBtn
          data={cliamPerOrderingCsvData}
          position
          filename={'Clinician-ClaimsPerOrderingClinician'}
          getTableData={getCsvTableData}
        />
      </div>
    );
  let renderCliniciantsGraph: any = <NoData />;
  if (FormattedTopTenData.data.xLabels.length > 0)
    renderCliniciantsGraph = <TopTenGraph data={FormattedTopTenData.data} tab={topTenToolSrc} />;

  const headingNames = [
    'Clinician',
    'Clinian ID',
    '# Claims',
    `Net Claimed (${getCurrencyToDisplay()})`,
    `Paid (${getCurrencyToDisplay()})`,
    `Pending (${getCurrencyToDisplay()})`,
    `Rejected (${getCurrencyToDisplay()})`,
    'Rejection (%)',
  ];

  const formattedTableData = claimsPerClinician?.data.data.map((item) => {
    const numsAmount: any = {};
    const numsCount: any = {};
    item.buckets.forEach((bucket) => {
      numsAmount[bucket.key] = bucket.totalAmount;
      numsCount[bucket.key] = bucket.totalCount;
    });
    const netAmount: any = Object.values(numsAmount).reduce((a: any, b) => a + b, 0);
    const netCount: any = Object.values(numsCount).reduce((a: any, b) => a + b, 0);
    const rejection = numsCount.rejected ? (numsCount.rejected / netCount) * 100 : 0;
    return [
      item.name || '-',
      item.id || '-',
      item.noOfClaims,
      <NumberFormat value={netAmount} displayType={'text'} thousandSeparator decimalScale={0} />,
      <NumberFormat value={numsAmount.paid || 0} displayType={'text'} thousandSeparator decimalScale={0} />,
      <NumberFormat value={numsAmount.submitted || 0} displayType={'text'} thousandSeparator decimalScale={0} />,
      <NumberFormat value={numsAmount.rejected || 0} displayType={'text'} thousandSeparator decimalScale={0} />,
      <NumberFormat value={rejection} displayType={'text'} thousandSeparator decimalScale={2} suffix="%" />,
    ];
  });
  const renderCliniciansTable = (
    <Table
      title={'Claims Per Ordering Clinician'}
      headingData={headingNames}
      bodyData={formattedTableData || []}
      countPerpage={perClinicianPaging.limit || 0}
      page={perClinicianPaging.limit}
      pushPaginationToRight
      totalResults={claimsPerClinician?.data?.totalCount || 0}
      showPagination
      handlePageChange={(pageNumber: number) => {
        const offset = pageNumber * 10;
        if (perClinicianPaging) setPerClinicianPaging({...perClinicianPaging, offset});
      }}
      tools={perClinicianTools}
      showLoading={perClinicianLoading}
    />
  );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticOverview />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col sm={12} style={{position: 'static'}} className={classes.row2}>
                <Card
                  title="Top 10 Ordering Clinicians"
                  minHeight={'90%'}
                  tools={topTenTools}
                  showLoading={topTenLoading}
                >
                  {renderCliniciantsGraph}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} style={{position: 'static'}} className={classes.row2}>
                {renderCliniciansTable}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
