import React from 'react';
import './style.css';
import {TopTenGraph} from '../Clinicians/components/TopTenGraph';

const data = {
  submitted: [0, 0, 0, 0, 0],
  paid: [0, 0, 0, 0, 0],
  rejected: [4860, 434, 288, 217, 217],
  partially_paid: [855.15, 76.38, 35.32, 38.19, 38.19],
  xLabels: ['BRIAN REILLY', 'MASSIMO PIRACCI', 'FABRIZIO FACCHINI', 'MOHAMED IBRAHIM', 'ERIK HOHMANN'],
};
const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-192">
        <div className="contentContainer-0-1-193">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="row2-0-1-194"
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{minHeight: '90%'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Top 10 Ordering Clinicians</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="toggleSwitchEL-0-1-93" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          SAR Amount
                        </div>
                        <div role="button">Count</div>
                      </div>
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <TopTenGraph data={data} tab={'SAR Amount'} />;
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="row2-0-1-194"
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Claims Per Ordering Clinician</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="downloadPopup-0-1-96 dashDLBtn">
                        <button className="klaimBtn-0-1-103 false undefined klaimBtnIconMode-0-1-106 buttonTool-0-1-102">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-105"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="tableWrapper-0-1-166">
                    <table className="klaimTable-0-1-176">
                      <thead className="klaimThead-0-1-181">
                        <tr>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Clinician</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Clinian ID</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165"># Claims</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Net Claimed (SAR)</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Paid (SAR)</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Pending (SAR)</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Rejected (SAR)</p>
                          </th>
                          <th className="klaimTh-0-1-180">
                            <p className="tableHeading-0-1-165">Rejection (%)</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="klaimTbody-0-1-178">
                        <tr className="tableRow-0-1-167">
                          <td>WALAA IKRAM EL ASSUITY</td>
                          <td>d4547</td>
                          <td>
                            <span>1,848</span>
                          </td>
                          <td>
                            <span>910,096</span>
                          </td>
                          <td>
                            <span>657,981</span>
                          </td>
                          <td>
                            <span>161,915</span>
                          </td>
                          <td>
                            <span>46,015</span>
                          </td>
                          <td>
                            <span>8.33%</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>ALEXANDER ABRAHAM</td>
                          <td>gd6171</td>
                          <td>
                            <span>55</span>
                          </td>
                          <td>
                            <span>20,591</span>
                          </td>
                          <td>
                            <span>12,897</span>
                          </td>
                          <td>
                            <span>6,399</span>
                          </td>
                          <td>
                            <span>801</span>
                          </td>
                          <td>
                            <span>5.17%</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>-</td>
                          <td>gt25200</td>
                          <td>
                            <span>5</span>
                          </td>
                          <td>
                            <span>1,776</span>
                          </td>
                          <td>
                            <span>584</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>1,192</span>
                          </td>
                          <td>
                            <span>20%</span>
                          </td>
                        </tr>
                        <tr className="tableRow-0-1-167">
                          <td>-</td>
                          <td>gt25371</td>
                          <td>
                            <span>1</span>
                          </td>
                          <td>
                            <span>606</span>
                          </td>
                          <td>
                            <span>606</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0</span>
                          </td>
                          <td>
                            <span>0%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paginationContainer-0-1-164 right">
                      <div className="paginationContainer-0-1-185">
                        <span />
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_left_icon.5d82b0df.svg" alt="arrow-left" />
                        </span>
                        <div className="numberButtonsContainer-0-1-183">
                          <div
                            role="button"
                            className="paginationNumberButton-0-1-187 paginationNumberButtonActive-0-1-188"
                          >
                            1
                          </div>
                        </div>
                        <span className="paginationLeftRightButton-0-1-186" role="button">
                          <img src="/static/media/arrow_right_icon.7c4eefa8.svg" alt="arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
