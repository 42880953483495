import React from 'react';
import './style.css';
import {ClaimsBreakdown} from '../Overview/components/ClaimsBreakdown';

const data = {
  data: {
    accepted: [181, 143, 165, 0, 0, 0],
    denied: [2, 4, 4, 0, 0, 0],
    xLabels: [
      '2022-01-01T00:00:00.000Z',
      '2022-02-01T00:00:00.000Z',
      '2022-03-01T00:00:00.000Z',
      '2022-04-01T00:00:00.000Z',
      '2022-05-01T00:00:00.000Z',
      '2022-06-01T00:00:00.000Z',
    ],
    pending: [7, 43, 76, 21, 15, 1],
    partially_paid: [13, 45, 11, 0, 0, 0],
  },
  scope: 'Monthly',
  tab: 'Count',
};
const Component = () => {
  return (
    <div className="dashboardContainer-0-1-59">
      <div className="container-0-1-79">
        <div className="contentContainer-0-1-80">
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Total</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-82"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>35,872</span>
                        </div>
                        <div className="numContLabel-0-1-139">Claims</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>36,551,546.32</span>
                        </div>
                        <div className="numContLabel-0-1-139">SAR Claimed</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'relative',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Breakdown</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-82"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>11,090</span>
                        </div>
                        <div className="numContLabel-0-1-139">Pending</div>
                        <div className="accent-0-1-137 purple" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>19,707</span>
                        </div>
                        <div className="numContLabel-0-1-139">Accepted</div>
                        <div className="accent-0-1-137 teal" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>3,475</span>
                        </div>
                        <div className="numContLabel-0-1-139">Partially Accepted</div>
                        <div className="accent-0-1-137 yellow" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>1,600</span>
                        </div>
                        <div className="numContLabel-0-1-139">Rejected</div>
                        <div className="accent-0-1-137 red" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="boxesCont-0-1-82"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>12,784,067.71</span>
                        </div>
                        <div className="numContLabel-0-1-139">SAR Pending</div>
                        <div className="accent-0-1-137 purple" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>18,166,021.72</span>
                        </div>
                        <div className="numContLabel-0-1-139">SAR Accepted</div>
                        <div className="accent-0-1-137 teal" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>4,514,320.89</span>
                        </div>
                        <div className="numContLabel-0-1-139">SAR Partially Accepted</div>
                        <div className="accent-0-1-137 yellow" />
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>1,087,136</span>
                        </div>
                        <div className="numContLabel-0-1-139">SAR Rejected</div>
                        <div className="accent-0-1-137 red" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">KPIs</div>
                  <div className="tools-0-1-87" />
                </div>
                <div className="body-0-1-83">
                  <div
                    className="boxesCont-0-1-82"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: 'normal',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>1,070.83 SAR</span>
                        </div>
                        <div className="numContLabel-0-1-139">Average Amount Per Claim</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '50%',
                        flex: '0 0 50%',
                        maxWidth: '50%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>19 Days</span>
                        </div>
                        <div className="numContLabel-0-1-139">Encounter To Claim Submission</div>
                      </div>
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        minHeight: '1px',
                        position: 'relative',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        flex: '0 0 100%',
                        maxWidth: '100%',
                        marginLeft: '0%',
                        right: 'auto',
                        left: 'auto',
                      }}
                    >
                      <div className="numCont-0-1-138">
                        <div className="numContNumber-0-1-140">
                          <span>37 Days</span>
                        </div>
                        <div className="numContLabel-0-1-139">Claim Submission To Payment</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              display: 'flex',
              flexFlow: 'row wrap',
              flexGrow: 0,
              flexShrink: 0,
              alignItems: 'normal',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="row2-0-1-81"
              style={{
                boxSizing: 'border-box',
                minHeight: '1px',
                position: 'static',
                paddingLeft: '15px',
                paddingRight: '15px',
                width: '100%',
                flex: '0 0 100%',
                maxWidth: '100%',
                marginLeft: '0%',
                right: 'auto',
                left: 'auto',
              }}
            >
              <div className="card-0-1-84" style={{height: 'auto'}}>
                <div className="header-0-1-85">
                  <div className="title-0-1-86">Claims Breakdown Per Period</div>
                  <div className="tools-0-1-87">
                    <div className="toolsWrapper">
                      <div className="toggleSwitchEL-0-1-89" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Count
                        </div>
                        <div role="button">Amount</div>
                      </div>
                      <div className="toggleSwitchEL-0-1-89" style={{width: 'auto'}}>
                        <div role="button" className="active">
                          Monthly
                        </div>
                        <div role="button">Yearly</div>
                      </div>
                      <div className="downloadPopup-0-1-92 dashDLBtn">
                        <button className="klaimBtn-0-1-99 false undefined klaimBtnIconMode-0-1-102 buttonTool-0-1-98">
                          <img
                            src="/static/media/dl_icon_v2.07ca3fb7.svg"
                            alt="icon-left"
                            className="klaimBtnIconLeft-0-1-101"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-0-1-83">
                  <div className="container-0-1-141">
                    <ClaimsBreakdown data={data.data} tab={data.tab} scope={data.scope} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
