import {FC, useState, useEffect} from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {Card} from 'modules/Common/components/Card';
import {ClaimsBreakdown} from 'modules/Dashboard/containers/Overview/components/ClaimsBreakdown';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {NoData} from 'modules/Common/components/NoData';
import {Accents, ClaimsBreakdownData} from 'modules/Dashboard/api/types';
import {DASH} from 'modules/Dashboard/api';
import {getOverviewDataService} from 'modules/Dashboard/api/requests';
import {useAppDispatch} from 'modules/App/store/index';
import {
  ClaimsBreakdownStatus,
  KPIsLabels,
  TotalClaimsLabels,
  ClaimsBreakdownFilters,
  TotalClaimsVSAmountKeys,
  ClaimsBreakdownDateRangeFormat,
  DashForbiddenMessage,
} from 'modules/Dashboard/api/strings';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {useAppSelector} from 'modules/App/store';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {
  getToatlDataService,
  getBreakdownDataService,
  getKpisDataService,
  getBreakdownPeriodDataService,
} from 'modules/Dashboard/containers/Overview/service/getFormatedOverviewCsvData';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import StaticOverview from '../StaticHTML/Overview';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    ...commonStyles,
  };
});

export const Overview: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  const [breakdownToolSrc, setBreakdownToolSrc] = useState(ClaimsBreakdownFilters.Count as string);
  const [breakdownToolRange, setBreakdownToolRange] = useState(ClaimsBreakdownFilters.Monthly as string);

  const [overviewData, setOverviewData] = useState<DASH.Overview.Response | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const [overviewTotalCsvData, setOverviewTotalCsvData] = useState<string[][] | null>([[]]);
  const [overviewBreakdownCsvData, setOverviewBreakdownCsvData] = useState<string[][]>([[]]);
  const [overviewKpisCsvData, setOverviewKpisCsvData] = useState<string[][]>([[]]);
  const [breakdownPeriodCsvData, setBreakdownPeriodCsvData] = useState<string[][]>([[]]);

  const fetchOverviewData = async (queryData: any) => {
    if (startDate && endDate) {
      setLoading(true);
      try {
        const data = await getOverviewDataService(queryData);
        if (data) {
          setLoading(false);
          setOverviewData(data);
          setOverviewTotalCsvData(getToatlDataService(data));
          setOverviewBreakdownCsvData(getBreakdownDataService(data));
          setOverviewKpisCsvData(getKpisDataService(data));
          setBreakdownPeriodCsvData(getBreakdownPeriodDataService(data, breakdownToolSrc, breakdownToolRange));
        }
      } catch (error: any) {
        setLoading(false);
        if (error === DashForbiddenMessage) {
          setOverviewData(null);
          setOverviewTotalCsvData([]);
          setBreakdownPeriodCsvData([]);
          setOverviewBreakdownCsvData([]);
          setOverviewKpisCsvData([]);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedDateType && startDate && endDate)
      fetchOverviewData({
        dateType: selectedDateType.value,
        startDate: dayjs(startDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        endDate: dayjs(endDate).format(ClaimsBreakdownDateRangeFormat.dateFormat),
        branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
      });
  }, [selectedDateType, selectedBranches, startDate, endDate]);

  useEffect(() => {
    dispatch(
      setRequiredFilters({
        dateTypeFilter: true,
        dateRangeFilterMonth: true,
        branchesFilter: true,
      }),
    );
  }, []);

  const BreakdownData: ClaimsBreakdownData = {
    data: {
      accepted: [],
      denied: [],
      xLabels: [],
      pending: [],
      partially_paid: [],
    },
    scope: breakdownToolRange,
    tab: breakdownToolSrc,
  };

  let dataKey = TotalClaimsVSAmountKeys.Amount;
  let histogramScope = overviewData?.data?.histogramPerMonth;
  if (breakdownToolSrc === ClaimsBreakdownFilters.Count) dataKey = TotalClaimsVSAmountKeys.Count;
  if (breakdownToolRange === ClaimsBreakdownFilters.Yearly) histogramScope = overviewData?.data?.histogramPerYear;

  histogramScope?.map((stack) => {
    BreakdownData.data.xLabels.push(stack.date);
    BreakdownData.data.accepted.push(stack.paid ? klaimDecimal(stack.paid[dataKey]) : 0);
    BreakdownData.data.denied.push(stack.rejected ? klaimDecimal(stack.rejected[dataKey]) : 0);
    BreakdownData.data.pending.push(stack.pending ? klaimDecimal(stack.pending[dataKey]) : 0);
    BreakdownData.data.partially_paid.push(stack.partially_paid ? klaimDecimal(stack.partially_paid[dataKey]) : 0);
    return stack;
  });

  const graphTools =
    overviewData?.data.acceptedClaims.totalClaims === 0 &&
    overviewData?.data.acceptedClaims.claimAmount === 0 &&
    overviewData?.data.partiallyPaidClaims.totalClaims === 0 &&
    overviewData?.data.partiallyPaidClaims.claimAmount === 0 &&
    overviewData?.data.rejectedClaims.totalClaims === 0 &&
    overviewData?.data.rejectedClaims.claimAmount === 0 &&
    overviewData?.data.pendingClaims.totalClaims === 0 &&
    overviewData?.data.pendingClaims.claimAmount === 0 ? (
      <div> </div>
    ) : (
      <div className="toolsWrapper">
        <ToggleSwitch
          leftLabel={ClaimsBreakdownFilters.Count}
          rightLabel={ClaimsBreakdownFilters.Amount}
          onClick={(val: string) => {
            setBreakdownToolSrc(val);
            setBreakdownPeriodCsvData(getBreakdownPeriodDataService(overviewData, val, breakdownToolRange));
          }}
        />
        <ToggleSwitch
          leftLabel={ClaimsBreakdownFilters.Monthly}
          rightLabel={ClaimsBreakdownFilters.Yearly}
          onClick={(val: string) => {
            setBreakdownToolRange(val);
            setBreakdownPeriodCsvData(getBreakdownPeriodDataService(overviewData, breakdownToolSrc, val));
          }}
        />
        <DownloadDashBtn data={breakdownPeriodCsvData} position filename={'Overview-ClaimsBreakdownPerPeriod'} />
      </div>
    );

  // for line 410 Download button
  // const totalTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn data={overviewTotalCsvData} position={false} filename={'Overview-Total'} />
  //   </div>
  // );

  // for line 415 Download button
  // const breakdownTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn data={overviewBreakdownCsvData} position filename={'Overview-Breakdown'} />
  //   </div>
  // );

  // for line 424 Download button
  // const kpiTools =
  //   overviewData?.data?.kpi.averageAmount === 0 ||
  //   overviewData?.data?.kpi.averageDaysBetweenEncounterAndSubmission === 0 ||
  //   overviewData?.data?.kpi.averageDaysBetweenSubmissionAndPayment === 0 ? (
  //     <div> </div>
  //   ) : (
  //     <div className="toolsWrapper">
  //       <DownloadDashBtn data={overviewKpisCsvData} position filename={'Overview-KPIs'} />
  //     </div>
  //   );
  let renderTotal: any = <NoData />;
  if (overviewData?.data?.totalClaims && overviewData?.data?.totalClaims > 0) {
    const acceptedAEDTotal = overviewData?.data?.acceptedClaims.claimAmount;
    const pendingAEDTotal = overviewData?.data?.pendingClaims.claimAmount;
    const partialAEDTotal = overviewData?.data?.partiallyPaidClaims.claimAmount;
    const rejectAEDTotal = overviewData?.data?.rejectedClaims.claimAmount;
    const overAllAEDClaimTotal = acceptedAEDTotal + pendingAEDTotal + partialAEDTotal + rejectAEDTotal;
    const acceptedTotal = overviewData?.data?.acceptedClaims.totalClaims;
    const pendingTotal = overviewData?.data?.pendingClaims.totalClaims;
    const partialTotal = overviewData?.data?.partiallyPaidClaims.totalClaims;
    const rejectTotal = overviewData?.data?.rejectedClaims.totalClaims;
    const overAllClaimTotal = acceptedTotal + pendingTotal + partialTotal + rejectTotal;

    renderTotal = (
      <Row className={classes.boxesCont}>
        <Col md={12} lg={6} xl={12}>
          <NumbersContainer
            number={<NumberFormat value={overAllClaimTotal} displayType={'text'} thousandSeparator />}
            label={TotalClaimsLabels.Claims}
          />
        </Col>
        <Col md={12} lg={6} xl={12}>
          <NumbersContainer
            number={
              <NumberFormat value={overAllAEDClaimTotal} displayType={'text'} thousandSeparator decimalScale={2} />
            }
            label={`${getCurrencyToDisplay()} ${TotalClaimsLabels.Claimed}`}
          />
        </Col>
      </Row>
    );
  }

  let renderBreakdown: any = <NoData />;
  let total = 0;
  if (overviewData?.data) {
    total += overviewData?.data?.pendingClaims.totalClaims || 0;
    total += overviewData?.data?.pendingClaims.claimAmount || 0;

    total += overviewData?.data?.acceptedClaims.claimAmount || 0;
    total += overviewData?.data?.acceptedClaims.totalClaims || 0;

    total += overviewData?.data?.partiallyPaidClaims.claimAmount || 0;
    total += overviewData?.data?.partiallyPaidClaims.totalClaims || 0;

    total += overviewData?.data?.rejectedClaims.totalClaims || 0;
    total += overviewData?.data?.rejectedClaims.claimAmount || 0;
  }
  if (total > 0)
    renderBreakdown = (
      <>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.pendingClaims.totalClaims}
                  displayType={'text'}
                  thousandSeparator
                />
              }
              label={ClaimsBreakdownStatus.Pending}
              accentColor={Accents.purple}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.acceptedClaims.totalClaims}
                  displayType={'text'}
                  thousandSeparator
                />
              }
              label={ClaimsBreakdownStatus.Accepted}
              accentColor={Accents.teal}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.partiallyPaidClaims.totalClaims}
                  displayType={'text'}
                  thousandSeparator
                />
              }
              label={ClaimsBreakdownStatus.PartiallyAccepted}
              accentColor={Accents.yellow}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.rejectedClaims.totalClaims}
                  displayType={'text'}
                  thousandSeparator
                />
              }
              label={ClaimsBreakdownStatus.Rejected}
              accentColor={Accents.red}
            />
          </Col>
        </Row>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.pendingClaims.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Pending}`}
              accentColor={Accents.purple}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.acceptedClaims.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Accepted}`}
              accentColor={Accents.teal}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.partiallyPaidClaims.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.PartiallyAccepted}`}
              accentColor={Accents.yellow}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={overviewData?.data?.rejectedClaims.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`${getCurrencyToDisplay()} ${ClaimsBreakdownStatus.Rejected}`}
              accentColor={Accents.red}
            />
          </Col>
        </Row>
      </>
    );

  let renderKPIs: any = <NoData />;
  if (
    overviewData?.data?.kpi &&
    overviewData?.data?.kpi.averageAmount &&
    overviewData?.data?.kpi.averageDaysBetweenEncounterAndSubmission &&
    overviewData?.data?.kpi.averageDaysBetweenSubmissionAndPayment
  )
    renderKPIs = (
      <Row className={classes.boxesCont}>
        <Col md={12} lg={6} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={overviewData?.data?.kpi.averageAmount}
                displayType={'text'}
                thousandSeparator
                suffix={` ${getCurrencyToDisplay()}`}
                decimalScale={2}
              />
            }
            label={KPIsLabels.AverageAPC}
          />
        </Col>
        <Col md={12} lg={6} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={Math.floor(overviewData?.data?.kpi.averageDaysBetweenEncounterAndSubmission)}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
                suffix=" Days"
              />
            }
            label={KPIsLabels.EncounterToClaim}
          />
        </Col>
        <Col lg={12} xl={4}>
          <NumbersContainer
            number={
              <NumberFormat
                value={Math.floor(overviewData?.data?.kpi.averageDaysBetweenSubmissionAndPayment)}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
                suffix=" Days"
              />
            }
            label={KPIsLabels.SubmissionTPayment}
          />
        </Col>
      </Row>
    );

  let renderBreakdownChart: any = <NoData />;
  if (overviewData?.data.histogramPerMonth && overviewData?.data.histogramPerMonth.length > 0)
    renderBreakdownChart = (
      <ClaimsBreakdown data={BreakdownData.data} tab={breakdownToolSrc} scope={BreakdownData.scope} />
    );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticOverview />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col
                style={{
                  position: 'static',
                  minWidth: overviewData?.data?.totalClaims && overviewData?.data?.totalClaims > 0 ? '' : 'fit-content',
                }}
                lg={12}
                xl={overviewData?.data?.totalClaims && overviewData?.data?.totalClaims > 0 ? 3 : 4}
              >
                <Card title="Total" height={'auto'} showLoading={loading}>
                  {renderTotal}
                </Card>
              </Col>
              <Col lg={12} xl={overviewData?.data?.totalClaims && overviewData?.data?.totalClaims > 0 ? 9 : 8}>
                <Card title="Breakdown" height={'auto'} showLoading={loading}>
                  {renderBreakdown}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12}>
                <Card title="KPIs" height={'auto'} showLoading={loading}>
                  {renderKPIs}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12} className={classes.row2}>
                <Card title="Claims Breakdown Per Period" height={'auto'} tools={graphTools} showLoading={loading}>
                  {renderBreakdownChart}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
