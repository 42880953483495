import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    backgroundColor: Colors.white,
    padding: 80,
    borderRadius: 10,
  },
  welcomeContainer: {
    flex: '1 1 0%',
  },
  headerContainer: {
    fontSize: 46,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  instructionsContainer: {
    width: 434,
    fontSize: 20,
    color: Colors.grayLighter,
    fontWeight: 400,
    marginTop: 30,
  },
  optionsContainer: {
    marginTop: 50,
    fontSize: 25,
    fontWeight: 700,
    color: Colors.grayLighter,
    width: 491,
    '& button': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  lastText: {
    fontWeight: 500,
  },
});
