import {useState, useEffect, useRef, useCallback} from 'react';

export const useStateWithPromise = (initialState: any) => {
  const [state, setState] = useState(initialState);
  const resolverRef = useRef<any>();

  useEffect(() => {
    if (resolverRef && resolverRef.current) {
      resolverRef.current(state);
      resolverRef.current = null;
    }
  }, [resolverRef.current, state]);

  const handleSetState = useCallback(
    (stateAction: any) => {
      setState(stateAction);
      return new Promise((resolve) => {
        resolverRef.current = resolve;
      });
    },
    [setState],
  );

  return [state, handleSetState];
};
