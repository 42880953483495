import {
  FilterGroupTotalStats,
  FirstSubmissionOutcomeCsv,
  HistogramMonthFilterGroup,
  DownloadCsv,
  ResubmissionBreakdownCsv,
  ResubmittedClaimsFOCsv,
  RejectionCsv,
  ClaimsKeyMap,
} from 'modules/Dashboard/common/enums';
import {TopTenRejectionReasonKeys, TopTenRejectionReasonCsv} from 'modules/Dashboard/api/strings';

/* Service for Total csv */
export const getRejectionTotalDataService = (dashboardData: any, activeTab: string) => {
  let formattedData: any = [];
  const paidClaims = dashboardData?.data?.acceptedClaims;
  const rejectedClaims = dashboardData?.data?.rejectedClaims;
  const keyToUse = activeTab === FilterGroupTotalStats.COUNT ? ClaimsKeyMap.ClaimsTotal : ClaimsKeyMap.ClaimsValue;
  if (rejectedClaims && rejectedClaims[keyToUse] > 0 && paidClaims && paidClaims[keyToUse] > 0) {
    const total = rejectedClaims[keyToUse] + paidClaims[keyToUse];
    const rejectedPer = (rejectedClaims[keyToUse] * 100) / total;
    const paidPer = (paidClaims[keyToUse] * 100) / total;
    formattedData = [
      [RejectionCsv.Status, `${RejectionCsv.RejectionRate} ${activeTab}`],
      [RejectionCsv.Rejected, `${Math.round(rejectedPer)}%`],
      [RejectionCsv.Paid, `${Math.round(paidPer)}%`],
    ];
  }

  return formattedData;
};

/* Service for rejection per period csv */
export const getTopRejectionSourceDataService = (dashboardData: any, activeTab1: string, activeTab2: string) => {
  let formattedData: any = [];
  const getSelectedTitle = activeTab2 === HistogramMonthFilterGroup.MONTHLY ? DownloadCsv.MONTHLY : DownloadCsv.YEARLY;
  let rejectionperPeriodData: string[][] = [];
  if (dashboardData.data && dashboardData.data.rejections.length > 0) {
    dashboardData.data.rejections.forEach((item: any, index: number) => {
      const date = new Date(dashboardData.data.xLabels[index]);
      const getMonthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      rejectionperPeriodData = [
        ...rejectionperPeriodData,
        [getSelectedTitle === DownloadCsv.MONTHLY ? getMonthYear : `${date.getFullYear()}`, item.toString()],
      ];
    });
    formattedData = [[getSelectedTitle, `${RejectionCsv.Rejections} ${activeTab1}`], ...rejectionperPeriodData];
  }

  return formattedData;
};

/* Service for Top 10 Rejections per source csv */
export const getTopRejectionPerSourceDataService = (dashboardData: any, activeTab: string) => {
  let formattedData: any = [];

  let topTenRejectionPerSourceData: string[][] = [];
  if (dashboardData && dashboardData.data.rejections.length > 0) {
    dashboardData.data.rejections.forEach((item: any, index: number) => {
      topTenRejectionPerSourceData = [
        ...topTenRejectionPerSourceData,
        [dashboardData.data.xLabels[index], item.toString()],
      ];
    });
    formattedData = [[activeTab, RejectionCsv.RejectionsAmount], ...topTenRejectionPerSourceData];
  }

  return formattedData;
};

/* Service for Top 10 Reasons Rejection outcome csv */
export const getTop10ReasonsRejectionDataService = (dashboardData: any, activeTab1: string, activeTab2: string) => {
  let formattedData: any = [];
  const reason =
    activeTab2 === TopTenRejectionReasonCsv.MedicalLabel
      ? TopTenRejectionReasonCsv.MedicalValue
      : TopTenRejectionReasonCsv.TechnicalValue;
  let rejectionTopTenReasonsData: string[][] = [];
  if (dashboardData.data && dashboardData.data.topTenRejectionReasons[reason].length > 0) {
    dashboardData.data.topTenRejectionReasons[reason].forEach((item: any) => {
      const {denialCodeName, denialCode, totalAmount, totalCount} = item;
      let count;
      if (activeTab1 === TopTenRejectionReasonKeys.Count) count = parseInt(totalCount, 10);
      if (activeTab1 === TopTenRejectionReasonKeys.Amount) count = parseInt(totalAmount, 10);
      rejectionTopTenReasonsData = [...rejectionTopTenReasonsData, [denialCode, denialCodeName, count]];
    });
    formattedData = [
      [RejectionCsv.DenialCode, `${RejectionCsv.Rejection} ${activeTab2}`, `${RejectionCsv.Rejected} ${activeTab1}`],
      ...rejectionTopTenReasonsData,
    ];
  }

  return formattedData;
};

/* Service for rejection 1st submission outcome csv */
export const getFirstSubmissionDataService = (totalCount: any, expandedClaims: any, rejectionRatioClaims: any) => {
  let formattedData: any = [];
  let total = 0;
  let xKeys: any;
  if (expandedClaims) {
    xKeys = Object.keys(expandedClaims);
    xKeys.forEach((x: string) => {
      total += expandedClaims[x]?.claimAmount || 0;
      total += expandedClaims[x]?.totalClaims || 0;
    });
  }
  if (totalCount) {
    total += totalCount?.claimAmount || 0;
    total += totalCount?.totalClaims || 0;
  }
  if (rejectionRatioClaims) {
    total += rejectionRatioClaims?.claimAmount || 0;
    total += rejectionRatioClaims?.totalClaims || 0;
  }

  if (total > 0) {
    formattedData = [
      [FirstSubmissionOutcomeCsv.FirstSubmissionOutcome, ''],
      [FirstSubmissionOutcomeCsv.TotalClaims, parseFloat(`${totalCount?.totalClaims}`).toFixed(2)],
      [FirstSubmissionOutcomeCsv.Accepted, parseFloat(`${expandedClaims?.acceptedClaims?.totalClaims}`).toFixed(2)],
      [
        FirstSubmissionOutcomeCsv.PartiallyAccepted,
        parseFloat(`${expandedClaims?.partiallyPaidClaims?.totalClaims}`).toFixed(2),
      ],
      [FirstSubmissionOutcomeCsv.Rejected, parseFloat(`${expandedClaims?.rejectedClaims?.totalClaims}`).toFixed(2)],
      [FirstSubmissionOutcomeCsv.RejectionRatioCount, parseFloat(`${rejectionRatioClaims?.totalClaims}`).toFixed(2)],
      [FirstSubmissionOutcomeCsv.TotalClaimsAED, parseFloat(`${totalCount?.claimAmount}`).toFixed(2)],
      [FirstSubmissionOutcomeCsv.AEDAccepted, parseFloat(`${expandedClaims?.acceptedClaims?.claimAmount}`).toFixed(2)],
      [
        FirstSubmissionOutcomeCsv.PartiallyAcceptedAED,
        parseFloat(`${expandedClaims?.partiallyPaidClaims?.claimAmount}`).toFixed(2),
      ],
      [FirstSubmissionOutcomeCsv.RejectedAED, parseFloat(`${expandedClaims?.rejectedClaims?.claimAmount}`).toFixed(2)],
      [FirstSubmissionOutcomeCsv.RejectionRatioAED, parseFloat(`${rejectionRatioClaims?.claimAmount}`).toFixed(2)],
    ];
  }

  return formattedData;
};

/* Service for Resubmissions breakdown csv */
export const getRejectionResubmissionDataService = (notResubmitted: any, resubmitted: any) => {
  let formattedData: any = [];
  let total = 0;
  if (notResubmitted) {
    total += notResubmitted?.claimAmount || 0;
    total += notResubmitted?.totalClaims || 0;
  }
  if (resubmitted) {
    total += resubmitted?.claimAmount || 0;
    total += resubmitted?.totalClaims || 0;
  }

  if (total > 0) {
    formattedData = [
      [ResubmissionBreakdownCsv.ResubmissionsBreakdown, ''],
      [ResubmissionBreakdownCsv.NotResubmitted, parseFloat(`${notResubmitted?.totalClaims}`).toFixed(2)],
      [ResubmissionBreakdownCsv.Resubmitted, parseFloat(`${resubmitted?.totalClaims}`).toFixed(2)],
      [ResubmissionBreakdownCsv.NotResubmittedAED, parseFloat(`${notResubmitted?.claimAmount}`).toFixed(2)],
      [ResubmissionBreakdownCsv.ResubmittedAED, parseFloat(`${resubmitted?.claimAmount}`).toFixed(2)],
    ];
  }
  return formattedData;
};

/* Service for Resubmitted Claims Final Outcome csv */
export const getResubmittedFODataService = (
  rejectedClaims: any,
  partiallyPaidClaims: any,
  acceptedClaims: any,
  pendingClaims: any,
) => {
  let formattedData: any = [];
  let total = 0;
  if (pendingClaims) {
    total += pendingClaims?.claimAmount || 0;
    total += pendingClaims?.totalClaims || 0;
  }
  if (acceptedClaims) {
    total += acceptedClaims?.claimAmount || 0;
    total += acceptedClaims?.totalClaims || 0;
  }
  if (partiallyPaidClaims) {
    total += partiallyPaidClaims?.claimAmount || 0;
    total += partiallyPaidClaims?.totalClaims || 0;
  }
  if (rejectedClaims) {
    total += rejectedClaims?.claimAmount || 0;
    total += rejectedClaims?.totalClaims || 0;
  }
  if (total > 0) {
    formattedData = [
      [ResubmittedClaimsFOCsv.ResubmittedClaimsFinalOutcome, ''],
      [ResubmittedClaimsFOCsv.StillPending, parseFloat(`${pendingClaims?.totalClaims}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.FinallyAccepted, parseFloat(`${acceptedClaims?.totalClaims}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.PartiallyAccepted, parseFloat(`${partiallyPaidClaims?.totalClaims}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.StillRejected, parseFloat(`${rejectedClaims?.totalClaims}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.StillPendingAED, parseFloat(`${pendingClaims?.claimAmount}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.FinallyAcceptedAED, parseFloat(`${acceptedClaims?.claimAmount}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.PartiallyAcceptedAED, parseFloat(`${partiallyPaidClaims?.claimAmount}`).toFixed(2)],
      [ResubmittedClaimsFOCsv.StillRejectedAED, parseFloat(`${rejectedClaims?.claimAmount}`).toFixed(2)],
    ];
  }
  return formattedData;
};
