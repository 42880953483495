import {Colors} from 'modules/Common/constants/Colors';

export const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    padding: '0',
    maxHeight: '100%',
    position: 'relative',
  },
  contentContainer: {
    borderRadius: '10px',
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    marginBottom: '45px',
    overflow: 'hidden',
  },
  row2: {
    margin: 0,
  },
  boxesCont: {
    padding: '0 30px',
  },
};
