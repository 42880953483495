import React, {FC, useState, useEffect} from 'react';
import {Row, Col} from 'react-grid-system';
import {createUseStyles} from 'react-jss';
import {NoData} from 'modules/Common/components/NoData';
import {ToggleSwitch} from 'modules/Common/components/ToggleSwitch';
import {Card} from 'modules/Common/components/Card';
import {CollectionsPerMonth} from 'modules/Dashboard/containers/Collection/components/CollectionsPerMonth';
import NumberFormat from 'react-number-format';
import {CollectionPerMonthData, Options} from 'modules/Dashboard/api/types';
import {DASH} from 'modules/Dashboard/api';
import {
  getCollectionsDataService,
  getCollectionsSourceDataService,
  getPayersDataService,
  getCollectionsSourceAllTabelData,
} from 'modules/Dashboard/api/requests';
import {Table} from 'modules/Dashboard/components/Table';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {CollectionPerMonthFilters, PayersClassifications, DashForbiddenMessage} from 'modules/Dashboard/api/strings';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import * as Toast from 'modules/Common/utils/toast';
import {MultiSelectTool} from 'modules/Dashboard/components/MultiSelectTool';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {headerSelect, Branches, setRequiredFilters} from 'modules/Header/headerSlice';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {getPerMonthDataService, getPerSourceDataService} from 'modules/Dashboard/containers/Collection/service';
import {klaimDecimal} from 'modules/Common/utils/formatter';
import insurerIcon from 'assets/insurer_icon.svg';
import tpaIcon from 'assets/tpa_icon.svg';
import {events, Mixpanel} from 'services/mixpanel';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import StaticOverview from '../StaticHTML/Collection';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const Collection: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {startDate, endDate, selectedBranches, selectedDateType} = useAppSelector(headerSelect);

  const [perMonthToolSrc, setPerMonthToolSrc] = useState(CollectionPerMonthFilters.Insurer as string);
  const [perSourceToolSrc, setPerSourceToolSrc] = useState(CollectionPerMonthFilters.Insurer as string);
  const [perSourcePaging, setPerSourcePaging] = useState({offset: 0, limit: 10});

  const [perMonthData, setPerMonthData] = useState<DASH.Collections.Response | null>();
  const [perSourceData, setPerSourceData] = useState<DASH.CollectionsSource.Response | null>();
  const [payersData, setPayersData] = useState<DASH.Payers.Response | null>();
  const [perSourceLoading, setPerSourceLoading] = useState<boolean>(true);
  const [perMonthLoading, setPerMonthLoading] = useState<boolean>(true);
  const [perSourceSelectedPayer, setPerSourceSelectedPayer] = useState<Options[]>([]);
  const [perMonthSelectedPayer, setPerMonthSelectedPayer] = useState<Options[]>([]);
  const [insurerOptions, setInsurerOptions] = useState<Options[]>([]);
  const [TPAOptions, setTPAOptions] = useState<Options[]>([]);

  const [perMonthCsvData, setPerMonthCsvData] = useState<string[][]>([[]]);
  const [perSourceCsvData, setPerSourceCsvData] = useState<string[][]>([[]]);

  const perMonthQueryData: DASH.CollectionsSource.Request = {
    dateType: selectedDateType.value,
    startDate,
    endDate,
    branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
    insurerType: perMonthToolSrc.toLowerCase(),
  };

  const perMonthQueryHeader = {insurercodes: perMonthSelectedPayer?.map((item: Options) => item.value).join(',')};

  const perSourceQueryData: DASH.CollectionsSource.Request = {
    dateType: selectedDateType.value,
    startDate,
    endDate,
    branchCodes: selectedBranches.map((branch: Branches) => branch.value).join(','),
    insurerType: perSourceToolSrc.toLowerCase(),
    insurerCodes: perSourceSelectedPayer.map((item: Options) => item.value).join(','),
    offset: perSourcePaging.offset,
    limit: perSourcePaging.limit,
  };

  const perSourceQueryHeader = {insurercodes: perSourceSelectedPayer.map((item: Options) => item.value).join(',')};

  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        dateTypeFilter: true,
        dateRangeFilterMonth: true,
        branchesFilter: true,
      }),
    );
  }, []);

  React.useEffect(() => {
    (async () => {
      if (startDate && endDate && payersData?.data && payersData?.data?.length > 0) {
        try {
          setPerMonthLoading(true);
          const data = await getCollectionsDataService(perMonthQueryData, perMonthQueryHeader);
          if (data) {
            setPerMonthLoading(false);
            setPerMonthData(data);
            setPerMonthCsvData(getPerMonthDataService(data, perMonthToolSrc));
            Mixpanel.track(events.pageViews.dashboardCollectionMonth);
          }
        } catch (error: any) {
          setPerMonthLoading(false);
          setPerMonthCsvData([]);
          if (error === DashForbiddenMessage) setPerMonthData(null);
          else Toast.error(error);
        }
      }
    })();
  }, [selectedDateType, selectedBranches, startDate, endDate, perMonthToolSrc, perMonthSelectedPayer, payersData]);

  React.useEffect(() => {
    (async () => {
      if (startDate && endDate && payersData?.data && payersData?.data?.length > 0) {
        try {
          setPerSourceLoading(true);
          const {data} = await getCollectionsSourceDataService(perSourceQueryData, perSourceQueryHeader);
          if (data) {
            setPerSourceLoading(false);
            setPerSourceData({...data.data, offset: data.offset, limit: data.limit});
            Mixpanel.track(events.pageViews.dashboardCollectionInsurer);
          }
        } catch (error: any) {
          setPerSourceLoading(false);
          if (error === DashForbiddenMessage) setPerSourceData(null);
          else Toast.error(error);
        }
      }
    })();
  }, [
    selectedDateType,
    selectedBranches,
    startDate,
    endDate,
    perSourceToolSrc,
    perSourcePaging,
    perSourceSelectedPayer,
    payersData,
  ]);

  React.useEffect(() => {
    (async () => {
      if (startDate && endDate) {
        try {
          let insurerOptionsArr: Options[] = [];
          let TPAOptionsArr: Options[] = [];
          const {data} = await getPayersDataService({limit: 300});
          if (data) {
            setPayersData({...data});
            insurerOptionsArr = [];
            TPAOptionsArr = [];
            data?.data?.map((item: any) => {
              if (item.classification === PayersClassifications.Insurance) {
                insurerOptionsArr.push({
                  label: item.longName,
                  value: item.payerCode,
                } as Options);
              } else if (item.classification === PayersClassifications.TPA) {
                TPAOptionsArr.push({
                  label: item.longName,
                  value: item.payerCode,
                } as Options);
              }
              return item;
            });
            setInsurerOptions([]);
            setTPAOptions([]);
            setInsurerOptions(insurerOptionsArr);
            setTPAOptions(TPAOptionsArr);
            setPerSourceSelectedPayer(insurerOptionsArr);
            setPerMonthSelectedPayer(insurerOptionsArr);
          }
        } catch (error: any) {
          if (error === DashForbiddenMessage) {
            setTPAOptions([]);
            setInsurerOptions([]);
            setPerSourceSelectedPayer([]);
            setPerMonthSelectedPayer([]);
          } else Toast.error(error);
        }
      }
    })();
  }, [selectedBranches]);

  /* Csv download table data for per source */
  const getCsvTableData = async () => {
    try {
      const tabelDataResponse = await getCollectionsSourceAllTabelData(perSourceQueryData, perSourceQueryHeader);
      if (tabelDataResponse) {
        const {data} = tabelDataResponse.data;
        return {formatedCsvData: getPerSourceDataService(data)};
      }
    } catch (error) {
      return {formatedCsvData: []};
    }
  };

  const MothlyCollections: CollectionPerMonthData = {
    data: {
      collected: [],
      rejected: [],
      xLabels: [],
      pending: [],
    },
    scope: perMonthToolSrc,
  };

  const histogramData = perMonthData?.data?.data;
  histogramData?.map((stack) => {
    MothlyCollections.data.xLabels.push(stack.date);
    MothlyCollections.data.pending.push(stack.pending ? klaimDecimal(stack.pending) : 0);
    MothlyCollections.data.rejected.push(stack.denied ? klaimDecimal(stack.denied) : 0);
    MothlyCollections.data.collected.push(stack.collected ? klaimDecimal(stack.collected) : 0);
    return stack;
  });

  const perMonthTools = (
    <div className="toolsWrapper">
      <ToggleSwitch
        labels={[CollectionPerMonthFilters.Insurer, CollectionPerMonthFilters.TPA]}
        onClick={(val: string) => {
          setPerMonthToolSrc(val);
          setPerMonthCsvData(getPerMonthDataService(perMonthData, val));
          setPerMonthSelectedPayer(TPAOptions);
          if (val === CollectionPerMonthFilters.Insurer) setPerMonthSelectedPayer(insurerOptions);
          else setPerMonthSelectedPayer(TPAOptions);
        }}
      />
      <MultiSelectTool
        options={perMonthToolSrc === CollectionPerMonthFilters.Insurer ? insurerOptions : TPAOptions}
        value={perMonthSelectedPayer}
        onChange={(selected) => {
          setPerMonthSelectedPayer(selected);
        }}
        overrideStrings={{
          allItemsAreSelected: perMonthToolSrc === CollectionPerMonthFilters.Insurer ? 'All Insurers' : 'All TPAs',
          selectAll: perMonthToolSrc === CollectionPerMonthFilters.Insurer ? 'All Insurers' : 'All TPAs',
          selectSomeItems:
            perMonthToolSrc === CollectionPerMonthFilters.Insurer ? 'Select Insurer(s)' : 'Select TPA(s)',
        }}
        position="center"
      />
      {perMonthData?.data.data.length === 0 ? (
        <div> </div>
      ) : (
        <DownloadDashBtn data={perMonthCsvData} position filename={'Collection-CollectionsPerMonth'} />
      )}
    </div>
  );
  const perSourceTools = (
    <div className="toolsWrapper">
      <ToggleSwitch
        leftLabel={CollectionPerMonthFilters.Insurer}
        rightLabel={CollectionPerMonthFilters.TPA}
        onClick={(val: string) => {
          setPerSourceToolSrc(val);
          setPerSourceSelectedPayer([]);
          if (val === CollectionPerMonthFilters.Insurer) setPerSourceSelectedPayer(insurerOptions);
          else setPerSourceSelectedPayer(TPAOptions);
        }}
      />
      <MultiSelectTool
        options={perSourceToolSrc === CollectionPerMonthFilters.Insurer ? insurerOptions : TPAOptions}
        value={perSourceSelectedPayer}
        onChange={(selected) => {
          setPerSourceSelectedPayer(selected);
        }}
        overrideStrings={{
          allItemsAreSelected: perSourceToolSrc === CollectionPerMonthFilters.Insurer ? 'All Insurers' : 'All TPAs',
          selectAll: perSourceToolSrc === CollectionPerMonthFilters.Insurer ? 'All Insurers' : 'All TPAs',
          selectSomeItems:
            perSourceToolSrc === CollectionPerMonthFilters.Insurer ? 'Select Insurer(s)' : 'Select TPA(s)',
        }}
        leftIcon={perSourceToolSrc === CollectionPerMonthFilters.Insurer ? insurerIcon : tpaIcon}
        position="center"
      />
      {perSourceData?.data.length === 0 ? (
        <div> </div>
      ) : (
        <DownloadDashBtn
          data={perSourceCsvData}
          position
          filename={'Collection-CollectionsPerSource'}
          getTableData={getCsvTableData}
        />
      )}
    </div>
  );
  let renderCollectionsChart: any = <NoData />;
  if (histogramData && histogramData?.length > 0)
    renderCollectionsChart = <CollectionsPerMonth data={MothlyCollections.data} scope={MothlyCollections.scope} />;

  const columnName = perSourceToolSrc === CollectionPerMonthFilters.Insurer ? 'Payer' : 'Receiver';
  const headingNames = [
    columnName,
    `${columnName} ID`,
    `Claimed ${getCurrencyToDisplay()}`,
    `Collected ${getCurrencyToDisplay()}`,
    'Collected (%)',
    `Rejected ${getCurrencyToDisplay()}`,
    'Rejected (%)',
    `Pending ${getCurrencyToDisplay()}`,
    'Pending (%)',
  ];

  const formattedTableData = perSourceData?.data.map((item) => {
    return [
      item.name || '-',
      item.id || '-',
      <NumberFormat value={item.claimed || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.collected || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.collectedPercentage || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.rejected || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.rejectedPercentage || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.pending || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
      <NumberFormat value={item.pendingPercentage || 0} displayType={'text'} thousandSeparator decimalScale={2} />,
    ];
  });

  const collectionTable = (
    <Table
      title={'Collections Per Source'}
      headingData={headingNames}
      bodyData={formattedTableData || []}
      countPerpage={perSourcePaging.limit || 0}
      page={perSourcePaging.limit}
      pushPaginationToRight
      totalResults={perSourceData?.totalCount || 0}
      showPagination={formattedTableData && formattedTableData?.length > 0}
      handlePageChange={(pageNumber: number) => {
        const offset = pageNumber * 10;
        if (perSourcePaging) setPerSourcePaging({...perSourcePaging, offset});
      }}
      tools={perSourceTools}
      showLoading={perSourceLoading}
    />
  );

  const {
    user: {organization},
  } = useAppSelector(authSelect);
  return (
    <>
      {organization.country !== 'UAE' ? (
        <StaticOverview />
      ) : (
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            <Row>
              <Col style={{position: 'static'}} sm={12} className={classes.row2}>
                <Card
                  title={`Collections Per Month (${getCurrencyToDisplay()} Amount)`}
                  minHeight={'90%'}
                  tools={perMonthTools}
                  showLoading={perMonthLoading}
                >
                  {renderCollectionsChart}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{position: 'static'}} sm={12} className={classes.row2}>
                {collectionTable}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
