import {FC} from 'react';
import NumberFormat from 'react-number-format';
import {createUseStyles} from 'react-jss';
import {Row, Col} from 'react-grid-system';
import {NoData} from 'modules/Common/components/NoData';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {Card} from 'modules/Common/components/Card';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import * as Toast from 'modules/Common/utils/toast';
import {RejectionResubmissionFinalOutcome} from 'modules/Dashboard/api/types';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {getResubmittedFODataService} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const ResubmissionFinalOutcome: FC<RejectionResubmissionFinalOutcome> = ({
  rejectedClaims,
  partiallyPaidClaims,
  acceptedClaims,
  pendingClaims,
  showLoading,
}) => {
  const classes = useStylesFromThemeFunction();

  // line 149 download button
  // const resubmittedClaimsTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn
  //       data={getResubmittedFODataService(rejectedClaims, partiallyPaidClaims, acceptedClaims, pendingClaims)}
  //       position={false}
  //       filename={'Rejections-ResubmittedClaimsFinalOutcome'}
  //     />
  //   </div>
  // );

  let renderContent: any = <NoData />;
  let total = 0;
  if (pendingClaims) {
    total += pendingClaims?.claimAmount || 0;
    total += pendingClaims?.totalClaims || 0;
  }
  if (acceptedClaims) {
    total += acceptedClaims?.claimAmount || 0;
    total += acceptedClaims?.totalClaims || 0;
  }
  if (partiallyPaidClaims) {
    total += partiallyPaidClaims?.claimAmount || 0;
    total += partiallyPaidClaims?.totalClaims || 0;
  }
  if (rejectedClaims) {
    total += rejectedClaims?.claimAmount || 0;
    total += rejectedClaims?.totalClaims || 0;
  }
  if (total > 0)
    renderContent = (
      <>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={<NumberFormat value={pendingClaims?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Still Pending'}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={<NumberFormat value={acceptedClaims?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Finally Accepted'}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={<NumberFormat value={partiallyPaidClaims?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Partially Accepted'}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={<NumberFormat value={rejectedClaims?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Still Rejected'}
            />
          </Col>
        </Row>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={pendingClaims?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={0}
                />
              }
              label={`Still Pending ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={acceptedClaims?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={0}
                />
              }
              label={`Finally Accepted ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={partiallyPaidClaims?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={0}
                />
              }
              label={`Partially Accepted ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col md={12} lg={6} xl={3}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={rejectedClaims?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={0}
                />
              }
              label={`Still Rejected ${getCurrencyToDisplay()}`}
            />
          </Col>
        </Row>
      </>
    );

  return (
    <Card title="Resubmitted Claims Final Outcome" height={'auto'} showLoading={showLoading}>
      {renderContent}
    </Card>
  );
};
