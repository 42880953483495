import {
  CollectionsPerMonthSeries,
  CollectionPerMonthCsv,
  CollectionPerSourceNames,
} from 'modules/Dashboard/api/strings';

/* Service for collection per month csv */
export const getPerMonthDataService = (collectionData: any, activeTab: string) => {
  let formatedData: any = [];
  let perMonthData: string[][] = [];
  if (collectionData && collectionData.data && collectionData.data.data && collectionData.data.data.length > 0) {
    collectionData.data.data.forEach((item: any) => {
      const date = new Date(item.date);
      const getMonthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      perMonthData = [...perMonthData, [getMonthYear, item.collected, item.denied, item.pending]];
    });
    formatedData = [
      [
        CollectionPerMonthCsv.Month,
        `${CollectionsPerMonthSeries.Collected} ${activeTab}`,
        `${CollectionsPerMonthSeries.Rejected} ${activeTab}`,
        `${CollectionsPerMonthSeries.Pending} ${activeTab}`,
      ],
      ...perMonthData,
    ];
  }
  return formatedData;
};

/* Service for collection per source csv */
export const getPerSourceDataService = (collectionData: any) => {
  let ageingBySourceData: any[][] = [];
  if (collectionData && collectionData?.data?.length > 0) {
    collectionData.data.forEach((item: any) => {
      ageingBySourceData = [
        ...ageingBySourceData,
        [
          item.name || '-',
          item.id || '-',
          (item.claimed || 0).toFixed(2),
          (item.collected || 0).toFixed(2),
          (item.collectedPercentage || 0).toFixed(2),
          (item.rejected || 0).toFixed(2),
          (item.rejectedPercentage || 0).toFixed(2),
          (item.pending || 0).toFixed(2),
          (item.pendingPercentage || 0).toFixed(2),
        ],
      ];
    });
    ageingBySourceData = [CollectionPerSourceNames, ...ageingBySourceData];
  }

  return ageingBySourceData;
};
