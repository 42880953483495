import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import NumberFormat from 'react-number-format';
import {Row, Col} from 'react-grid-system';
import {NoData} from 'modules/Common/components/NoData';
import {Card} from 'modules/Common/components/Card';
import {NumbersContainer} from 'modules/Dashboard/components/NumbersContainer';
import {getCurrencyToDisplay} from 'modules/Common/utils/localization';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import * as Toast from 'modules/Common/utils/toast';
import {RejectionResubmissionData} from 'modules/Dashboard/api/types';
import {commonStyles} from 'modules/Dashboard/styles/common/common.styles';
import {DownloadDashBtn} from 'modules/Common/components/DownloadDashBtn';
import {getRejectionResubmissionDataService} from 'modules/Dashboard/containers/Rejections/service/getFormateDownloadDataservice';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    ...commonStyles,
  };
});

export const ResubmissionsBreakdown: FC<RejectionResubmissionData> = (props): JSX.Element | null => {
  const classes = useStylesFromThemeFunction();

  const {resubmitted, notResubmitted, showLoading} = props;

  // line 96 download button
  // const reSubmissionTools = (
  //   <div className="toolsWrapper">
  //     <DownloadDashBtn
  //       data={getRejectionResubmissionDataService(notResubmitted, resubmitted)}
  //       position={false}
  //       filename={'Rejections-ResubmissionBreakdown'}
  //     />
  //   </div>
  // );

  let renderContent: any = <NoData />;
  let total = 0;
  if (notResubmitted) {
    total += notResubmitted?.claimAmount || 0;
    total += notResubmitted?.totalClaims || 0;
  }
  if (resubmitted) {
    total += resubmitted?.claimAmount || 0;
    total += resubmitted?.totalClaims || 0;
  }
  if (total > 0)
    renderContent = (
      <>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6}>
            <NumbersContainer
              number={<NumberFormat value={notResubmitted?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Not Resubmitted'}
            />
          </Col>
          <Col md={12} lg={6}>
            <NumbersContainer
              number={<NumberFormat value={resubmitted?.totalClaims} displayType={'text'} thousandSeparator />}
              label={'Resubmitted'}
            />
          </Col>
        </Row>
        <Row className={classes.boxesCont}>
          <Col md={12} lg={6}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={notResubmitted?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`Not Resubmitted ${getCurrencyToDisplay()}`}
            />
          </Col>
          <Col md={12} lg={6}>
            <NumbersContainer
              number={
                <NumberFormat
                  value={resubmitted?.claimAmount}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              }
              label={`Resubmitted ${getCurrencyToDisplay()}`}
            />
          </Col>
        </Row>
      </>
    );

  return (
    <Card title="Resubmissions Breakdown" height={'auto'} showLoading={showLoading}>
      {renderContent}
    </Card>
  );
};
